import {SETTINGS} from '..'
import {Helper} from '../classes'


export const ariaSpanDirective = [
	'$q',
(
	$q,
)=>{
	return {
		restrict: 'E',
		priority: -400,
		link: (scope, elem, attrs)=>{
      if ( !attrs['role'] && attrs['aria-hidden']!='true' ) {
        elem.attr('role', 'none');
      }
			var fa = elem.filter('.fas, .far');
			if ( fa.attr('aria-hidden')==undefined )
				fa.attr('aria-hidden', 'true');
		},
	};
}];
ariaSpanDirective.selector = 'span';
