import {Helper, MESSAGES} from '../'

const THROTTLE_DELAY = 300;


export const rawRecipientDirective = [
	'recipient',
  '$timeout',
  '$mdUtil',
(
	recipient,
  $timeout,
  $mdUtil,
)=>{
	return {
		require: ['ngModel', '^mdInputContainer', '^?form', '^?ngModelOptions'],
		restrict: 'E',
		link: function($scope, $element, $attr, ctrls){
      const [ngModel, containerCtrl, parentForm, modelOptions] = ctrls;

      if ( modelOptions ) ngModel.$options = modelOptions.$options;  

      $element
        .attr('contenteditable', 'true')
        .on('blur', evt=>update(evt))
        .on('input', evt=>{
          cleanup(evt);
          debouncedUpdate(evt);
        })
        .on('mouseenter', 'span.error', function(){
          const w = $element.width()/3;
          const x = this.offsetLeft;
          let pos = 'bottom';
          if ( x < w )
            pos = 'bottom-right';
          else if ( x > w*2 )
            pos = 'bottom-left';
          this.setAttribute('data-microtip-position', pos);
        });

      ngModel.$render = ()=>{
        $element.html(ngModel.$viewValue);
      };
      ngModel.$formatters.push(value=>format(value));
      ngModel.$parsers.push(value=>Helper.htmlToText(value));
      ngModel.$validators.expr = (modelValue, viewValue)=>{
        if ( recipient.hasErrors(modelValue || Helper.htmlToText(viewValue)) )
          return false;
        return true;
      };

      const cleanup = evt=>{
        let value = Helper.stripOtherTags($element.html().trim(), 'em, span');
        if ( $element.data('html') != value ) {
          let pos = $element.caret('pos');
          $element
            .html(value)
            .data('html', value)
            .data('expr', '');
          ngModel.$setViewValue(value, evt.type);

          if ( evt.type!='blur' && pos > -1 )
            $element.caret('pos', pos);
        }
      }
      const update = evt=>{
        let expr = Helper.htmlToText($element.html().trim());
        if ( $element.data('expr') != expr ) {
          let pos = $element.caret('pos');
          $element.html(format(expr));

          let html = $element.html();
          $element
            .data('expr', expr)
            .data('html', html);
          ngModel.$setViewValue(html, evt.type);

          if ( ngModel.$options.getOption('updateOnDefault') )
            $element.trigger('change');
          if ( evt.type!='blur' && pos > -1 )
            $element.caret('pos', pos);
        }
      };
      const debouncedUpdate = Helper.debounce(update, 500);

      const format = expr=>{
        let result = recipient.parse(expr, {ignoreInvalids:true, ignoreErrors:true});
        console.log(expr, result);

        let offset = 0, idx = 0;
        result.errors.forEach(err=>{
          // skip errors that are before idx
          if ( err.offset < idx ) {
            return console.log('skip err', err);
          }

          let pre = expr.substring(0, offset + err.offset);
          let word = expr.substr(offset + err.offset, err.word.length);
          let wrapped = `<span class="error" aria-label="${Helper.htmlToText(err.message)}" role="tooltip" data-microtip-position="bottom">${word}</span>`;
          let post = expr.substring(offset + err.offset + err.word.length);

          expr = pre + wrapped + post;
          offset += wrapped.length - word.length;
          idx = err.offset + err.word.length;
        });

        return expr;
      };

      // handle disable attr
      $scope.$watch(()=>$element.is('[disabled]'), (value, old)=>{
        value!=old && $element.attr('contenteditable', value ? null : 'true');
      });

      // integration with md-input-container
      $element
        .addClass('md-input')
        .css('height', 'auto')
        .after($('<div class="md-errors-spacer">'))
        .on('focus', evt=>$mdUtil.nextTick(()=>containerCtrl.setFocused(true)))
        .on('blur', evt=>$mdUtil.nextTick(()=>containerCtrl.setFocused(true)))
      // containerCtrl.input = $element;
      let isErrorGetter = containerCtrl.isErrorGetter || (()=>ngModel.$invalid && (ngModel.$touched || parentForm?.$submitted));
      $scope.$watch(isErrorGetter, containerCtrl.setInvalid);

      const checkValue = (args)=>{
        containerCtrl.setHasValue(!ngModel.$isEmpty(args));
        return args;
      };
      ngModel.$formatters.push(checkValue);
      ngModel.$parsers.push(checkValue);

      $scope.$on('$destroy', ()=>{
        containerCtrl.setFocused(false);
        containerCtrl.setHasValue(false);
        containerCtrl.input = null;
      });
    }
  }
}]
rawRecipientDirective.selector = 'rawRecipient';