import $ from 'jquery'
import angular from 'angular'
import {Helper} from '../'
import Pickr from '@simonwep/pickr/dist/pickr.es5.min';

import '@simonwep/pickr/dist/themes/monolith.min.css';


export const colorPickrDirective = [
(
)=>{
	return {
		require: '?ngModel',
		restrict: 'A',
		link: ($scope, $element, attrs, ngModel)=>{
			let element = $element.get(0),
				el = $('<span class="pickr-btn"></span>').insertBefore($element).get(0),
				$mask = $('<div class="md-scroll-mask"><div class="md-scroll-mask-bar"></div></div>');

			let pickr, opts = {
				el,
				// container: $element.parent().prop('tagName'),
				theme: 'monolith',
				appClass: 'md-whiteframe-8dp',
				components: {
					// Main components
					preview: false,
					opacity: false,
					hue: true,
					// Input / output Options
					interaction: {
						hex: false,
						rgba: false,
						hsla: false,
						hsva: false,
						cmyk: false,
						input: false,
						clear: false,
						save: false,
					},
				},
				lockOpacity: true,
				position: 'bottom-start',
				swatches: ['#00A39C', '#CCEDEB', '#6582ff', '#f37f1c'],
				useAsButton: true,
				comparison: false,
			};

			let init = ()=>{
				if ( ngModel.$modelValue ) {
					opts.default = ngModel.$modelValue;
					if ( !opts.swatches.includes(ngModel.$modelValue) )
						opts.swatches.splice(0, 0, ngModel.$modelValue);
				}
				
				pickr = Pickr.create(opts);
				pickr.on('init', ()=>{
					if ( ngModel.$modelValue )
						update(pickr.getColor());
				});
				pickr.on('show', ()=>{
					$element.focus();
					$mask.insertBefore(pickr._root.app);
				});
				pickr.on('hide', ()=>$mask.remove());
				pickr.on('change', color=>{
					update(color);
					digest(color);
				});
				pickr.on('clear', ()=>update());
				ngModel.$viewChangeListeners.push(()=>pickr.setColor(ngModel.$valid && ngModel.$viewValue || null, true));
				$element.on('focus mousedown', ()=>!pickr.isOpen() && pickr.show());
			};
			let update = Helper.throttle(color=>{
				let hex = color && color.toHEXA().toString() || '';
				element.style.backgroundColor = hex;
				$element.toggleClass('white', color && color.toHSLA()[2] < 70);
				if ( color && hex ) {
					element.value = hex;
				}
			}, 50, true);
			let digest = Helper.throttle(color=>color && $element.focus().change(), 200, true);


			$scope.$parent?.$once('deps-loaded', ()=>setTimeout(()=>init(), 250));

			$scope.$once('$destroy', ()=>{
				pickr && pickr.hide().destroy();
				$element.off('focus').off('mousedown');
			});
		},
	}
}];
colorPickrDirective.selector = 'colorPickr';
