import {DependencyInjected, Helper} from '../classes';
import {SETTINGS} from '../settings';

const TIMEZONES =  moment.tz.names();

class TimezoneSelectController extends DependencyInjected {
	static get $inject(){return [
		'$scope',
	]}

	init(){
    this.$scope.$watch('model', (value)=>{
      if ( value != this.old ) { // weird enough, watch's old model isn't consistent
				this.old = value;
        this.$scope.results = this.query(this.search, value);
			}
    });
	}

	query(str, value){
		if ( str ) {
			str = str.toLowerCase().replace(/\s+/, '_');
			return TIMEZONES.filter(name=>name.toLowerCase().indexOf(str) > -1);
		} else {
			if ( value && !SETTINGS.TZ_DEFAULTS.includes(value) )
				return [value].concat(SETTINGS.TZ_DEFAULTS);
		}
		return SETTINGS.TZ_DEFAULTS;
	}
}


export const timezoneSelectDirective = [
(
)=>{
	return {
		restrict: 'A',
		scope: {
			model: '=timezoneSelect',
			ngDisabled: '<?',
			ngRequired: '<?',
      label: '<?label',
		},
		controller: TimezoneSelectController,
		controllerAs: 'ctrl',
		template: TEMPLATE,
    transclude: true,
    priority: 10,
	};
}];
timezoneSelectDirective.selector = 'timezoneSelect';

const TEMPLATE = `<label>{{ ctrl.label || 'Time Zone' }}</label>
  <md-select name="timezone" 
    data-md-container-class="select-with-search"
    ng-model="model"
    ng-required="ngRequired"
    md-on-close="model && (results = ctrl.query(search='', model))">
    <md-select-header>
      <input type="search" class="header-search" placeholder="Search others" autocomplete="off"
				aria-label="search others"
        ng-keydown="$event.stopImmediatePropagation()"
        ng-click="$event.stopImmediatePropagation()"
        ng-model-options="{debounce:{'default':300, 'blur':0}, '*':'$inherit'}"
        ng-model="search" ng-change="results = ctrl.query(search, model)">
			<md-icon><i class="fas fa-search"></i></md-icon>
    </md-select-header>
    <md-option ng-value="value"" ng-repeat="value in results">{{ value }}</md-option>
    <md-option ng-if="!results.length" disabled="disabled"><em>No results</em></md-option-group>
  </md-select>
  <ng-transclude></ng-transclude>`;