import angular from 'angular'
import moment from 'moment'
import momentTz from 'moment-timezone'
import SETTINGS from '../settings'
import $ from 'jquery'

// using map here to preserve order
export const DATE_PATTERNS = new Map([
	[/^\d{4}-\d\d-\d\dT/, 'YYYY-MM-DD[T]'],
	[/^\d{4}-\d\d-\d\d/, 'YYYY-MM-DD'],
	[/^\d{4}-\d+-\d+\b/, 'YYYY-MM-DD'],
	[/^\d\d?\/\d+\/\d{4,}\b/, 'M/D/YYYY'],
	[/^\d\d?\/\d+\/\d\d\b/, 'M/D/YY'],
	[/^[a-z]{4,}\s+\d+,\s+\d{4,}\b/i, 'MMMM D, YYYY'],
	[/^[a-z]+\s+\d+,\s+\d{4,}\b/i, 'MMM D, YYYY'],
	[/^[a-z]{4,}\s+\d+\s+\d{4,}\b/i, 'MMMM D YYYY'],
	[/^[a-z]+\s+\d+\s+\d{4,}\b/i, 'MMM D YYYY'],
]);
export const TIME_PATTERNS = new Map([
	[/(\d\d?:\d\d:\d\d([\+\-]\d\d:?\d\d|Z)?)$/, 'HH:mm:ssZ'],
	[/\d\d?:\d\d[a-z]+$/, 'hh:mma'],
	[/(\d\d?:\d\d:\d\d)$/, 'HH:mm:ss'],
	[/\d\d?:\d\d$/, 'HH:mm'],
	[/\d{3,4}[a-z]+$/, 'hhmma'],
	[/\d\d?:\d\d\s+[a-z]+$/, 'hh:mm a'],
	[/\d{3,4}$/, 'HHmm'],
	[/\d\d?[a-z]+$/, 'ha'],
	[/\d\d?\s+[a-z]+$/, 'h a'],
	[/\d\d?$/, 'HH'],
]);

const TASKPROG_INIT_RATE = 0.1;
const TASKPROG_FACTOR = 0.25;


export class Helper {
	static get selector(){return 'util'}


	static sortByName(a, b){
		return ( a.name.toLowerCase() < b.name.toLowerCase() ) ? -1 : (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : 0;
	}
	static sortBy(key){
		return function(a, b){
			return a[key] < b[key] ? -1 : (a[key] > b[key] ? 1 : 0);
		}
	}
	static sortByPath(path, separator){
		return function(a, b){
			a = Helper.getPathValue(a, path, separator);
			b = Helper.getPathValue(b, path, separator);
			return a < b ? -1 : (a > b ? 1 : 0);
		}
	}

	static getPathValue(obj, path, separator){
		let keys = path.split(separator || /[\.\\\/]+/).reverse();
		while( keys.length ){
			let k = keys.pop();
			if ( obj.hasOwnProperty(k) )
				obj = obj[k];
			else break;
		}
		return obj;
	}

	static trueishFilter(value){
		return !!value;
	}
	static uniqueFilter(value, index, self) { 
		return self.indexOf(value) === index;
	}
	static uniquePropertyFilter(key) { 
		return (item, index, self)=>self.findIndex(v=>v[key] == item[key]) === index;
	}
	static arrayFindSplice(arr, item){
		let p = arr.indexOf(item);
		return p > -1 ? arr.splice(p,1)[0] : null;
	}

	static matchReplace(str, map) {
		Object.keys(map).forEach(key=>str = str.replace('%'+ key +'%', map[key]));
		return str;
	}

	static isValidValue(value){
		return value !== null && value !== undefined && String(value).trim() !== '';
	}

	static addPadding(value, pad){
		return (pad + value).slice(-pad.length);
	}

	static getNextWord(str){
		// gets the next word or next non-word character
		var m = str.match(/^([a-z0-9\-_]+|.{1})/i);
		return m ? m[0] : undefined;
	}

	static getLinkDomain(link){
		let m = /https?:\/\/((?:\w+\.)+\w{2,6})\/?.*/.exec(link);
		if ( m ) return m[1];
		return link;
	}

	static deepCopy(obj){
		return JSON.parse(JSON.stringify(obj));
	}

	static superList(list){
		list.byId = Object.create(null);
		list.byName = Object.create(null);
		list.forEach(item=>list.byId[item._id] = list.byName[item.name] = item);
		return list;
	}

	static superMap(obj, param, fn){
		let list = Object.keys(obj).map((key)=>angular.extend({_id: key, name: obj[key]}, param||{}));
		list.sort(Helper.sortByName);
		list.byId = Object.create(null);
		list.forEach((item)=>{
			list.byId[item._id] = item;
		});
		if ( fn ) fn(list);
		return list;
	}


	static debounce(func, delay){
		let inDebounce;
		return function() {
			const context = this;
			const args = arguments;
			clearTimeout(inDebounce);
			inDebounce = setTimeout(() => func.apply(context, args), delay);
		}
	}

	static throttle(func, limit, ensure){
		let last = 0, throttled;
		return function() {
			const args = arguments;
			const context = this;
			let now = Date.now();

			if ( last + limit <= now ) {
				last = now;
				func.apply(context, args);
			} else
			if ( ensure ) {
				clearTimeout(throttled);
				throttled = setTimeout(()=>{
					func.apply(context, args);
					last = now;
				}, last + limit - now);
			}
		}
	}


	static toMomentOrNull(value){
		return value ? moment(value) : null;
	}

	static getValidISODate(dateStr){
		return dateStr && moment(dateStr).isValid() ? moment(dateStr) : false;
	}
	static toTimestamp(dateObj){
		return moment.tz(dateObj, SETTINGS.timezone).toISOString().replace(/\.\d{3}Z$/, 'Z');
	}
	static toNoonISO(dateObj){
		return moment.tz(dateObj, SETTINGS.timezone).format('YYYY-MM-DD') +'T12:00:00Z';
	}

	static parseISO(iso){
		var d = iso ? moment(iso) : null;
		return d?.isValid() ? d : null;
	}

	static parseDateTime(value){
		value = (value || '').trim();
		if ( ! value ) return undefined;

		let format;
		let rgx = Array.from(DATE_PATTERNS.keys()).find(rgx=>rgx.test(value));
		if ( rgx ) {
			format = DATE_PATTERNS.get(rgx);
		} else {
			return undefined;
		}

		let tformat, timePart = value.replace(rgx.exec(value)[0], '').trim();
		if ( timePart ) {
			rgx =  Array.from(TIME_PATTERNS.keys()).find(rgx=>rgx.test(timePart));
			if ( rgx ) {
				tformat = TIME_PATTERNS.get(rgx);
				format = format + tformat;
			}
		}

		let mo = moment(value, format);
		mo._hasTime = !!tformat;
		if ( ! mo._hasTime && mo.isValid() ) {
			mo = mo.startOf('day');
		}
		return mo;
	}

	static parseTime(value){
		value = (value || '').trim();
		if ( ! value ) return undefined;
		
		let format;
		let rgx =  Array.from(TIME_PATTERNS.keys()).find(rgx=>rgx.test(value));
		if ( rgx ) {
			format = TIME_PATTERNS.get(rgx);
		} else {
			return undefined;
		}
		let mo = moment(value, format);
		mo._hasTime = true;

		let match = rgx.exec(value);
		mo._hasTimeOnly = match && value.indexOf(match[0]) == 0;
		return mo;
	}

	static formatRelativeDate(dateObj, formats={}){
		const today = moment();
		const date = moment(dateObj);
		if ( date.year() == today.year() ) { // same year
			if ( date.dayOfYear() == today.dayOfYear() ) {// same day
				return date.format(formats.sameDay || SETTINGS.timeZoneFormat);
			}
			return date.format(formats.sameYear || `${SETTINGS.dateFormat0} ${SETTINGS.timeZoneFormat}`);
		}
		return date.format(formats.default || SETTINGS.dateTimeZoneFormat);
	}


	static isSameJSDay(d1, d2) {
		return (
			Helper.isSameJSMonth(d1,d2) &&
			d1.getDate() == d2.getDate()
		);
	}
	static isSameJSMonth(d1, d2) {
		return (
			Helper.isSameJSYear(d1, d2) &&
			d1.getMonth() == d2.getMonth()
		);
	}
	static isSameJSYear(d1, d2) {
		return (
			d1 instanceof Date && 
			d2 instanceof Date &&
			d1.getFullYear() == d2.getFullYear()
		);
	}
	


	static momentToDate(mo){
		let d = new Date();
		d.setYear(mo.year());
		d.setMonth(mo.month());
		d.setDate(mo.date());
		d.setHours(mo.hour());
		d.setMinutes(mo.minute());
		return d;
	}
	static momentCopyDate(src, target){
		if ( src instanceof Date )
			return target.year(src.getFullYear()).month(src.getMonth()).date(src.getDate());
		if ( moment.isMoment(src) )
			return target.year(src.year()).month(src.month()).date(src.date());
	}

	static mergeObjects(list){
		let obj = Object.create(null);
		list.forEach((item)=>{
			Object.keys(item).forEach((key)=>{
				obj[key] = item[key];
			});
		});
		return obj;
	}

	static removeFrList(list, item){
		let p = list.indexOf(item);
		if ( p > -1 ) list.splice(p, 1);
		return p > -1;
	}

	// static distBetween(scale, index, count, value) {
	// 	if ( index === 0 ) return 0;
	// 	if ( index === count-1 ) return value;
	// 	return Math.round(scale * );
	// }

	// static distBetween(scales, max){
	// 	scales.map((v, i)=>{
	// 		if ( i ===  )
	// 	});
	// }

	static focusFormError(form){
		if ( !form.$pending && form.$invalid && Object.keys(form.$error || {}).length > 0 ) {
			let $el = $();
			Object.values(form.$error).forEach(list=>list.forEach($item=>{
				$el = $el.add($item.$$element);
				$item.$setTouched && $item.$setTouched();
			}));
			if ( $el.length > 0 ) {
				$el[0].focus();
				try { $el[0].scrollIntoView && $el[0].scrollIntoView({behavior:'smooth', block:'center'});
				} catch(e){}
			}
			return true;
		}
	}


	static isValidURL(str){
		return /^\w+:\/\/.+/.test(str);
	}

	/**
   * This will take a full url & find characters that aren't url safe then encode them.
	 */
	static encodeSafeURL(url){
		return url.replace(/^(\w+:\/\/)(.+)$/, (m, g1, g2)=>g1 + g2.replace(/([^\/a-zA-Z0-9_\-])/, (m, g1)=>encodeURIComponent(g1)));
	}

	static downloadURL(url, filename='download'){
		let a = document.createElement('a');
		a.href = url;
		a.download = filename;
		a.target = '_blank';
		a.click();
	}


	static stripTags(text, replace){
		if ( text )
			text = String(text).replace(/<[^>]+>/gm, replace||'');
		return text;
	}
	static stripOtherTags(text, tags, replace) {
		return Helper.replaceTags(text, tags, null, replace || '');
	}
	static replaceTags(text, tags='', replaceMatch='', replaceOther=null) {
		if ( text ) {
			tags = tags.split(',');
			text = String(text).replace(/<\/?([^/>]+)\/?>/gm, (m, content)=>{
				let tag = content.split(/\s+/)[0];
				if ( tags.includes(tag) )
					return replaceMatch!=null ? m.replace(m, replaceMatch) : m;
				return replaceOther!=null ? m.replace(m, replaceOther) : m;
			});
		}
		return text;
	}

	static decodeHtml(html){
		if ( /&([a-z]+|#\d+);/i.test(html) ) { // has html entities
			const txt = document.createElement("textarea");
			txt.innerHTML = html;
			return txt.value;
		}
		return html;
	}
	static encodeHtml(text){
		return text.replace(/[\u00A0-\u9999<>\&]/g, i=>`&#${i.charCodeAt(0)};`);
	}

	static plainLinksToHtml(text='', tpl=null){
		return text.replace(/\[(.+?)\|(\w{3,}?:\/\/.+?)]/gm, tpl || '<a href="$2" target="_blank" rel="nofollow">$1</a>');
	}
	static newlinesToHtml(text=''){
		return text.replace(/[\n\r]/g, '<br/>');
	}

	static camelCase(text){
		return text.split(/[^a-z0-9]/i).map(word=>Helper.capitalFirstLetter(word)).join('')
	}
	static capitalFirstLetter(word){
		return (word.length > 0 ? word[0].toUpperCase() : '') + (word.length > 1 ? word.substr(1).toLowerCase() : '');
	}

	static htmlToText(html){
		return Helper.decodeHtml(
						Helper.stripTags(
							(html || '')
								.replace(/[\n\r]/gm, '') // remove existing CR+LF
								// .replace(/((&#13;)?&#10;|&#13;)/gm, '\n') // CR+LF
								.replace(/<br \/>/igm, '\n')
								.replace(/(<\/(?:p|h\d|blockquote|div|hr|ol|ul)>)(\s*)(<(?:p|h\d|blockquote|div|hr|ol|ul)>)/igm, '$1\n\n$3')
						)
		);
	}
	static textToHtml(text){
		text = (text || '')
			.replace(/&/gm, '&amp;')
			.replace(/</gm, '&lt;')
			.replace(/>/gm, '&gt;')
			.replace(/[\n\r]{2,}/gm, '</p><p>')
			.replace(/[\n\r]/gm, '<br />');
		return `<p>${text}</p>`;
	}


	static getCrawlValue(obj, keys){
		for(var i=0, len=keys.length; i<len; i++){
			if ( obj )
				obj = obj[keys[i]];
			else
				return obj;
		}
		return obj;
	}
	static setCrawlValue(obj, keys, value){
		for(var i=0, len=keys.length; i<len; i++){
			if ( obj ) {
				let key = keys[i];
				if ( i < len-1 ) // not last
					obj = obj[key];
				else
					return obj[key] = value;
			}
		}
	}


	static getOffsetTop(elem){
		let top = elem.offsetTop,
			parent = elem.offsetParent;
		while( parent ) {
			top += parent.offsetTop;
			parent = parent.offsetParent;
		}
		return top;
	}


	static smoothScrollTo(target, container, done){
		target = $(target).get(0);
		container = $(container || $(target).parents().filter(function(){
				return this.scrollHeight > this.clientHeight && ['auto', 'scroll'].includes($(this).css('overflowY'));
			})).get(0);

		if ( container && target && target.offsetParent ) {
			target.scrollIntoView({block:'center', behavior:'smooth'});
			setTimeout(done, 500);
			// let top = target.offsetTop,
			// 	parent = target.offsetParent;

			// while( parent && parent !== container ) {
			// 	top += parent.offsetTop;
			// 	parent = parent.offsetParent;
			// }
			// if ( target.clientHeight < container.clientHeight ) {
			// 	top -= (container.clientHeight - target.clientHeight)/2;
			// }
			// top = Math.max(0, Math.min(top, container.scrollHeight - container.clientHeight));

			// $(container).animate({scrollTop: top}, 300, done);

			// if ( top < container.scrollTop || top > container.scrollTop + container.clientHeight ) // not in view
			// 	return true;
		} else
		if ( done ) {
			done();
		}
		return false;
	}


	static modifyStyleString(str, obj){
		let map = {}, keys = Object.keys(obj);

		str.split(';').forEach(pair=>{
			pair = pair.split('=');

			if ( keys.includes(pair[0]) )
				map[pair[0]] = obj[pair[0]];
			else
				map[pair[0]] = pair.length > 1 ? pair[1] : '';
		});
		keys.forEach(k=>{
			if ( ! map.hasOwnProperty(k) )
				map[k] = obj[k];
		});

		return Object.keys(map).map(k=>{
			if ( !k || map[k] === undefined )
				return null;
			if ( map[k] === '' )
				return k;
			return `${k}=${map[k]}`;
		}).filter(v=>!!v).join(';');
	}

	static _modifyStyleString(str, key, value){
		let map = {};
		str.split(';').forEach(pair=>{
			pair = pair.split('=');

			if ( key == pair[0] )
				map[pair[0]] = value;
			else
				map[pair[0]] = pair.length > 1 ? pair[1] : '';
		});
		if ( ! map.hasOwnProperty(key) )
			map[key] = value;

		return Object.keys(map).map(k=>{
			if ( !k || map[k] === undefined )
				return null;
			if ( map[k] === '' )
				return k;
			return `${k}=${map[k]}`;
		}).filter(v=>!!v).join(';');
	}


	static throttledArrayPush(list, item, delay, onPush, pushFirst){
		const data = list.__throttle = list.__throttle || {};
		const pushNext = ()=>{
			if ( data.pending.length > 0 ) {
				list.push(item = data.pending.shift());
				typeof onPush == 'function' && onPush(list, item);
			} else {
				clearInterval(data.iid);
				delete list.__throttle; // cleanup
			}
		};

		(data.pending = data.pending || []).push(item);
		if ( ! data.iid ) { // no interval existing
			pushFirst && pushNext(); // push first immediately
			data.iid = setInterval(pushNext, delay || 250);
		}
	}


	static calcTaskProgress(count){
		return 1 - (1 - TASKPROG_INIT_RATE) * Math.pow(1 - TASKPROG_FACTOR, count);
	}

  static hasLangTags(str) {
    let localeRegEx = /[^{\}]+(?=})/g;
    if ( str.match(localeRegEx) ) {
      return true;
    } 
    return false;
  }

	static convertToLangSpan(str=''){
		try {
			return Helper.quickLangToSpan(str);
		} catch (err) {
			return str;
		}
	}

	static quickLangToSpan(str, offset=0){
		const startRegex = /{{{(\w+)}}}/i;

		let sub = str.substr(offset);
		let res = sub.match(startRegex);
		if ( res ) {
			let index = res.index + offset;
			let lang = res[1];
			let span = `<span lang="${lang}">`;
			sub = sub.replace(res[0], span);

			str = str.substr(0, offset) + sub;
			offset = index + span.length;
			str = Helper.quickLangToSpan(str, offset);
			sub = str.substr(offset);

			let res1 = sub.match(new RegExp(`{{{/${lang}}}}`));
			if ( ! res1 ) {
				let res2 = sub.match(/{{{\/(\w+)}}}/i);
				let err;
				if ( res2 ) {
					err = new Error(`Unexpected closing language tag {{{/${res2[1]}}}}, expecting {{{/${lang}}}}`);
					err.type = 'lang-mismatch';
				} else {
					err = new Error(`Missing closing language tag {{{/${lang}}}}`);
					err.type = 'lang-missing';
				}
				throw err;
			}
			
			str = str.substr(0, offset) + str.substr(offset).replace(res1[0], `</span>`);
		}
		return str;
	}

	static toSafeLangHtml(str='', tags='span'){
		return str ? Helper.stripOtherTags(Helper.convertToLangSpan(str), tags, Helper.encodeHtml) : '';
	}

}
