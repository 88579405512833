<div ng-message="required">Required</div>
<div ng-message="date_month">Invalid month, unrecognized value</div>
<div ng-message="date_over_year">Invalid year, over value</div>
<div ng-message="date_over_month">Invalid month, over value</div>
<div ng-message="date_over_day">Invalid day, over value</div>
<div ng-message="date_over_hour">Invalid hour, over value</div>
<div ng-message="date_over_minute">Invalid minute, over value</div>
<div ng-message="date_over_second">Invalid second, over value</div>
<div ng-message="date_over_millisecond">Invalid millisecond, over value</div>
<div ng-message="datetime">Invalid format {{:: dateTimeExample || ctrl.dateTimeExample ? '(must be eg '+ (dateTimeExample || ctrl.dateTimeExample) +')' : '' }}</div>
<div ng-message="date, parse">Invalid format {{:: dateExample || ctrl.dateExample ? '(must be eg '+ (dateExample || ctrl.dateExample) +')' : '' }}</div>