import {SETTINGS} from '../'
import {Helper} from '../classes'


export const validLangDirective = [
(
)=>{
	return {
		restrict: 'AC',
		require: '?ngModel',
		link: (scope, elem, attrs, ngModel)=>{
			if ( ! ngModel ) return; // do nothing if no ng-model

			ngModel.$validators.lang = (modelValue, viewValue)=>{
				ngModel.$setValidity('lang-missing', true);
				ngModel.$setValidity('lang-mismatch', true);
				if ( viewValue ) {
					try {
						Helper.quickLangToSpan(viewValue);
					} catch(err) {
						if ( err.type ) {
							ngModel.$setValidity(err.type, false);
							ngModel.$error[err.type] = err.message;
						}
						return false;
					}
				}
				return true;
			};
		},
	};
}];
validLangDirective.selector = 'validLang';
