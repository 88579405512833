import angular from 'angular'
import {DependencyInjected, Helper} from '../classes'
import {MESSAGES} from '../../common'


export class ErrorPrompt extends DependencyInjected {
	static get selector(){return 'errorPrompt'};
	static get $inject(){return [
		'$mdDialog',
		'$q',
	]};

	show(err, $ev, opts){
		return this.$q.when(this._active || true)
			.finally(()=>this._show(err, $ev, opts));
	}
	_show(err, $ev, opts){
		let loc = this._active = this.$mdDialog.show({
			controller: ErrorPromptController,
			controllerAs: 'ctrl',
			template: TEMPLATE,
			targetEvent: $ev,
			locals: {error: err, options: opts||{}},
			clickOutsideToClose: false,
			escapeToClose: true,
			focusOnOpen: true,
			multiple: false,
		})
		.finally(()=>{
			if ( this._active === loc )
				this._active = undefined;
		});
		return loc;
	}

	get isShown(){
		return Boolean(this._active);
	}

	getTemplate(){
		return TEMPLATE;
	}

	close(){
		this.$mdDialog.hide();
	}
}

class ErrorPromptController extends DependencyInjected {
	static get $inject(){return [
		'$window',
		'$mdDialog',
		'$sce',
		'api',
		'error',
		'options',
	]}

	init(){
		if ( this.api.isApiError(this.error) ) {
			this.title = this.error.title || this.error.name || MESSAGES.API.UNEXPECTED_ERROR;
			if ( ! this.options.expected )
				this.title = MESSAGES.API.UNEXPECTED_ERROR +': '+ this.title;
			this.message = this.error.message || MESSAGES.API.SERVER_ERROR;
		} else {
			this.title = this.error.title || this.error.name || MESSAGES.API.UNEXPECTED_ERROR;
			if ( ! this.options.expected )
				this.title = MESSAGES.API.UNEXPECTED_ERROR +': '+ this.title;
			this.message = this.error.message || MESSAGES.API.UNKNOWN_ERROR;

			let err = this.error;
			if ( err instanceof Error ) {
				err.code = err.code || err.message;
				err.debug = err.debug || (err.stack && err.stack.toString() || '');
			}
		}
		this.message = this.$sce.trustAsHtml(this.message.replace(/[\n\r]/gm, '<br>'));
	}

	refresh(){
		this.$window.location.reload();
	}
	close($e){
		this.$mdDialog.hide();
	}
}



const TEMPLATE = `
<md-dialog role="alertdialog" aria-labelledby="alertdialogTitle" aria-describedby="alertdialogDesc" aria-label="{{:: ctrl.title }}" md-theme="::'default'">
	<md-toolbar class="md-warn">
		<div class="md-toolbar-tools">
			<h2 id="alertdialogTitle"><i class="fas fa-exclamation-triangle"></i>&nbsp;&nbsp;{{:: ctrl.title }}</h2>
			<span flex></span>
			<md-button class="md-icon-button" ng-click="ctrl.close()" aria-label="Close">
				<i class="fas fa-times"></i>
			</md-button>
		</div>
	</md-toolbar>

	<md-dialog-content>
		<div class="md-dialog-content">
			<p class="md-title normal-wg" id="alertdialogDesc" ng-bind-html="ctrl.message"></p>
			<div ng-hide="ctrl.options.noDebug">
				<hr>
				<p class="md-body-1">Please do the following:</p>
				<ol class="md-body-1">
					<li>
						Copy this entire text:<br>
						<pre class="margin-1x padding-2x smaller" md-colors="::{background:'grey-200'}" style="overflow:auto; white-space:pre-wrap; word-break:break-all; max-height:150px;"
							click-Select>{{:: ctrl.error.code +'\n' }}{{ ::ctrl.error.debug }}</pre>
						and send to <a href="mailto:mycoachsupport@beyond12.org">mycoachsupport@beyond12.org</a><br><br>
					</li>
					<li ng-hide="ctrl.options.noRefresh">Refresh your browser to ensure data you see is in the correct state.</li>
				</ol>
			</div>
		</div>
	</md-dialog-content>

	<md-dialog-actions layout="row">
		<span flex></span>
		<md-button ng-click="ctrl.refresh()" ng-hide="ctrl.options.noRefresh"><i class="fas fa-sync-alt"></i>&nbsp;&nbsp;Refresh Page</md-button>
		<md-button ng-click="ctrl.close()">Close</md-button>
	</md-dialog-actions>
</md-dialog>
`;
