import angular from 'angular'
import ngSanitize from 'angular-sanitize'
import ngMaterial from 'angular-material'
import ngAnimate from 'angular-animate'
import ngFileUpload from 'ng-file-upload'
import uiRoute from '@uirouter/angularjs'
import ngJwt from 'angular-jwt'
// import ngCookies from 'angular-cookies'

import smoothscroll from 'smoothscroll-polyfill';

import 'angular-moment-picker'
import 'angular-moment-picker/dist/angular-moment-picker.css'
import './styles/moment-picker.scss'

import './styles/_animations.scss'
import './styles/_variables.scss'

import * as directives from './directives'
import * as services from './services'
import * as animations from './animations'

import {TEMPLATE_FILES} from './templates'

import {Helper} from './classes/Helper.class'
const MESSAGES = require('./messages.json')

import {
	themeConfig,
	momentPickerConfig,
	pageTitle,
	idleCheck,
	SETTINGS,
} from './index'


const module = angular.module('app.commonModule', [
		uiRoute,
		ngMaterial,
		ngSanitize,
		ngAnimate,
		ngJwt,
		// ngCookies,
		'moment-picker',
		ngFileUpload,
	])

	.constant('API_URI', window.API_URI)
	.constant('MATRIX_URI', window.MATRIX_URI)
	.constant('MAPPINGS_JSON', window.mappings_json)
	.constant('Helper', Helper)
	.constant('MESSAGES', MESSAGES)
	.constant('BEYOND12_ID', window.BEYOND12_ID)

	.factory('$exceptionHandler', services.ErrorHandlerFactory)

	.filter('stripTags', ()=>Helper.stripTags)
	.filter('stripOtherTags', ()=>Helper.stripOtherTags)
	.filter('replaceTags', ()=>Helper.replaceTags)
	.filter('decodeHtml', ()=>Helper.decodeHtml)
	.filter('camelCase', ()=>Helper.camelCase)

  .config(themeConfig)
  .config(momentPickerConfig)
	.run(pageTitle)
	.run(idleCheck)

	.run(['$templateCache', ($templateCache)=>{
		for(const item of Object.values(TEMPLATE_FILES)) {
			$templateCache.put(item.name, item.file);
		}
	}])

	// TODO: remove when mappings has college terms already
	.run(['MAPPINGS_JSON', '$state', (MAPPINGS_JSON, $state)=>{
		if ( MAPPINGS_JSON ) {
			MAPPINGS_JSON.colleges.terms = MAPPINGS_JSON.colleges.terms || {
				semester: 'Semester',
				// trimester: 'Trimester',
				quarter: 'Quarter',
			};
		} else {
			$state.target('error.500', null, {location:false});
		}
	}])

  .run(['$rootScope', ($rootScope)=>{
    $rootScope.SETTINGS = SETTINGS;
    $rootScope.BEYOND12_ID = window.BEYOND12_ID;
		
    moment.tz.setDefault(SETTINGS.timezone);
		
		smoothscroll.polyfill();
  }]);


angular.forEach(services, (item)=>{
	item.selector && module.service(item.selector, item);
});
angular.forEach(directives, (item, key)=>{
	module.directive(item.selector ?? key.replace('_directive', ''), item);
});
angular.forEach(animations, (item)=>{
	item.selector && module.animation(item.selector, item);
});


export default module.name;
