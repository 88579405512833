import {ApiError} from '../services';

export const validRecipientDirective = [
  '$q',
  'api',
  'errorPrompt',
  'toast',
  '$timeout',
(
  $q,
  api,
  errorPrompt,
  toast,
  $timeout,
)=>{
	return {
		restrict: 'A',
		require: '?ngModel',
    scope: {
      error: '=?recipientError',
      required: '<ngRequired',
    },
		link: ($scope, elem, attrs, ngModel)=>{
			if ( ! ngModel ) return; // do nothing if no ng-model

			ngModel.$asyncValidators.expr = function(modelValue, viewValue){
        if ( ! $scope.required ) return $q.resolve();
        return api.post('recipients/validation', {expression: viewValue || modelValue || ''}, {level: ApiError.LEVEL.MANUAL})
          .catch(err=>{
            if ( err && ApiError.isApiError(err) ) {
              let data = err.response?.data;
              $scope.error = data?.errors?.[1]?.message || data?.invalid_expression || '';
              $scope.error = $scope.error.replace(/(expression)\:.*/, '$1');
              if ( ! /^4\d\d$/.test(err.response.status) ) {
                toast.warn('Recipient validation is unavailable');
                return $q.resolve();
              }
            } else
            if ( err ) {
              errorPrompt.show(err);
            }

            let defer = $q.defer();
            $timeout(()=>defer.reject(err.message), 200);
            return defer.promise;
            // return $q.reject(err.message);
          })
			}
		},
	};
}];
validRecipientDirective.selector = 'validRecipient';
