import $ from 'jquery'

let google;

// this factory returns a promise, always wrap it with a $q.when()
export function GoogleMapsFactory($window, $q){
  // BTG-1 BTC-29 google maps
  // if ( ! $('#googlemaps').length ) {
    let defer = $q.defer();
    let script = document.createElement('script');
    script.id = 'googlemaps';
    script.src = `https://maps.googleapis.com/maps/api/js?key=${$window.GAPIKEY}&callback=__initGoogleMap`;
    script.defer = true;
    script.async = true;
    $window.__initGoogleMap = ()=>{
      google = $window.google;
      delete $window.__initGoogleMap;
      defer.resolve(google);
    };
    $window.document.head.appendChild(script);
    return defer.promise;
  // }
  // return $q.resolve(google);
}

GoogleMapsFactory.selector = 'googleMapsFactory';
GoogleMapsFactory.$inject = ['$window', '$q'];