<button type="button" class="md-datepicker-triangle-button md-icon-button md-button"
  aria-label="open calendar">
  <div class="md-datepicker-expand-triangle"></div>
</button>

<div class="datepicker-dialog md-whiteframe-1dp" role="dialog" aria-modal="true" aria-label="Choose Date">
  <div class="header">
    <button type="button" class="focusable prev-year" tabindex="0" aria-label="previous year">
      <span class="fas fa-angle-double-left fa-lg"></span>
    </button>

    <button type="button" class="focusable prev-month" tabindex="0" aria-label="previous month">
      <span class="fas fa-angle-left fa-lg"></span>
    </button>

    <h2 id="id-grid-label" class="month-year" aria-live="polite">February 2020</h2>

    <button type="button" class="focusable next-month" tabindex="0" aria-label="next month">
      <span class="fas fa-angle-right fa-lg"></span>
    </button>

    <button type="button" class="focusable next-year" tabindex="0" aria-label="next year">
      <span class="fas fa-angle-double-right fa-lg"></span>
    </button>
  </div>

  <div class="table-wrap"><table class="dates" role="grid" aria-labelledby="id-grid-label">
    <thead></thead>
    <tbody></tbody>
  </table></div>

  <!-- <div class="dialog-ok-cancel-group">
    <button class="dialog-button" value="cancel">Cancel</button>
    <button class="dialog-button" value="ok">OK</button>
  </div> -->
  <!-- <div class="dialog-message" aria-live="polite"></div> -->
</div>
        