import moment from 'moment';
import {SETTINGS} from '../'
import {Helper} from '../classes/Helper.class';

const ERR_OVERFLOWS = Object.freeze({
	0: 'year',
	1: 'month',
	2: 'day',
	3: 'hour',
	4: 'minute',
	5: 'second',
	6: 'millisecond',
});

export const dateValidators = (()=>{
	let obj = {'date_month': (val)=>!val || !val.parsingFlags().invalidMonth};
	for(const [i, key] of Object.entries(ERR_OVERFLOWS)){
		obj[`date_over_${key}`] = (val)=>!val || val.parsingFlags().overflow != +i;
	}
	return Object.freeze(obj);
})();

export const validDateDirective = [
(
)=>{
	return {
		restrict: 'A',
		require: 'ngModel',
		priority: -100,
		// scope: {
		// 	model: '=validDate',
		// 	includeTime: '&',
		// 	format: '&',
		// },
		link: (scope, elem, attrs, ngModel)=>{
			if ( ! ngModel ) return; // do nothing if no ng-model

			const isWithTime = ()=>Boolean(scope.$eval(attrs.includeTime));
			const getFormat = ()=>attrs.viewFormat || null;

			let lastViewValue;

			// scope.$watch(attrs.ngModel, (value, old)=>{
			// 	if(value != old) ngModel.$processModelValue();
			// });

			// ngModel.$validators.date = (model)=>!model || model.isValid();
			// ngModel.$validators.datetime = (model)=>!scope.includeTime() || !model || model.isValid();
			const setDateValidity = (value)=>{
				ngModel.$setValidity('date', value);
				ngModel.$setValidity('datetime', isWithTime() ? value : null);
			}

			for(const [key, fn] of Object.entries(dateValidators)) {
				ngModel.$validators[key] = fn;
			}

			// when view changes, update model
			ngModel.$parsers.push((viewValue)=>{
				let model = null;
				lastViewValue = viewValue.trim();
				if ( lastViewValue ) {
					let hasTime = isWithTime();
					let format = getFormat();

					model = Helper.parseDateTime(lastViewValue) || (format ? moment(lastViewValue, format) : undefined);
					if ( model && model.isValid() ) {
						if ( !hasTime ) {
							model._hasTime = false;
							model = model.startOf('day');
						}
						// scope.$evalAsync(()=>ngModel.$processModelValue());
						// if ( ngModel.$modelValue ) {
						// 	ngModel.$modelValue
						// 		.year(model.year()).month(model.month()).date(model.date)
						// 		.hour(model.hour()).minute(model.minute());
						// }

						let value = model.format(getFormat() || (isWithTime() ? SETTINGS.dateTimeFormat : SETTINGS.dateFormat));
						ngModel.$setViewValue(value);
						ngModel.$render();
					}
				}
				setDateValidity(model ? model.isValid() : model===undefined ? false : null);
				return model;
			});

			// when model changes, update view
			ngModel.$formatters.push((model)=>{
				if ( moment.isMoment(model) && model.isValid() ) {
					setDateValidity(true);
					return model.format(getFormat() || (isWithTime() ? SETTINGS.dateTimeFormat : SETTINGS.dateFormat));
				}
				setDateValidity(null);

				// model = ngModel.$modelValue;
				// if ( model ) {
				// 	if ( model.isValid() ) {
				// 		lastViewValue = model.format(getFormat() || (isWithTime() ? SETTINGS.dateTimeFormat : SETTINGS.dateFormat));
				// 	}
				// } else {
				// 	lastViewValue = '';
				// }
				// return lastViewValue;
			});

		},
	};
}];
validDateDirective.selector = 'validDate';
