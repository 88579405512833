import $ from 'jquery'

export const chipsListDirective = [
	'$compile',
(
	$compile,
)=>{
	return {
		restrict: 'A',
		scope: {
			chipsList: '<*',
		},
		link: function($scope, $element, $attr){
			let template = $element.html() || `<md-chip class="flex-none">{{chip.title || chip.name}}</md-chip>`;
			$scope.$watch('chipsList', (newVal, oldVal)=>oldVal !== newVal && render(newVal));
			$scope.$evalAsync(()=>render($scope.chipsList));

			function render(value){
				$element.html('');
				Array.isArray(value) && value.forEach(chip=>{
					let scope = $scope.$new(true);
					scope.chip = chip;
					$element.append($compile(template)(scope));
				});
			}
		}
	};
}];
chipsListDirective.selector = 'chipsList';