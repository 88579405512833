<md-input-container class="md-input-has-value">
  <label role="presentation">Sort</label>
  <md-select name="sort"
    ng-model="ctrl.query.sortBy" 
    ng-change="ctrl.onFiltersChange()">
    <md-option ng-repeat="item in ctrl.mapping.sortBy" ng-value="item._id"><span ng-bind-html="item.name"></span></md-option>
  </md-select>
</md-input-container>
<md-input-container class="md-input-has-value">
  <label>Order</label>
  <md-select name="order"
    ng-model="ctrl.query.sortOrder" 
    ng-change="ctrl.onFiltersChange()">
    <md-option ng-repeat="item in ctrl.mapping.sortOrder" ng-value="item._id"><span ng-bind-html="item.name"></span></md-option>
  </md-select>
</md-input-container>