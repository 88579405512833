import angular from 'angular'
import $, { contains } from 'jquery'
import {Helper, CONSTANTS} from '../'
import tinymce from 'tinymce'

import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.css';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/table';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/code';
import './plugins/alttext/plugin';

import contentUiCss from 'tinymce/skins/ui/oxide/content.css';
import contentCss from 'tinymce/skins/content/default/content.css';


const PLAIN_TEXT = CONSTANTS.CONTENT_TYPE.TEXT;
const HTML_TEXT = CONSTANTS.CONTENT_TYPE.HTML;

export const tinymceDirective = [
	'$sce',
	'$q',
	'$compile',
	'$mdDialog',
	'$timeout',
(
	$sce,
	$q,
	$compile,
	$mdDialog,
	$timeout,
)=>{
	return {
		priority: 20,
		require: ['ngModel', '^?form'],
		restrict: 'A',
		scope: {
			// tinymce: '<*',
			// tinymceOpts: '<*',
			// onInit: 
			plainText: '<*?',
			switchContentType: '<?',
			contentTypeModel: '=?',
		},
		link: function($scope, $element, $attr, ctrls){
			// add this directive as a dependency for the page
			$scope.initDefer = $q.defer();
			$scope.$emit(CONSTANTS.SCOPE_EVENTS.ADD_PAGE_DEPENDENCY, $scope.initDefer.promise);

			$element.data('tinymce', new TinyMCE($scope, $element, $attr, ctrls, $q, $sce, $mdDialog, $compile, $timeout));
		},
	};
}];
tinymceDirective.selector = 'tinymce';


const SWITCH_TEMPLATE = `<md-button class="tinymce-switch md-primary compact smaller pull-right" 
		ng-click="ctrl.changeContentType('${PLAIN_TEXT}')" 
		ng-show="contentTypeModel!='${PLAIN_TEXT}'">
			Switch to plain text
	</md-button>
	<md-button class="tinymce-switch md-primary compact smaller pull-right" 
		ng-click="ctrl.changeContentType('${HTML_TEXT}')" 
		ng-show="contentTypeModel!='${HTML_TEXT}'">
			Switch to rich text
	</md-button>`;
const OPTIONS = {
	deprecation_warnings: false,
  external_plugins: {
   'imagealttext': '/plugins/altText/plugin.js', 
  },
	plugins: 'paste searchreplace autolink autoresize visualchars image link table hr lists emoticons code',
  menubar: 'edit view insert', 
	toolbar: 'formatselect | bold italic underline strikethrough | removeformat | numlist bullist blockquote | imagealttext link unlink emoticons code | language',
	emoticons_database: 'emojis',
	toolbar_drawer: 'sliding',
	hidden_input: false,
	inline: false,
	contextmenu: false,
	schema: 'html4',
	// block_formats: 'Normal=p; Header 1=h1; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Preformatted=pre',
	branding: false,
	valid_elements: 'a[!href],strong/b,blockquote,br/div,h1,h2,h3,h4,h5,h6,hr,em/i,img[!src|!title|!alt],li,ol,p,pre,s/del,span[lang],table[width],tbody,td[colspan|rowspan],tfoot,th,thead,tr,u,ul',
	valid_children : '-a[img]',
	// valid_children : '-a[img],-li[p|blockquote|pre|h1|h2|h3|h4|h5|h6|table|img]',
	min_height: 240,
	max_height: 720,
	formats: {
		bold: {inline: 'strong'},
		italic: {inline: 'em'},
		underline: {inline: 'u'},
		strikethrough: {inline: 's'},
		removeformat: [
			{selector:'b,strong,em,i,u,s,del', remove:'all', split:true, expand:false, deep:true},
			{selector:'h1,h2,h3,h4,h5,h6', remove:'all', split:false, expand:false, block_expand:true, deep:true},
		],
	},
	image_description: true,
	image_dimensions: false,
	image_title: true,
	image_uploadtab: false,
	file_picker_types: 'image',
	link_title: false,
	link_target: false, 
	target_list: false,
	table_toolbar: 'tableMergeCells tableSplitCells | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | tabledelete',
	table_default_attributes: {width:'100%'},
	table_default_styles: {},
	table_appearance_options: false,
	table_responsive_width: true,
	table_advtab: false,
	table_cell_advtab: false,
	table_row_advtab: false,
	table_resize_bars: false,
	table_style_by_css: false,
	skin: false,
	content_css: false,
	content_style: [
		contentUiCss.toString(),
		contentCss.toString(),
		'.mce-content-body img {max-width:100%;} ',
		'.mce-content-body {line-height:1.5em}',
	].join('\n'),
	content_langs: [
		{ title: 'English', code: 'en' },
		{ title: 'Spanish', code: 'es' },
		{ title: 'French', code: 'fr' },
		{ title: 'German', code: 'de' },
		{ title: 'Portuguese', code: 'pt' },
		{ title: 'Chinese', code: 'zh' },
		{ title: 'Arabic', code: 'ar'},
		{ title: 'Basque', code: 'eu'},
		{ title: 'Bulgarian (Bulgaria)', code: 'bg_BG'},
		{ title: 'Catalan', code: 'ca'},
		{ title: 'Chinese (Taiwan)', code: 'zh_TW'},
		{ title: 'Croatian', code: 'hr'},
		{ title: 'Czech', code: 'cs'},
		{ title: 'Danish', code: 'da'},
		{ title: 'Dutch', code: 'nl'},
		{ title: 'Finnish', code: 'fi'},
		{ title: 'French (France)', code: 'fr_FR'},
		{ title: 'Greek', code: 'el'},
		{ title: 'Hebrew (Israel)', code: 'he_IL'},
		{ title: 'Hungarian (Hungary)', code: 'hu_HU'},
		{ title: 'Indonesian', code: 'id'},
		{ title: 'Italian', code: 'it'},
		{ title: 'Japanese', code: 'ja'},
		{ title: 'Kazakh', code: 'kk'},
		{ title: 'Persian', code: 'fa'},
		{ title: 'Korean (Korea)', code: 'ko_KR'},
		{ title: 'Norwegian Bokmål (Norway)', code: 'nb_NO'},
		{ title: 'Polish', code: 'pl'},
		{ title: 'Portuguese (Brazil)', code: 'pt_BR'},
		{ title: 'Portuguese (Portugal)', code: 'pt_PT'},
		{ title: 'Romanian', code: 'ro'},
		{ title: 'Russian', code: 'ru'},
		{ title: 'Slovak', code: 'sk'},
		{ title: 'Slovenian (Slovenia)', code: 'sl_SI'},
		{ title: 'Swedish (Sweden)', code: 'sv_SE'},
		{ title: 'Thai (Thailand)', code: 'th_TH'},
		{ title: 'Turkish', code: 'tr'},
		{ title: 'Ukrainian', code: 'uk'},
	],
};

class TinyMCE {
	constructor($scope, $textarea, $attr, controllers, $q, $sce, $mdDialog, $compile, $timeout){
		$scope.ctrl = this;
		this.$scope = $scope;
		this.$textarea = $textarea;
		this.$attr = $attr;
		this.$q = $q;
		this.$mdDialog = $mdDialog;
		this.$timeout = $timeout;

		let ngModel = this.ngModel = controllers[0];
		this.form = controllers[1] || null;
		this.editor = null;


		if ( $scope.switchContentType ) {
			$compile(SWITCH_TEMPLATE)($scope.$new(), (html, scope)=>{
				scope.ctrl = this;
				$(this.$textarea).after(html);
			});
		}


		this.save = Helper.debounce(()=>{
			if ( this.editor?.isDirty() ) {
				this.editor.save();

				let plain = this.editor.getContent({format:'text'}).trim();
				let value = plain.length > 0 ? this.editor.getContent({format:'html'}) : '';

				ngModel.$setViewValue(value);
				ngModel.$commitViewValue();
				ngModel.$setDirty();
				$textarea.val(value).trigger('input').trigger('change');
				$scope.$applyAsync();
			}
		}, 400);


		ngModel.$overrideModelOptions({debounce: 250});
		ngModel.$render = ()=>{
			let value = ngModel.$modelValue || '';
			if ( this.editor ) {
				this.editor.setContent(value);
				if ( $scope.contentTypeModel==HTML_TEXT )
					this.editor.fire('change');
				$textarea.val(value).trigger('input', 'sync').trigger('change', 'sync');
			}
		};

		$attr.$observe('disabled', val=>this.toggleDisable(val));

		this.destructors = [
			$scope.$on('$destroy', ()=>{
				if ( this.editor ) this.editor.remove();
				while( this.destructors.length ) this.destructors.pop()();
			})
		];


		this.init();
	}
	init(){
		const opts = angular.extend({}, {
			target: this.$textarea[0],
			setup: edtr=>{
				edtr.on('init', ()=>{
					if ( ! this.$scope.contentTypeModel ) {
						this.$scope.contentTypeModel = PLAIN_TEXT;
					}
					let isPlain = this.$scope.contentTypeModel==PLAIN_TEXT;
					if ( isPlain ) {
						edtr.hide();
						this.$textarea.val(this.ngModel.$viewValue)//.trigger('input');
					} else {
						// this.ngModel.$render();
						edtr.setContent(this.ngModel.$modelValue || '', {format: 'html'});
					}

					this.ngModel.$setPristine();
					this.ngModel.$setUntouched();
					this.editor = edtr; // is ready
					this.$textarea.parents('md-input-container')
							.toggleClass('has-tinymce', !isPlain)
							.toggleClass('has-tinymce-plain', isPlain);
					setTimeout(()=>{
						this.$attr.onInit && this.$scope.$evalAsync(()=>this.$scope.$parent.$eval(this.$attr.onInit));
						this.$scope.initDefer.resolve();
					}, 500);

					this.destructors.push(
						this.$scope.$watch('contentTypeModel', (type, old)=>{
							this.$scope.contentTypeModel = type || PLAIN_TEXT;
							type && type!=old && this.toggleContentType(type==HTML_TEXT)
						})
					);
				});

				// Update model when:
				// - a button has been clicked [ExecCommand]
				// - the editor content has been modified [change]
				// - the node has changed [NodeChange]
				// - an object has been resized (table, image) [ObjectResized]
				edtr.on('ExecCommand change NodeChange ObjectResized', ()=>this.save());

				edtr.on('focus', ()=>{
					this.$timeout(()=>this.$textarea.trigger('focus'), 10);
					let $parent = this.$textarea.parents('md-input-container').first();
					if ( $parent.length ) Helper.smoothScrollTo($parent);
				});
				edtr.on('blur', ()=>this.$timeout(()=>this.$textarea.trigger('blur'), 10));

			},
		}, OPTIONS);

		this.$q.when(tinymce.init(opts) || true)
			.then(()=>this.toggleDisable(this.$attr.disabled));
	}


	toggleDisable(disabled){
		this.editor?.mode?.set?.(disabled ? 'readonly' : 'design');
		// this.editor.ui.[disabled ? 'hide' : 'show']();
	}

	toggleContentType(isHtml, transformValue){
		if ( isHtml ) {
			if ( this.editor.isHidden() ) {
				if ( transformValue ) {
					let content = Helper.textToHtml(this.ngModel.$modelValue);
					this.$timeout(()=>{ // use timeout to sync other models
						this.editor.setContent(content, {format: 'raw'});
						this.ngModel.$setViewValue(content);
						this.ngModel.$commitViewValue();
						this.$textarea.val(content).trigger('input', 'sync').trigger('change', 'sync');
					}, 10);
				}
				this.editor.show();
				this.$textarea.parents('md-input-container')
					.addClass('has-tinymce')
					.removeClass('has-tinymce-plain');
			}
		} else
		if ( ! this.editor.isHidden() ) {
			let height = $(this.editor.getContainer()).height();
			this.editor.hide();
			
			if ( transformValue ) {
				let content = Helper.htmlToText(this.ngModel.$modelValue);
				this.$timeout(()=>{ // use timeout to sync other models
					this.ngModel.$setViewValue(content);
					this.ngModel.$commitViewValue();
					this.$textarea.val(content).trigger('input', 'sync').trigger('change', 'sync');
				}, 10);
			}
			this.$textarea.parents('md-input-container')
				.removeClass('has-tinymce')
				.addClass('has-tinymce-plain');
		}
		this.$scope.$evalAsync();
	}

	changeContentType(type){
		if ( type == PLAIN_TEXT && this.$scope.contentTypeModel==HTML_TEXT ) {
			let isEmpty = Helper.stripTags(this.editor.getContent()).length == 0;
			return this.$q.when(isEmpty || this.$mdDialog.show(
				this.$mdDialog.confirm()
						.title('Plain Text')
						.textContent('Current formatting will be removed when switching to plain text.\n\nContinue?')
						.ariaLabel('alert')
						.ok('Yes')
						.cancel('No')
			)).then(()=>{
				this.$scope.contentTypeModel = type;
				this.toggleContentType(false, true);
			});
		} else {
			this.$scope.contentTypeModel = type;
			this.toggleContentType(type==HTML_TEXT, true);
		}
	}

}