export const isEqualDirective = [
(
)=>{
	return {
		restrict: 'A',
		require: '?ngModel',
		link: (scope, elem, attrs, ngModel)=>{
			if ( ! ngModel ) return; // do nothing if no ng-model

			let validate = ()=>{
				ngModel.$setValidity('isEqual', ngModel.$viewValue===attrs.isEqual);
			}

			var destructors = [
				scope.$watch(attrs.ngModel, validate),

				attrs.$observe('isEqual', validate),

				scope.$on('$destroy', ()=>{
					while( destructors.length )
						destructors.pop()();
				}),
			];
		},
	};
}];
isEqualDirective.selector = 'isEqual';
