import $ from 'jquery'
import {Helper} from '../classes'

export const onScrollEndDirective = [
	'$mdUtil',
	'$animateCss',
	'$$rAF',
	'$window',
(
	$mdUtil, 
	$animateCss, 
	$$rAF,
	$window,
)=>{
	return {
		restrict: 'AC',
		link: ($scope, $element, attrs)=>{
			let $this = $($element);
			let offset = +attrs.onScrollEndOffset || 0;
			let calculate = Helper.throttle(()=>{
				if ( $this.scrollTop() + $this.height() + offset >= $this.prop('scrollHeight') ) {
					$scope.$evalAsync(attrs.onScrollEnd);
				}
			}, 200, true);

			$this.on('scroll', calculate);
			$($window).on('resize', calculate);

			let destructors = [
				$scope.$on('$destroy', ()=>{
					while( destructors.length > 0 )
						destructors.pop()();
					$this.parents().off('scroll', calculate);
					$($window).off('resize', calculate);
				})
			];
		},
	}
}];
onScrollEndDirective.selector = 'onScrollEnd';
