<md-input-container class="filters" ng-show="ctrl.filters._$keys.mydistricts" md-no-float="true">
  <md-select multiple
    aria-label="districts"
    ng-model="ctrl.filters._mydistricts" 
    ng-change="ctrl.onFiltersChange()"
    md-selected-text="'Districts'">
    <md-option ng-repeat="item in ctrl.mapping.mydistricts" ng-value="item">{{ item.name }}</md-option>
  </md-select>
</md-input-container>
<md-input-container class="filters" ng-show="ctrl.filters._$keys.mycolleges" md-no-float="true">
  <md-select multiple
    aria-label="colleges"
    ng-model="ctrl.filters._mycolleges" 
    ng-change="ctrl.onFiltersChange()"
    md-selected-text="'Colleges'">
    <md-option ng-repeat="item in ctrl.mapping.mycolleges" ng-value="item">{{ item.name }}</md-option>
  </md-select>
</md-input-container>
<!-- <md-input-container class="filters" ng-show="ctrl.filters._$keys.myterms" md-no-float="true">
  <md-select multiple
    aria-label="term"
    ng-model="ctrl.filters._myterms" 
    md-on-close="headerForm.terms.$dirty && ctrl.updateFilters(true)"
    md-selected-text="'Term'">
    <md-option ng-repeat="item in ctrl.mapping.myterms" ng-value="item">{{ item.name }}</md-option>
  </md-select>
</md-input-container> -->
<md-input-container class="filters" ng-show="ctrl.filters._$keys.mycohorts" md-no-float>
  <md-select multiple
    aria-label="cohorts"
    ng-model="ctrl.filters._mycohorts" 
    ng-change="ctrl.onFiltersChange()"
    md-selected-text="'Cohorts'">
    <md-option ng-repeat="item in ctrl.mapping.mycohorts" ng-value="item">{{ item.title }}</md-option>
  </md-select>
</md-input-container>
<md-input-container class="filters" ng-show="ctrl.filters._$keys.mylevels" md-no-float="true">
  <md-select multiple
    aria-label="levels"
    ng-model="ctrl.filters._mylevels" 
    ng-change="ctrl.onFiltersChange()"
    md-selected-text="'Levels'">
    <md-option ng-repeat="item in ctrl.mapping.mylevels" ng-value="item">{{ item.title }}</md-option>
  </md-select>
</md-input-container>