import $ from 'jquery'
import {Helper} from '../classes'
import {ApiError} from './api.service'
import {MESSAGES} from '../../common'


export const ErrorHandlerFactory = [
	'$window',
	'$state',
	'$injector',
(
	$window,
	$state,
	$injector,
)=>{
	let toastError = undefined;
	let authentication = undefined;
	let gtag = undefined;

	return (err, cause)=>{
		if ( err === null ) return;

		toastError = toastError || Helper.throttle((e)=>$injector.get('toast').error(e), 4000);
		authentication = authentication || $injector.get('authentication');
		gtag = gtag || $injector.get('gtag');

		if ( err instanceof Error ) {
			// err.code = err.message;
			err.debug = (err.stack && err.stack.toString() || '');
		} else {
			if ( err.indexOf('Possibly unhandled rejection:') === 0 )
				return console.warn(err);
			err = new Error(err);
			err.code = err.debug = cause;
		}
		err.message = MESSAGES.API.UNEXPECTED_ERROR;

		if ( authentication.isValid() )
			$state.go('app.500', {error: err}, {location:false, reload:false}).catch(()=>toastError(err));
		else
			$state.go('guest.login').then(()=>toastError(err));
		console.error('unhandled', err, cause);

		gtag('event', 'exception', {
				fatal: true,
				event_label: 'unhandled',
				description: err.name +'\n'+ err.code,
			});
	};
}]