import $ from 'jquery'

export const popHelperDirective = [
	'$window',
  '$timeout',
  '$compile',
(
	$window,
  $timeout,
  $compile,
)=>({
  restrict: 'AE',
  scope: {
    value: '<?popHelper',
  },
  compile: tElement=>{
    const content = $('<div class="pop-helper-content md-whiteframe-1dp"><span class="far fa-question-circle"></span></div>');
    
    while (tElement[0].firstChild) {
      let child = tElement[0].lastChild;
      tElement[0].removeChild(child);
      content.append(child);
    }
    tElement.addClass('pop-helper');
    tElement.append('<span class="far fa-question-circle"></span>');
    tElement.attr('tabindex', '-1');
    tElement.attr('aria-hidden', 'true');
    tElement.attr('aria-role', 'tooltip');

    return ($scope, $element)=>{
      let $content;
      $compile(content)($scope, cloned=>{
        $content = $(cloned);
        // $content.insertAfter($element);
        $element.prepend($content);
      });

      let visible = false;

      $element
        .on('click', evt=>{
          visible = ! visible;
          $content.attr('aria-hidden', String(!visible));
          $element.toggleClass('open', visible);
          setTimeout(()=>$content.toggleClass('show', visible), 0);
          evt.stopPropagation();
          evt.preventDefault();
        })
        .on('keydown', evt=>{
          if ( evt.keyCode == 27 && visible ) {
            visible = false;
            $element.removeClass('open');
            $content.removeClass('show');
          }
        })
        .on('blur', evt=>{
          if ( visible ) {
            visible = false;
            $element.removeClass('open');
            $content.removeClass('show');
          }
          evt.stopPropagation();
        });

      $scope.$on('$destroy', ()=>{
        $element.off('click.popHelper');
        $($window).off('click.popHelper');
      });
    };
  },
})];
popHelperDirective.selector = 'popHelper';
