import $ from 'jquery'
import angular from 'angular'


export const toggleExpandDirective = [
	'$mdUtil',
	'$animateCss',
	'$$rAF',
	'$timeout',
(
	$mdUtil, 
	$animateCss, 
	$$rAF,
	$timeout,
)=>{
	return {
		restrict: 'AC',
		scope: {
			value: '=toggleExpand',
			duration: '<?duration',
			easing: '<?easing',
			onShow: '&?onExpand',
			onHide: '&?onCollapse',
		},
		link: ($scope, $element)=>{
			let lastValue = undefined;
			let update = (value)=>{
				let $list = $($element).find('.expandable');
				if ( $list.length > 0 )
					$$rAF(()=>{
						$list.show();
						var nHeight = value ? $list[0].scrollHeight : 0;

						if ( ! value )
							$list.css('height', $list[0].scrollHeight +'px');
						$list.removeClass('expanded collapsed');

						$animateCss(angular.element($list[0])).end();
						$animateCss(angular.element($list[0]), {
							structural: true,
							easing: $scope.easing || 'cubic-bezier(0.35, 0, 0.25, 1)',
							to: {height: nHeight + 'px'},
							addClass: value ? 'expanding' : 'collapsing',
							removeClass: !value ? 'expanding' : 'collapsing',
							duration: +($scope.duration || 750) / 1000 // mili to seconds
						}).start().done(()=>{
							cleanUp($list, value);
							$element.trigger(value ? 'expanded' : 'collapsed');
							$scope.$parent.$broadcast('md-resize-textarea');
						});
						$scope.$parent.$broadcast('md-resize-textarea');
					});
				$scope.$applyAsync();
			}
			let cleanUp = ($list, value)=>{
				$element.removeClass('has-expanded has-collapsed').addClass(value ? 'has-expanded' : 'has-collapsed');
				$list.removeClass('expanding collapsing').addClass(value ? 'expanded' : 'collapsed');
				if ( value ) {
					$list.show()
						.css('height', 'auto')
						.attr('aria-hidden', 'false')
						.removeAttr('tab-index');
					$scope.onShow?.();
				} else {
					$list.hide()
						.css('height', '0px')
						.attr('aria-hidden', 'true')
						.attr('tab-index', '-1');
						$scope.onHide?.();
				}
			}

			$element.on('expand', ()=>!lastValue && update($scope.value = lastValue = true))
				.on('collapse', ()=>lastValue && update($scope.value = lastValue = false))

			let destructors = [
				$scope.$watch('value', value=>{
					if ( lastValue === undefined ) {
						cleanUp($($element).find('.expandable'), lastValue = !!value);
					} else
					if ( !value != !lastValue ) {
						update(lastValue = !!value);
					}
				}),

				$scope.$on('$destroy', ()=>{
					$element.off('expand').off('collapse');

					while( destructors.length )
						destructors.pop()();
				}),
			]
		},
	}
}];
toggleExpandDirective.selector = 'toggleExpand';
