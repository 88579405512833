import {DependencyInjected} from '../classes'
import {Authentication} from './authentication.service'


export class Authorization extends DependencyInjected {
  static get $inject(){return [
    Authentication.selector,
    '$uiRouter',
  ]};
  static get selector(){return 'authorization'};

  isValid(){
    return this.authentication.isValid();
  }

  hasPermission(scope){
    if ( ! this.authentication.hasToken() ) return false;
    if ( ! Array.isArray(scope) ) scope = [scope];
    const permissions = this.authentication.getPayload().permissions;
    for( var i=0; i<scope.length; i++ )
      if ( permissions.indexOf(scope[i]) == -1 )
        return false;
    return true;
  }
  hasPermissionAndLevel(object){
    if ( ! this.authentication.hasToken() ) return false;
    const payload =  this.authentication.getPayload();
    const myPermissions = payload.permissions;
    const myRoles = payload.roles;
    
    for( let [name, levels] of Object.entries(object) ) {
      if ( ! myPermissions.includes(name) ) return false;
      if ( levels === true ) return true;
      let found = myRoles.find((role)=>{
        let myLevels = this._rolesMap.byId[role]?.permissions?.[name];
        if ( ! myLevels ) return false;
        for( let level of levels ) {
          if ( myLevels.includes(level) ) // any matching permission level passes
            return true;
        }
      });
      if ( ! found ) return false;
    }
    return true;
  }


  hasStateAccess(stateName){
		let reg = this.$uiRouter.stateRegistry.get(stateName);
		return !reg?.access || this.hasPermissionAndLevel(reg.access);
  }
  hasStateAccessLevel(stateName, level){
    let reg = this.$uiRouter.stateRegistry.get(stateName);
    return !reg?.access || this.hasPermissionAndLevel(
      Object.keys(reg.access)
        .reduce((obj, name)=>Object.assign(obj, {[name]:[level]}), {})
    );
  }


  hasRole(roles){
    if ( this.authentication.hasToken() ) {
      if ( ! Array.isArray(roles) ) roles = [roles];
      for( var i=0; i<roles.length; i++ )
        if ( this.authentication.getPayload().roles.indexOf(roles[i]) !== -1 )
          return true;
    }
    return false;
  }

  isSuperuser(){
    console.warn('deprecated call to authorization.isSuperuser()', (new Error()).stack);
    return this.hasRole('superuser');
  }

  isCollegeAdmin(){
    console.warn('deprecated call to authorization.isCollegeAdmin()', (new Error()).stack);
    return this.hasRole('college_admin');
  }

  isContentAdmin(){
    console.warn('deprecated call to authorization.isContentAdmin()', (new Error()).stack);
    return this.hasRole('content_admin');
  }

  isCoach(){
    console.warn('deprecated call to authorization.isCoach()', (new Error()).stack);
    return this.hasRole('coach');
  }

  isCoachingCorpsCoach(){
    console.warn('deprecated call to authorization.isCoachingCorpsCoach()', (new Error()).stack);
    return this.hasRole('coaching_corps_coach');
  }


  get userId(){
    return this.authentication.hasToken() && this.authentication.getPayload()._id || undefined;
  }

	initRolePermissions(rolesMap){
    this._rolesMap = rolesMap;
    this._permissions = Object.create(null);

    let roleById = {};
    rolesMap.forEach((role)=>roleById[role._id] = role);
    rolesMap.byId = roleById;

    // merge permissions from multiple roles
    const myPermissions = this.authentication.getPayload().permissions;
    const myRoles = this.authentication.getPayload().roles;
    
    myPermissions.forEach((name)=>{
      myRoles.forEach((role)=>{
        let levels = roleById[role]?.permissions?.[name];
        if ( levels ) {
          if ( this._permissions[name] ) {
            this._permissions[name].push(...levels);
          } else {
            this._permissions[name] = levels.concat();
          }
        }
      });
    });

    console.log('permissions', this._permissions);
	}


}