import {SETTINGS} from '../'
import {Helper} from '../classes'


export const validateDirective = [
	'$q',
(
	$q,
)=>{
	return {
		restrict: 'A',
		require: '?ngModel',
		link: (scope, elem, attrs, ngModel)=>{
			if ( ! ngModel ) return; // do nothing if no ng-model
			ngModel.$validators.validate = (modelValue, viewValue)=>viewValue ? scope.$eval(attrs.validate, {$modelValue:modelValue, $viewValue:viewValue}) : true;
		},
	};
}];
validateDirective.selector = 'validate';
