
export const SETTINGS = Object.freeze({
	apiAncestry: 'v2',
	timezone: 'America/Los_Angeles',
	dateFormat: 'MMM D, YYYY',
	dateFormat0: 'MMM D',
	dateFormatYM: 'MMM YYYY',
	dateTimeFormat: 'MMM D, YYYY h:mma',
	timeFormat: 'h:mm a',
	timeFormat0: 'h:mma',
	timeZoneFormat: 'h:mma z',
	dateTimeZoneFormat: 'MMM D, YYYY h:mma z',
	datetimeISO: 'YYYY-MM-DD HH:mm:ss z',
	dateISO: 'YYYY-MM-DD',
	TZ_DEFAULTS: [
		'US/Hawaii',
		'US/Alaska',
		'US/Pacific',
		'US/Arizona',
		'US/Mountain',
		'US/Central',
		'US/Eastern',
	],
});

export default SETTINGS;