<md-input-container class="search flex right-action-btn md-input-has-placeholder" 
  ng-model-options="{debounce:{'default':1000, 'blur':0}, '*':'$inherit'}">
  <label aria-hidden="true">Search</label>
  <md-icon class="fas fa-search"></md-icon>
  <input name="search" ng-model="ctrl.query.search" type="search"
    aria-label="search"
    ng-change="ctrl.onFiltersChange()"
    ng-keypress="$event.keyCode==13 && ctrl.onFiltersChange()">
  <md-button class="md-icon-button compact" aria-label="clear search" ng-click="ctrl.query.search=''; ctrl.onFiltersChange()">
    <md-icon class="fas fa-times"></md-icon>
  </md-button>
</md-input-container>