import {DependencyInjected} from '../classes'


export const errorCardDirective = [()=>({
	template: TEMPLATE,
	restrict: 'EAC',
	controller: ErrorCardController,
	controllerAs: 'ctrl',
	scope: {
		error: '<errorCard',
		retry: '&?errorCardRetry',
	},
	link: (scope, element)=>{
		element.attr('role', 'alert');
	},
})];
errorCardDirective.selector = 'errorCard';


class ErrorCardController extends DependencyInjected {
	static get $inject(){return [
		'$scope',
		'api',
		'errorPrompt',
	]}

	init(){
		if ( this.api.isApiError(this.$scope.error) ) {
			this.message = this.$scope.error.message;
		} else {
			this.message = 'An unexpected error occured';
		}
	}

	retry(){
		this.$scope.$evalAsync(()=>this.$scope.retry());
	}

	openInfo(err, $evt, opts){
		this.errorPrompt.show(err, $evt, opts);
	}
}

const TEMPLATE = `
<md-card class="md-margin" md-theme="::'danger'" ng-if="error">
	<md-card-title>
		<md-card-title-text>
			<h2 class="md-headline margin-0x">
				<span aria-hidden="true"><i class="fas fa-exclamation-triangle"></i>&nbsp;&nbsp;</span>
				{{ error.title || error.name }}
			</h2>
		</md-card-title-text>
	</md-card-title>
	<md-card-content>
		{{ ctrl.message }}
	</md-card-content>
	<md-card-actions layout="row" layout-align="end center">
		<md-button ng-show="retry" ng-click="ctrl.retry()">Try Again</md-button>
		<md-button class="md-icon-button" 
			aria-label="more information on error" title="more information on error"
			ng-click="ctrl.openInfo(error, $event, {noRefresh:!retry})" 
			ng-show="error.debug">
			<i class="fas fa-info-circle" aria-hidden="true"></i>
		</md-button>
	</md-card-actions>
</md-card>
`;
