import $ from 'jquery'
import angular from 'angular'
import {Helper} from '../'

let targets = [
	'input',
	'button',
	'textarea',
	'md-checkbox',
	'md-slider-container',
	'md-slider',
	'md-switch',
	'md-select', // not working
	'md-radio-group',
	'raw-recipient',
	'.md-chips',
	// 'md-autocomplete',
	// 'md-chips',
];

export const formDisabledDirective = [
	'$timeout',
(
	$timeout,
)=>{
	return {
		restrict: 'A',
		scope: true,
		priority: 300,
		link: ($scope, $element, attrs)=>{
			const element = $element.get(0);

			const parse = ()=>$scope.$parent.$formDisabled || !!$scope.$eval(attrs.formDisabled);

			const update = (value, force)=>{
				if ( !force && value === $scope.$formDisabled ) return;
				$scope.$formDisabled = value;

				$element.toggleClass('disabled', !!value);
				const $targets = $element.find(targets.join(',')).not('[no-form-disable], [ng-disabled]');

				if ( value === true ) {
					$targets.addClass('disabled')
						.attr('disabled', 'disabled')
						.attr('aria-disabled', 'true');
					$targets.filter('md-select').removeAttr('tabindex');
				} else {
					$targets.removeClass('disabled')
						.removeAttr('disabled')
						.removeAttr('aria-disabled');
					$targets.filter('md-select').attr('tabindex', '0');
				}

				$scope.$broadcast('check-form-disabled');
			};

			$timeout(()=>update(parse()), 250);

			var destructors = [
				$scope.$parent?.$on('deps-loaded', ()=>$timeout(()=>update(parse(), true), 100)),

				$scope.$on('check-form-disabled', ()=>update(parse())),

				$scope.$watch(parse, (v)=>update(v)),

				$scope.$on('$destroy', ()=>{
					while( destructors.length )
						destructors.pop()();
				}),
			];

			// TODO: do not use references to form controller from scope, assuming it is there. Be explicit. Temp fix below
			if ( element.tagName == 'FORM' ) {
				$scope.$parent[attrs.name] = $scope[attrs.name];
				console.warn(`Will be later deprecating references to $scope.${attrs.name} as it is unreliable & is only assumed`);
			}
		},
	}
}];
formDisabledDirective.selector = 'formDisabled';
