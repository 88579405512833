import {Helper, DependencyInjected} from '../classes'
import {ApiError} from './'


export class Heatmap extends DependencyInjected {
  static get $inject(){return [
    '$q',
    'api',
    'toast',
  ]};
  static get selector(){return 'heatmap'};

  init(){
    this._values = {};
		this._objects = {};
		this._monthMap = {};
		this.currentPicker = null;
  }
	clear(){
		this.currentPicker = null;
		Object.values(this._monthMap).forEach((v)=>v.defer && v.defer.reject());
		this._values = {};
		this._monthMap = {};
	}

	setExpr(expr){
		if ( this.expr != expr ) {
			this.clear();
			this.expr = expr;

			if ( this.currentPicker )
				this.updatePicker(this.currentPicker);
			else
				this.updateDate(this.lastMdate);
		}
	}

	updateDate(mdate){
		this.lastMdate = mdate ??= moment();
		this._process(mdate.year(), mdate.month());
	}

	updatePicker(picker){
		this.currentPicker = picker;
		this._process(picker.focusDate.getFullYear(), picker.focusDate.getMonth());
		this._renderNodes(picker.dateNodes);
	}

	async _process(year, month){
		let from = moment().year(year).month(month);
		let expr = this.expr;
		
		let i = 3; // get the next 3 months
		while(i-- > 0 && expr == this.expr) {
			if ( !this._monthMap[from.format('YYYY-M')] ) 
				await this._fetch(from.clone().startOf('month'));
			from.add(1, 'M');
		}
	}

	async _fetch(mdate){
		const params = {
			myTokenAdministers: true,
			expression: this.expr || undefined,
			from: mdate.format('YYYY-MM-DD'),
			to: mdate.endOf('month').format('YYYY-MM-DD'),
		};
		const yrmo = mdate.format('YYYY-M');

		let defer = this.$q.defer();
		let monthData = this._monthMap[yrmo] = {defer};
		
		const cfg = {timeout: defer.promise, level: ApiError.LEVEL.MANUAL};
		await this.api.get('analysis/notifications/heatMap', params, cfg)
			.then((res)=>{
				Object.keys(res.data).forEach((k)=>this._values[k] = res.data[k]);
				this._renderToPicker(mdate.year(), mdate.month());

				monthData.result = res.data;
				delete monthData.defer;

			}, (err)=>{
				if ( this.api.isApiError(err) && err.isManual() ) {
					this.toast.warn('Heatmap unavailable');
					console.error(err);
				}
				delete this._monthMap[yrmo];
			})
			.finally(()=>{
				monthData.defer = null;
			});
	}

	_renderToPicker(year, month){
		const picker = this.currentPicker;
		if ( !picker || !picker.isOpen() ) return;

		let focusDate = picker.focusDate;
		if ( focusDate.getFullYear() == year && focusDate.getMonth() == month ) {
			this._renderNodes(picker.dateNodes);
		}
	}

	_renderNodes(nodes){
		nodes.forEach((node)=>{
			let dateStr = node.getAttribute('data-date');
			let value = this._values[dateStr];

			if ( value !== undefined ) {
				node.setAttribute('data-color-count', Math.min(value, 10));
				node.setAttribute('title', (+value) +' message'+ (value > 1 ? 's' : ''));
			} else {
				node.removeAttribute('data-color-count');
				node.removeAttribute('title');
			}
		});
	}
}