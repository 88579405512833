import { forEach } from 'angular';
import {DependencyInjected, CONSTANTS, Helper} from '../'

const SCOPE_EVENTS = CONSTANTS.SCOPE_EVENTS;

class ContentOwnerController extends DependencyInjected {
	static get $inject(){return [
		'$scope',
		'$q',
		'$mdMenu',
    'apiMap',
	]}

	init(){
    const defer = this.$q.defer();
    this.$scope.$emit(SCOPE_EVENTS.ADD_PAGE_DEPENDENCY, defer.promise);

    this.apiMap.getActiveColleges().then(data=>this.colleges = data)
      .then(()=>{
        defer.resolve();
        if ( this.model_id && !this.colleges.byId[this.model_id] )
          this.model_id = null;
        else if ( ! this.model_id && this.colleges.length == 1 )
          this.model_id = this.colleges[0]._id;
      });
    
    this.results = [];
    this.recipientColleges = {};
    
    this._destructors.push(
      this.$scope.$on(SCOPE_EVENTS.RECIPIENT_INIT, (ev, groups)=>{
        this.recipientColleges = groups.reduce((obj, group, index)=>{
          obj[index] = Helper.deepCopy(group.map.college);
          return obj;
        }, {});
        debouncedUpdateColleges();
      }),
      this.$scope.$on(SCOPE_EVENTS.RECIPIENT_UPDATE, (ev, index, group)=>{
        this.recipientColleges[index] = Helper.deepCopy(group.map.college).sort(Helper.sortByName);
        if ( (!this.model_id || !this.colleges.byId[this.model_id]) && group.map.college.length > 0 ) {
          let id = group.map.college[0]._id;
          if ( this.colleges.byId[id] )
            this.model_id = group.map.college[0]._id;
        }
        debouncedUpdateColleges();
      }),
    );

    const debouncedUpdateColleges = Helper.debounce(()=>this.updateColleges(), 100);
	}

  updateColleges(){
    if ( this.search || this.forceAll || !this.model_id ) {
      this.results = this.colleges
        .filter((item)=>!this.search || item.name.toLowerCase().indexOf(this.search) > -1)

    } else {
      let list = [];
      Object.values(this.recipientColleges).forEach(colleges=>list.push(...colleges));
      // disabled the suggested list
      // if ( list.length > 1 || (list.length==1 && list[0]._id != this.model_id) ) {
      //   this.model_id && list.push(this.colleges.byId[this.model_id]);
      //   list = list.filter(Helper.uniquePropertyFilter('_id'));
      //   if ( list.length > 3 ) {
      //     this.results = list.sort(Helper.sortByName);
      //     return 
      //   }
      // }
      this.results = this.colleges;
    }
    this.$scope.$evalAsync();
  }
}


export const contentOwnerDirective = [
(
)=>{
	return {
		restrict: 'A',
		scope: {
			model_id: '=contentOwner',
		},
		bindToController: true,
		controller: ContentOwnerController,
		controllerAs: 'ctrl',
		template: `<md-input-container class="md-block md-input-has-placeholder" required>
        <label>Content Owner</label>
        <md-select name="content_owner" required
          tabindex="0"
          md-container-class="content-owner"
          md-on-close="ctrl.forceAll=false; ctrl.model_id && ctrl.updateColleges(ctrl.search='');"
          ng-model="ctrl.model_id">
          <md-option ng-repeat="item in ctrl.results" ng-value="item._id">{{ item.title || item.name }}</md-option>
        </md-select>
        <div class="md-errors-spacer"></div>
        <div ng-messages="form.content_owner.$error" role="alert">
          <div ng-message="required">Required</div>
        </div>
      </md-input-container>`,

    // `select-with-search
    // <md-select-header class="black-33">
    //   <i class="fas fa-search"></i>
    //   <input type="search" class="header-search" placeholder="Search" autocomplete="off"
    //     ng-keydown="$event.stopImmediatePropagation()"
    //     ng-click="$event.stopImmediatePropagation()"
    //     ng-focus="ctrl.forceAll=true; ctrl.updateColleges()"
    //     ng-model-options="{debounce:{'default':300, 'blur':0}, '*':'$inherit'}"
    //     ng-model="ctrl.search" ng-change="ctrl.updateColleges()">
    // </md-select-header>
    // <md-optgroup>
    //   <label ng-show="!ctrl.search && ctrl.results.length != ctrl.colleges.length">Suggested</label>
    // </md-optgroup>
    // <md-option ng-if="!ctrl.results.length" disabled="disabled"><em>No results</em></md-option-group>`
	};
}];
contentOwnerDirective.selector = 'contentOwner';
