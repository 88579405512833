import angular from 'angular'
import {DependencyInjected, Helper} from '../classes'
import {Api, ApiError} from './api.service'


export class ApiMap extends DependencyInjected {
	static get $inject(){return [
		'MAPPINGS_JSON',
		'api',
		'$state',
	]}
	static get selector(){return 'apiMap'};

	init(){
		this.getDistricts = this._createFn('districts', {myTokenAdministers:true});
		this.getAllDistricts = this._createFn('districts');
		this.getColleges = this._createFn('colleges', {myTokenAdministers:true});
		this.getAllColleges = this._createFn('colleges');
		this.getActiveColleges = this._createFn('colleges', {status:'active', myTokenAdministers:true});
		this.getCategories = this._createFn('categories', {}, null, Helper.sortByPath('name.en_US'));
		this.getTags = this._createFn('tags', {}, null, Helper.sortByPath('name.en_US'));
		this.getCohortTypes = this._createFn('cohortTypes');
		this.getCohortLevels = this._createFn('cohorts', {cohortType:'level'}, this._processCohort(), Helper.sortBy('title'));
		this.getCohortCoaches = this._createFn('cohorts', {cohortType:'beyond12_coaches'}, this._processCohort(), Helper.sortBy('title'));
		this.getCohortCorpsCoaches = this._createFn('cohorts', {cohortType:'beyond12_coaching_corps_coaches'}, this._processCohort(), Helper.sortBy('title'));
		this.getCohorts = this._createFn('cohorts', {myTokenAdministers:true}, this._processCohort(), Helper.sortBy('title'));
		this.getAllCohorts = this._createFn('cohorts', null, this._processCohort(), Helper.sortBy('title'));

		this.getFlowTypes = this._createFn('flowTypes', {}, null, Helper.sortByPath('name'));

		this.getProvidedCohorts = (opts)=>this.getCohortTypes(opts)
			.then((cohortTypes)=> this._createFn('cohorts', {myTokenAdministers:true}, this._providedCohortFilter(cohortTypes), Helper.sortBy('title'))(opts));
		this.getAllProvidedCohorts = (opts)=>this.getCohortTypes(opts)
			.then((cohortTypes)=> this._createFn('cohorts', null, this._providedCohortFilter(cohortTypes), Helper.sortBy('title'))(opts));

		this.getConsoleUsers = this._createFn('users', {roles:['college_admin', 'content_admin', 'superuser', 'bot']});
		this.getUsersCollegeAdminUp = this._createFn('users', {roles:['college_admin', 'content_admin', 'superuser']});
		this.getUsersCollegeAdmin = this._createFn('users', {roles:'college_admin'});
		this.getUsersNonStudents = this._createFn('users', {roles:['coach', 'coaching_corps_coach', 'college_admin', 'superuser', 'bot']});
		this.getUsersCoach = this._createFn('users', {roles:['coach', 'coaching_corps_coach']});
		this.getUsersBot = this._createFn('users', {roles:'bot'});
		this.getUsersSuper = this._createFn('users', {roles:'superuser'});
		this.getAllUsers = this._createFn('users');
		this.getPublicUsers = this._createFn('users/public');
		this.getActiveUsersCoach = this._createFn('users', {status:'active', roles:['coach', 'coaching_corps_coach']});
		this.getQRDesign = this._createFn('qrsResources', {type: 'design'});
	}


	// returns the data payload itself, instead of response object 
	// this might be confusing but apiMap is auto-cached, response object wouldn't make sense
	_createFn(path, params, filterFn, sortFn){
		return (options)=>this.api.get(path, angular.extend({}, params, options && options.params || {}), angular.extend({cache: true, level: ApiError.LEVEL.DEPENDENCY}, options||{}))
				.then((res)=>{
					var list = res.data;
					if ( filterFn ) list = filterFn.call(this, list);
					if ( ! list.byId ) {
						list.byId = {}; 
						if ( list[0] && list[0].name )
							list.byName = {};
						list.forEach((item)=>{
							list.byId[item._id] = item;
							if ( typeof item.name === 'string' ) 
								list.byName[item.name] = item;
							else if ( item.name && item.name.en_US )
								list.byName[item.name.en_US] = item;
						});
						if ( sortFn )
							list.sort(sortFn);
						else if ( list.length && 'name' in list[0] )
							list.sort(Helper.sortByName);
					}
					return list;
				}, (err)=>{
					if ( err instanceof ApiError && err.isCritical() ) {
						this.$state.go('app.500', {error: err});
					}
					throw err;
				});
	}

	_processCohort(){
		return (list)=>{
			list.forEach((cohort)=>{
				cohort.title = this.MAPPINGS_JSON.cohorts.name_key[cohort.name] || cohort.description;
			});
			list.sort(Helper.sortByName);
			return list;
		}
	}

	_providedCohortFilter(cohortTypes) {
		return (list)=>this._processCohort()(list.filter((item)=>item.cohort_type in cohortTypes.byName && cohortTypes.byName[item.cohort_type].source === 'provided_list'));
	}

}