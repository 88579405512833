'use strict';

const script = document.createElement('script');
script.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id='+ window.GTAG_ID);
script.setAttribute('async', '');
document.getElementsByTagName('body')[0].append(script);


window.dataLayer = window.dataLayer || [];
function gtag(){window.dataLayer.push(arguments);}
gtag('js', new Date());
gtag.trackID = window.GTAG_ID;


export function GtagFactory(
	$transitions,
	$state,
){
	return gtag;
}


GtagFactory.selector = 'gtag';
GtagFactory.$inject = [
	'$transitions',
	'$state',
];