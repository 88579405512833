import $ from 'jquery'


export const chatHeightUp = [()=>{
  return {
    enter: ($element, done)=>{
      $element
        .css('overflow', 'hidden')
        .height(0)
        .animate({height: $element[0].scrollHeight}, 300, 'swing', ()=>{
          $element.css({
            height: '',
            overflow: '',
          });
          done();
          let parent = $element.parent()[0];
          parent.scrollTop = parent.scrollHeight - parent.clientHeight;
        });
    },
    move: (element, done)=>done(),
    leave: (element, done)=>done(),
  }
}];
chatHeightUp.selector = '.chat-height-up';