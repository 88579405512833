import sdk from 'matrix-js-sdk'


import {DependencyInjected} from '../classes'

export class ChatMatrix extends DependencyInjected {
  static get $inject(){return [
    'MATRIX_URI',
    'authentication',
    'authorization',
    '$q',
  ]};
  static get selector(){return 'chatMatrix'};

  init(){
  }

  get client(){
    return this._client;
  }

  isConnected(){
    return !! this._client;
  }
  hasStarted(){
    return this.isConnected() && this._started;
  }

  connect(){
    if ( ! this.client ) {
      let client = sdk.createClient(this.MATRIX_URI);

      let payload = {
        user: `gg${this.authorization.userId}`,
        password: this.authentication.getToken().substr(7),
      };
      let defer = this.$q.defer();

      // separate sdk's promise with angular promise
      client.login("m.login.password", payload)
        .then(res=>{
          console.log('chat matrix connected');
          defer.resolve(this._client = client);
        }, err=>{
          console.log('matrix login fail', err);
          defer.reject(err);
        });

      return defer.promise;
    }
    return this.$q.when(this.client);
  }

  disconnect(){
    if ( this._client ) {
      let defer = this.$q.defer();
      this._client.stopClient();
      this._client.removeAllListeners();
      this._client.logout()
        .then(()=>this._client.clearStores())
        .then(()=>{
          console.log('chat matrix disconnected');
          this._client = null;
          defer.resolve();
        });
      return defer.promise;
    }
    return this.$q.resolve();
  }

  start(){
    return this.connect()
      .then(()=>{
        let defer = this.$q.defer();
        this.client.startClient();
        this.client.once('sync', (state, prev, res)=>{
          if ( state == 'PREPARED' ) {
            console.log('chat matrix ready');
            this._started = true;
            defer.resolve(this.client);
          } else {
            defer.reject(state);
          }
        }, err=>defer.reject(err)); 
        return defer.promise;
      })
  }
  stop(){
    if ( this.isConnected() ) {
      console.log('chat matrix stopped');
      this.client.stopClient();
      this._started = false;
    }
  }


}