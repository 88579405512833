<md-chips ng-model="ctrl.filters" ng-show="ctrl.filters.length > 0" ng-disabled="ctrl.ngDisabled"
	readonly="true"
	md-on-add="ctrl.add($chip, $index); ctrl._$search='';"
	md-on-remove="ctrl.remove($chip, $index)"
	md-removable="!ctrl.ngDisabled">
	<md-chip ng-show="ctrl.filters.length===0 && ctrl.emptyChip" class="no-animate" no-cloak>{{ ctrl.emptyChip }}</md-chip>
	<md-chip ng-show="ctrl.defaultChip" class="no-animate" no-cloak>{{ ctrl.defaultChip }}</md-chip>
	<!-- <md-autocomplete flex ng-hide="ctrl.ngDisabled || ctrl.hideInput"
		ng-model-options="{updateOn:'default', debounce:{'default':200}}"
		md-selected-item="ctrl.filters._selected"
		md-search-text="ctrl._$search"
		md-min-length="2"
		md-autoselect="true"
		md-items="item in ctrl.search(ctrl._$search)"
		md-item-text="item.name"
		md-menu-container-class="filter-search-results labeled-filter-search-results">
		<md-item-template>
			<small class="black-50">{{ ctrl.keys[item._$groupId] || item._$groupId }}</small><br>
			<span>{{ item.title || item.name }}</span>
		</md-item-template>
	</md-autocomplete> -->
	<md-chip-template>
		<div title="{{ ctrl.mapping.typeNames[$chip._$groupId] ? ctrl.mapping.typeNames[$chip._$groupId].name : '' }}">
			<span ng-class="ctrl.mapping.typeNames[$chip._$groupId].icon" chip-class="$chip._$groupId +' no-animate'"></span>
			{{ $chip.title || $chip.name }}
		</div>
	</md-chip-template>
	<button type="button" md-chip-remove class="md-chip-remove" aria-label="remove {{$chip.title || $chip.name}}">
		<span class="far fa-times-circle"></span>
	</button>
</md-chips>