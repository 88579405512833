export class DependencyInjected {

	static get $inject(){ return [] }

	constructor(){
		let args = arguments;
		this.constructor.$inject.forEach((value, index)=>{
			this[value] = args[index];
		});
		this._destructors = [];
		if ( 'init' in this )
			this.init();

		if ( this.$scope )
			this._destructors.push(this.$scope.$on('$destroy', ()=>{
				while( this._destructors.length )
					this._destructors.pop()();
			}));
		else if ( this._destructors.length > 0 )
			console.warn('$scope not injected for destructors', this);
	}

}