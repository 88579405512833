import angular from 'angular'
import {DependencyInjected, Helper} from '../classes'
import {MESSAGES} from '../../common'


export class Api extends DependencyInjected {
	static get $inject(){return [
		'$rootScope',
		'$http',
		'$httpParamSerializer',
		'$location',
		'$state',
		'$q',
		'$window',
		'authentication',
		'errorPrompt',
		'API_URI',
		'Upload',
		'toast',
		'gtag',
	]}
	static get selector(){return 'api'};
	get apiError(){return ApiError};


	init(){
		this.timeoutDefers = new Set();
		this.cache = new Map();
		this.pending = new Map();
	}


	exec(options){
		options.url = this.API_URI + options.url;
		options.headers = options.headers || {};
		options.level = options.level || 0;

		var token = this.authentication.getToken();
		if ( token )
			options.headers['Authorization'] = token;

		var defered = null;
		if ( options.timeout === undefined ) {
			defered = this.$q.defer();
			this.timeoutDefers.add(defered);
			options.timeout = defered.promise;
		}

		return this.$http(options)
			.then(res=>this._handleSuccess(res))
			.catch(res=>this._processError(this._handleFail(res)))
			.finally(()=>{
				if ( !! defered && this.timeoutDefers.has(defered) ) {
					this.timeoutDefers.delete(defered);
				}
			})
	}

	_handleSuccess(res){
		if ( /^2\d\d$/.test(res.status) ) {
			if ( res.headers ) {
				var token = res.headers('authorization');
				if ( token ) {
					this.authentication.setToken(token);
					// idleTimeout.resetTokenTimeout();
				}
			}
			return res;
		}
		throw res;
	}
	_handleFail(res){
		let config = res.config;

		if ( +res.status === 0 && config.timeout && config.timeout.$$state && config.timeout.$$state.value === 'abort' )
			return new ApiError(MESSAGES.API.CANCELLED, ApiError.LEVEL.IGNORED, null, null, res);
		if ( +res.status === 401 )
			return new ApiError(MESSAGES.API.UNAUTHORIZED, this.$state.includes('guest.login') ? config.level : ApiError.LEVEL.IGNORED, null, null, res);

		let debugInfo = res.status +' '+ config.method +' '+ config.url.replace(this.API_URI, '/');
		if ( config.params && JSON.stringify(config.params) != "{}" )
			debugInfo += '?'+ config.paramSerializer(res.config.params);

		let message, codeList = [res.status];
		if ( res.data ) {
			if ( res.data.error_code )
				codeList.push(res.data.error_code);
			if ( res.data.error_message ) {
				codeList.push(res.data.error_message);
				message = res.data.error_message;
			} else
			if ( res.data.errors ) {
				let msgs = [];
				angular.forEach(res.data.errors || {}, (obj,k)=>{
					if ( k!='json' ) {
						codeList.push([k, obj.code, obj.key||'', ].join(' '));

						if ( MESSAGES.API.CODE[obj.code] )
							msgs.push(Helper.matchReplace(MESSAGES.API.CODE[obj.code], obj));
						else
							msgs.push(obj.message);
						
						debugInfo += '\n'+ k +' '+ JSON.stringify(obj);
					}
				});
				message = msgs.join('\n\r');
			}
		}
		let code = codeList.join('|');

		if ( ['POST', 'PUT'].includes(config.method) )
			try{ debugInfo += '\n'+ this.$window.btoa(JSON.stringify(config.data)); } catch(e){};


		if ( +res.status === 404 ) {
			if ( (config.level & ApiError.LEVEL.CRITICAL) > 0 )
				return new ApiError(MESSAGES.API.NOT_FOUND, ApiError.LEVEL.IGNORED, code, debugInfo, res);
			if ( (config.level & ApiError.LEVEL.DEPENDENCY) > 0 )
				return new ApiError(MESSAGES.API.NOT_AVAILABLE, ApiError.LEVEL.IGNORED, code, debugInfo, res);
		}
		if ( res.xhrStatus === 'abort' ) //
			return new ApiError(MESSAGES.API.CANCELLED, ApiError.LEVEL.IGNORED, null, null, res);

		if ( /^4\d\d$/.test(res.status) ) {// client side
			if ( message )
				return new ApiError(message, config.level, code, debugInfo, res);
			if ( res.data && (res.data.error_message || res.data.error) )
				return new ApiError(res.data.error_message || res.data.error, config.level, code, debugInfo, res);
			return new ApiError(MESSAGES.API.REQUEST_ERROR, config.level, code, debugInfo, res);
		}

		if ( /^5\d\d$/.test(res.status) || res.status <= 0 ) //
			return new ApiError(MESSAGES.API.SERVER_ERROR, config.level, code, debugInfo, res);

		return new ApiError(MESSAGES.API.SERVER_ERROR, config.level, code, debugInfo, res);
	}
	_processError(err){
		if ( ApiError.isApiError(err) ) {
			this.gtag('event', 'exception', {
					event_label: 'api', 
					description: err.message +'\n'+ err.code +'\n'+ err.debug, 
					fatal: err.isCritical(),
				});
			if ( +err.response.status === 401 && !this.$state.includes('guest.login') ) {
				this.authentication.setToken(null);
				return this.$state.go('guest.login', {redirect: this.$window.encodeURIComponent(this.$location.url())}).finally(()=>{throw null});
			}
 
			if ( err.isCritical() ) {
				if ( this.authentication.isValid() ) {
					return this.$state.go(/^40[34]$/.test(err.response.status) ? `app.${err.response.status}` : 'app.500', {error: err}, {location: false}).finally(()=>{throw null});
				} else {
					return this.$state.go('guest.500', {error: err}, {location: false}).finally(()=>{throw null});
				}
			}
			if ( err.isWarning() )
				this.toast.warn(err.message);
			if ( err.isAuto() ) {
				this.errorPrompt.show(err);
				return this.$q.reject();
			}

			if ( err.isIgnored() ) {
				console.warn('ignored', err);
				throw null;
			}
		}
		throw err;
	}


	clearCache(url){
		var count = 0;
		if ( !! url ) {
			// clear cache related to url
			var list = [url.replace(/([^\w ]+)/, '\\$1')],
				match = url.match(/\w+/);
			if ( match ) list.push( match[0] )
			var regex = new RegExp('^('+ list.join('|') +')');
			this.cache.forEach((res, key)=>{
				if ( regex.test(key) ) {
					this.cache.delete(key);
					count++;
				}
			}, this.cache);
		} else {
			count = this.cache.size;
			this.cache.clear();
		}
		return count;
	}


	get(url, params, cfg){
		cfg = cfg || {};
		cfg.method = 'get';
		cfg.url = url;
		cfg.params = params;

		var cacheKey = undefined;
		if ( cfg.cache ) {
			cacheKey = url;
			if ( params && !angular.equals(params, {}) )
				cacheKey += '?'+ this.$httpParamSerializer(params);
			delete cfg.cache; // we don't use $http cache factory
			if ( this.cache.has(cacheKey) ) {
				return this.$q.resolve(this.cache.get(cacheKey));
			}
			if ( this.pending.has(cacheKey) ) {
				return this.pending.get(cacheKey);
			}
		}


		if ( this.$window.document.documentMode ) {
			cfg.params = cfg.params || {};
			cfg.params.noCache = String(Math.random()).replace('.', '');
		}


		var p = this.exec(cfg).then((data)=>{
			if ( cacheKey )
				this.cache.set(cacheKey, data);
			return data;
		});
		if ( cacheKey )
			this.pending.set(cacheKey, p = p.finally(()=>this.pending.delete(cacheKey)));
		return p;
	};

	post(url, data, cfg){
		(cfg = cfg || {}).method = 'post';
		return this._post(url, data || {}, cfg);
	};
	put(url, data, cfg){
		(cfg = cfg || {}).method = 'put';
		return this._post(url, data || {}, cfg);
	};
	delete(url, data, cfg){
		(cfg = cfg || {}).method = 'delete';
		return this._post(url, data, cfg);
	};


	upload(url, data, cfg){
		let options = angular.merge({
			level: ApiError.LEVEL.AUTO,
			url: url,
			data: data,
			headers: {},
		}, cfg);

		options.url = this.API_URI + options.url;
		
		var token = this.authentication.getToken();
		if ( token )
			options.headers['Authorization'] = token;

		var defered = null;
		if ( options.timeout === undefined ) {
			defered = this.$q.defer();
			this.timeoutDefers.add(defered);
			options.timeout = defered.promise;
		}
	
		return this.Upload.upload(options)
			.then(res=>this._handleSuccess(res))
			.catch(res=>this._processError(this._handleFail(res)))
			.finally(()=>{
				if ( !! defered && this.timeoutDefers.has(defered) ) {
					this.timeoutDefers.delete(defered);
				}
			});
	}

	handleError(err, title){
		if ( this.isApiError(err) ) {
			err.name = title;
			this.toast.error(err);
		} else
		if ( err ) {
			if ( err instanceof Error )
				this.errorPrompt.show(err);
			console.error(err);
		}
	}


	_post(url, data, cfg){
		cfg.url = url;
		cfg.data = data;

		return this.exec(angular.merge({level: ApiError.LEVEL.AUTO}, cfg))
			.then((res)=>{
				this.clearCache(url);
				return res;
			});
	}


	isApiError(a) {
		return ApiError.isApiError(a);
	}
}

export class ApiError {
	static get LEVEL(){ return {IGNORED:1, MANUAL:2, AUTO:4, WARNING:8, CRITICAL:16, DEPENDENCY:24} }
	static isApiError(a){ return a instanceof ApiError }


	constructor(msg, level, code, debug, res){
		// super(msg);
		this.message = msg;
		this.level = level;
		this.code = code;
		this.debug = debug;
		this.response = res;
		this.name = 'Error';
		this.stack = (new Error(msg)).stack;
	}

	isIgnored(){ return (this.level & ApiError.LEVEL.IGNORED) > 0; }
	isManual(){ return (this.level & ApiError.LEVEL.MANUAL) > 0; }
	isAuto(){ return (this.level & ApiError.LEVEL.AUTO) > 0; }
	isWarning(){ return (this.level & ApiError.LEVEL.WARNING) > 0; }
	isCritical(){ return (this.level & ApiError.LEVEL.CRITICAL) > 0; }
	isDependency(){ return (this.level & ApiError.LEVEL.DEPENDENCY) > 0; }
}
