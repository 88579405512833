
export const TEMPLATE_FILES = Object.freeze({
  LOCK_CARD: {name: 'common/lockCard.tpl', file: require('./lockCard.html')},
  BUSY_OVERLAY: {name: 'common/busy.overlay.tpl', file: require('./busyOverlay.html')},
  LISTPAGE_SEARCH: {name: 'common/listPage.search.tpl', file: require('./listPageSearch.html')},
  LISTPAGE_FILTERBTN: {name: 'common/listPage.filterBtn.tpl', file: require('./listPageFilterBtn.html')},
  LISTPAGE_FILTERS: {name: 'common/listPage.filters.tpl', file: require('./listPageFilters.html')},
  LISTPAGE_FILTERDATES: {name: 'common/listPage.filterDates.tpl', file: require('./listPageFilterDates.html')},
  LISTPAGE_FILTERCHIPS: {name: 'common/listPage.filterChips.tpl', file: require('./listPageFilterChips.html')},
  LISTPAGE_SORTS: {name: 'common/listPage.sorts.tpl', file: require('./listPageSorts.html')},
  LISTPAGE_EMPTY_RESULTS: {name: 'common/listPage.emptyResults.tpl', file: require('./listPageEmptyResults.html')},
  LISTPAGE_ERROR: {name: 'common/listPage.error.tpl', file: require('./listPageError.html')},
  LISTPAGE_ITEM_CHIPS: {name: 'common/listPage.itemChips.tpl', file: require('./listPageItemChips.html')},
  VALID_DATE_MSGS: {name: 'common/validDateMsgs.tpl', file: require('./validDateMsgs.html')},
});

let templates = {};
for( const [key, item] of Object.entries(TEMPLATE_FILES) ) {
  templates[key] = item.name;
}

export const TEMPLATES = Object.freeze(templates);
