export const pageTitle = [
	'$injector',
	'$transitions',
	'$window',
	'$timeout',
	'$q',
	'$state',
	'gtag',
(
	$injector,
	$transitions,
	$window,
	$timeout,
	$q,
	$state,
	gtag,
)=>{
	$transitions.onSuccess({}, transition=>{
		let state = transition.to(),
			title = state.title;
		if ( title ) {
			if ( title instanceof Function || Array.isArray(title) ) {
				let local = {};
				transition.getResolveTokens().forEach(token=>{
					if ( ! (token instanceof Function) )
						local[token] = transition.injector().get(token);
				});
				title = $injector.invoke(title, undefined, local);
			}
			$q.when(title).then(value=>$timeout(()=>{
				$window.document.title = value ? value +' — MyCoach' : 'MyCoach';
				$timeout(()=>gtag('config', gtag.trackID, {page_title: value, page_path: $window.location.href}));
			}));
		}
	});
}];


export default pageTitle;