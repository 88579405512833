import {SETTINGS} from '../'
import {Helper} from '../classes'


export const timezoneFieldDirective = [()=>{
	return {
		restrict: 'A',
    priority: 200,
    compile: (tElement, tAttrs)=>{
      tAttrs.$attr.mdSearchText = 'md-search-text';
      tAttrs.$attr.mdItemText = 'md-item-text';
      tAttrs.$attr.mdItems = 'md-items';

      tElement
        .attr('md-search-text', tAttrs.mdSearchText='_$tzQuery.search')
        .attr('md-item-text', tAttrs.mdItemText='item')
        .attr('md-items', tAttrs.mdItems='item in _$tzQuery(_$tzQuery.search)')
        .prepend(`<md-item-template><span class="blue" md-highlight-text="_$tzQuery.search" md-highlight-flags="i">{{ item }}</span></md-item-template>
          <md-not-found>No match for &quot;<em>{{ _$tzQuery.search }}</em>&quot;</md-not-found>`);

      let timezones = moment.tz.names();
      
      return (scope, elem, attrs)=>{
        scope._$tzQuery = (str)=>{
          if ( ! str || ! str.length ) return timezones;
          return timezones.filter(name=>name.search(new RegExp(str.replace(/[^a-z0-9]+/i, '.+'), 'gi')) > -1);
        }
      }
    },
	};
}];
timezoneFieldDirective.selector = 'timezoneField';
