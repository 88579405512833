import {DependencyInjected} from '../classes'


export class PromptExit extends DependencyInjected {
	static get $inject(){return [
		'$transitions',
		'$mdDialog',
		'$state',
		'$q',
	]}
	static get selector(){return 'promptExit'};

	enable(stateName, options){
		if ( this._dereg ) {
			console.warn('promptExit already enabled');
			return;
		}
		this._dereg = this.$transitions.onBefore({from: stateName || this.$state.current.name}, (transition)=>this._checkStop(transition));
		this.options = angular.extend({
				title: 'Unsaved changes could be lost.',
				desc: 'Are you sure you want to leave this page?',
			}, options);
		window.onbeforeunload = ()=>this.options.title +'\n\n'+ this.options.desc;
		console.log('promptExit enabled');
	}

	disable(){
		if ( this._dereg ) {
			this._dereg();
			this._dereg = undefined;
			if ( this._dialog ) {
				this.$mdDialog.hide(this._dialog);
				this._dialog = null;
			}
			window.onbeforeunload = null;
			console.log('promptExit disabled');
		}
	}

	get enabled(){
		return !! this._dereg;
	}

	_checkStop(transition){
		if ( this._dereg && (!transition || transition.to().name !== 'app.500') ) {
			return this._dialog = this.$mdDialog.show(
					this.$mdDialog.confirm()
							.title(this.options.title)
							.textContent(this.options.desc)
							.ariaLabel('confirm')
							// .clickOutsideToClose(true)
							.ok('Leave')
							.cancel('Stay')
				)
				.then(()=>this.disable())
				.finally(()=>this._dialog = null);
		}
		return this.$q.resolve();
	}

}