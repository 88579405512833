import {SETTINGS} from '..'
import {Helper} from '../classes'


export const mdSelect_directive = [
(
)=>{
	return {
		restrict: 'E',
		priority: 600,
		link: (scope, $elem)=>{
      scope.$evalAsync(()=>{
        let $select = $elem.siblings('select');
        let $label = $select.siblings('label');
        let label = $elem.attr('aria-label') || $label.text() || $select.attr('name') || '';
        
        if ( $label.length ) {
          let id = $elem.attr('id') +'_select';
          if ( ! $select.length )
            console.warn('Select element missing for mdSelect. make sure to add name attribute', $elem.get(0))
          $select.attr('id', id);
          $label.attr('for', id);
        } else {
          $select.attr('aria-label', label);
        }
      });
		},
	};
}];
