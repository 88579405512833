import angular from 'angular';
import $ from 'jquery';


export const useTemplateDirective = [
	'$compile',
  '$templateRequest',
(
	$compile,
  $templateRequest,
)=>{
	return {
		restrict: 'M',
    priority: -400,
    link: function(scope, $element, $attr, ctrl) {
      var templateUrl = scope.$eval($attr.useTemplate);
      $templateRequest(templateUrl, true)
        .then(function(response){
          if (scope.$$destroyed) return;

          var template = $(response);
          $element.after(template);
          $compile(template)(scope);

          $element.remove();
        });
    }
	};
}];
useTemplateDirective.selector = 'useTemplate';


