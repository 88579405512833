import angular from 'angular'
import $ from 'jquery'
import {Helper} from '../classes'
import { ApiError } from '../services';

const WARN_BEFORE_EXPIRE = 60 * 1000; // show token expire this amount of seconds before expiring
const IDLE_CHECK_BEFORE_EXPIRE = 60 * 1000; // seconds has been idle when warning shows


export const idleCheck = [
	'$rootScope',
	'$interval',
	'$state',
	'$location',
	'$mdDialog',
	'toast',
	'api',
	'authentication',
	'promptExit',
(
	$rootScope, 
	$interval,
	$state,
	$location,
	$mdDialog,
	toast,
	api,
	authentication,
	promptExit,
)=>{
	let expireTimer, warnTimer;
	let lastAction = 0;
	let dialog = null;

	const log = Helper.debounce(console.info, 2000);
	const reAuth = Helper.throttle(()=>{
		api.post('authentication/refreshUserToken', {}, {level: ApiError.LEVEL.MANUAL})
			.catch((err)=>{
				if ( api.isApiError(err) ) {
					err.name = 'Unable to refresh session';
					toast.warn(err.name, 12, {error: err});
				}
			});
	}, 2000, true);

	function goExpire(){
		console.log('token expired');
		$mdDialog.cancel();
		promptExit.disable();
		authentication.setToken(null);
		$state.go('guest.login', {redirect: $location.url()})
			.then(()=>toast.warn('Session expired', 0));
	}

	function promptDialog(){
		return dialog = $mdDialog.show(
			$mdDialog.alert()
				.clickOutsideToClose(true)
				.title('Are you still there?')
				.textContent('Your session is about to expire.')
				.ok('I\'m here')
		);
	}

	function runWarning(){
		if ( ! authentication.isValid() ) return;
		// check if idle for some seconds
		if ( Date.now() - lastAction >= IDLE_CHECK_BEFORE_EXPIRE ) {
			promptDialog().then(()=>{
				log('idle reauth', new Date());
				reAuth();
			}, angular.noop);
		} else {
			log('auto reauth', new Date());
			reAuth();
		}
	}
	function runExpire(){
		if ( ! authentication.isValid() && $state.includes('app.**') ) {
			goExpire();
		}
	}


	function onUpdateToken(){
		if ( ! authentication.isValid() )
			return;

		const payload = authentication.getPayload(),
			expireTime = payload.exp * 1000;
		log('expires at', new Date(expireTime));

		warnTimer && $interval.cancel(warnTimer);
		warnTimer = $interval(runWarning, expireTime - Date.now() - WARN_BEFORE_EXPIRE, 1);

		expireTimer && $interval.cancel(expireTimer);
		expireTimer = $interval(runExpire, expireTime - Date.now() +100, 1);
	}
	$rootScope.$on('tokenUpdate', Helper.throttle(onUpdateToken, 5000, true));

	const check = Helper.throttle(()=>{
		lastAction = Date.now();
		if ( ! authentication.isValid() && $state.includes('app.**') ) {
			$rootScope.$evalAsync(goExpire());
		}
	}, 1000);

	const events = 'keydown keyup click mousemove DOMMouseScroll mousewheel mousedown touchstart touchmove scroll focus';
	$('body').on(events, check);
	window.addEventListener('focus', check);
}];

export default idleCheck;
