import {SETTINGS} from '../'
import {Helper} from '../classes'
import {dateValidators} from './validDate.directive';


export const validDateRangeDirective = [
(
)=>{
	return {
		restrict: 'A',
		require: '?ngModel',
		scope: {
			model: '=validDateRange',
			includeTime: '&',
			timeOnly: '&',
			viewFormat: '&',
			modelFormat: '&',
		},
		link: (scope, elem, attrs, ngModel)=>{
			if ( ! ngModel ) return; // do nothing if no ng-model

			const isTimeOnly = scope.timeOnly();

			const viewFormat = angular.extend({date: SETTINGS.dateFormat, datetime: SETTINGS.dateTimeFormat}, scope.viewFormat());
			const modelFormat = angular.extend({date: SETTINGS.dateFormat, datetime: SETTINGS.dateTimeFormat}, scope.modelFormat());
			const separator = scope.$eval(attrs.separator, scope.$parent);
			let lastViewValue;
			// keep track of moment objects parsed from model
			let $moments = [];
			// first invalid moment
			let $invalid = null;

			// validators
			// ngModel.$validators.date = (model)=>!model || !$invalid;
			// ngModel.$validators.datetime = (model)=>!$moments.find(mo=>mo._hasTime) || !model || !$invalid;
			const setDateValidity = ()=>{
				const valid = !$invalid;
				ngModel.$setValidity('date', valid);
				ngModel.$setValidity('datetime', !$moments.find(mo=>mo._hasTime) ? valid : null);
			}

			for(const [key, fn] of Object.entries(dateValidators)) {
				ngModel.$validators[key] = (model)=>!model || !$invalid || fn($invalid);
			}
			
			ngModel.$validators.order = (model)=>{
				if ( !model || $invalid ) return true;

				let found = $moments.find((mo, i)=>{
					if ( i>0 && mo?.isValid() && $moments[i-1] ) {
						// if last of range is 12am, consider it as next day
						if ( mo._hasTimeOnly && i == $moments.length-1 && mo.isSame(mo.clone().startOf('day')) )
							return false;
						return mo.isBefore($moments[i-1]);
					}
					return false;
				});
				return found ? false : true;
			}

			const _parse = (value, index)=> Helper.parseDateTime(value) || ((isTimeOnly || index > 0) && Helper.parseTime(value)) || undefined;

			// when view changes, update model
			ngModel.$parsers.push((viewValue)=>{
				lastViewValue = viewValue.trim();
				$moments = lastViewValue
					.split(separator)
					.map((value, index)=>value ? _parse(value, index) : undefined)
					.filter(v=>!!v);
				$invalid = null;

				let prev;
				$moments.forEach((mo, index)=>{
					if ( index > 0 && (prev = $moments[index-1]) && prev.isValid() && mo._hasTimeOnly ) { 
						// copy year-day of previous model
						mo.year(prev.year()).dayOfYear(prev.dayOfYear());
					}
					$invalid ??= !mo.isValid() ? mo : null;
				});
				let model = $moments.map(mo=>mo.format(mo._hasTime ? modelFormat.datetime : modelFormat.date));
				model.$moments = $moments;
				scope.$evalAsync(()=>ngModel.$processModelValue());
				setDateValidity();
				return model;
			});

			// when model changes, update view
			ngModel.$formatters.push((model)=>{
				if ( ! model ) return ngModel.$viewValue || '';
				if ( ! Array.isArray(model) ) model = [model];
				$moments = model.$moments = model.map((val, i)=>_parse(val, i));
				$invalid = $moments.find(mo=>!mo.isValid());
				if ( ! $invalid ) {
					lastViewValue = $moments.map((mo, index)=>{
						let format = viewFormat.date;
						if ( mo._hasTime ) {
							format = viewFormat.datetime;
							if ( (mo._hasTimeOnly || (index > 0 && mo.format('YYYYDDDD') == $moments[index-1].format('YYYYDDDD'))) )
								format = viewFormat.time;
						}
						return mo.format(format);
					}).join(separator);
				}
				setDateValidity();
				return lastViewValue || undefined;
			});

		},
	};
}];
validDateRangeDirective.selector = 'validDateRange';