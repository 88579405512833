import $ from 'jquery'
import angular from 'angular'
import {DependencyInjected, Helper} from '../classes'


class ToastController extends DependencyInjected {
	static get $inject(){return [
		'$mdToast',
		'$mdDialog',
		'message',
		'palette',
		'error',
	]}

	close(){
		this.$mdToast.hide();
		this.hasDialog = false;
	}

	openInfo($e){
		if ( this.hasDialog || ! this.error ) return;
		this.hasDialog = true;

		return this.$mdDialog.show({
				controller: ToastInfoController,
				controllerAs: 'ctrl',
				template: require('./toast.info.html'),
				targetEvent: $e,
				locals: {
					error: this.error,
				},
				clickOutsideToClose: false,
				escapeToClose: true,
				focusOnOpen: true,
				multiple: true,
			})
			.then(()=>{
				this.hasDialog = false;
			});
	}
}


class ToastInfoController extends DependencyInjected {
	static get $inject(){return [
		'$mdDialog',
		'error',
	]}

	close($e){
		this.$mdDialog.hide();
	}
}


export class Toast extends DependencyInjected {
	static get $inject(){return [
		'$mdToast',
		'$transitions',
		'$q',
		'authentication',
		'errorPrompt',
	]}
	static get selector(){return 'toast'};

	static get defaults(){return {
			hideDelay: 5000,
			position: 'bottom left',
			controller: ToastController,
			controllerAs: 'ctrl',
			template: `
				<md-toast>
					<span class="md-toast-text" md-colors="::{color: '{{ ctrl.palette }}'}" flex role="status">{{ ::ctrl.message }}</span>
					<md-button class="md-highlight" ng-click="ctrl.openInfo($event)" ng-show="ctrl.error" aria-label="more info">
						<i class="fas fa-info-circle"></i>
					</md-button>
				  <md-button ng-click="ctrl.close()" aria-label="close">&times;</md-button>
				</md-toast>`,
		}}

	init(){
		this.options = undefined;

		this.$transitions.onFinish({to:'**'}, transition=>{
			// auto-hide manually-closed toast, ie errors
			if ( this.options && ! this.options.hideDelay )
				this.$mdToast.hide();
		});

		// CON2-733 better error display
		this.error = err=>this.errorPrompt.show(err);

		this.warn = Helper.throttle((message, lifespan, opts)=>this._show(angular.extend(opts||{}, {
			hideDelay: lifespan!==undefined ? lifespan : 8000,
			locals: {
				message: message,
				palette: 'orange',
				error: null,
			},
		})), 4000, true);

		this.success = Helper.throttle((message, lifespan, opts)=>this._show(angular.extend(opts||{}, {
			hideDelay: lifespan!==undefined ? lifespan : 6000,
			locals: {
				message: message,
				palette: 'yellow',
				error: null,
			},
		})), 2000, true);
	}

	_show(options){
		return this.clear().then(()=>{
				this.options = angular.extend({parent:$('#main-content')[0] || $('#content main')[0]}, Toast.defaults, options);
				return (this._promise = this.$mdToast.show(this.options))
					.finally(()=>this.options = this._promise = null);
			});
	}

	clear(){
		return this.$q.when(this._promise && this.$mdToast.hide() || true);
	}
}