import { DependencyInjected, Helper } from "../../classes";
export * from '../../prototype';
import './style.scss';

const TEMPLATE = require('./template.html');



export const datePicker_directive = [
  '$compile',
(
  $compile,
)=>{
  return {
    restrict: 'A',
    require: 'ngModel',
    controller: DatePickerController,
    controllerAs: 'picker',
    // scope: {
    //   minDate: '=minDate',
    //   maxDate: '=maxDate',
    // },
    link: ($scope, $elem, attrs, ctrl)=>{
      const ngModel = ctrl;
      const $tpl = $(TEMPLATE);
      $elem.after($tpl);
      $elem.parent('md-input-container').addClass('has-datepicker-triangle');

      let $btn = $tpl.filter('[type="button"]');
      let $dialog = $tpl.filter('[role="dialog"]');

      const picker = $scope.picker;
      picker.prepare($dialog.get(0));
      picker.onOpen = ()=>$scope.$evalAsync(attrs.onPickerOpen, {$picker: picker});
      picker.onClose = ()=>$scope.$evalAsync(attrs.onPickerClose, {$picker: picker});
      picker.onUpdate = ()=>$scope.$evalAsync(attrs.onPickerUpdate, {$picker: picker});
      
      $compile($tpl)($scope);

      // $dialog.remove(); // detach initially

      $btn.on('click', ($evt)=>{
        let value = ngModel.$modelValue?.isValid() ? ngModel.$modelValue.format('YYYY-MM-DD') : null;
        picker.open($evt.originalEvent, value);
      });
      $dialog.on('calendar', ($evt)=>{
        if ( $evt.detail instanceof Date ) {
          let date = new Date($evt.detail);

          let m = (ngModel.$modelValue?.clone() || moment().startOf('day'))
            .year(date.getFullYear())
            .month(date.getMonth())
            .date(date.getDate());
          $scope.$eval(`${attrs.ngModel} = m;`, {m});
          ngModel.$modelValue = m;
          ngModel.$processModelValue();
          $scope.$eval(attrs.ngChange);
        }
      });

      $scope.$evalAsync(attrs.pickerInit, {$picker: picker});

      $scope.$on('$destroy', ()=>{
        $btn.off('click');
        $dialog.off('calendar');
        picker.destroy();
      });
    },
  };
}];

const CALENDAR_DIALOG_WIDTH = 320;
const CALENDAR_DIALOG_HEIGHT = 335;


/*
 *   This is based of w3c date dicker example
 *   https://www.w3.org/WAI/ARIA/apg/patterns/dialog-modal/examples/datepicker-dialog/
 *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 */
class DatePickerController extends DependencyInjected {
  static get $inject(){return [
    '$mdUtil',
    '$window',
    '$$rAF',
    'moment',
  ]}

  prepare(dialogNode){
    this.documentElement = angular.element(document.documentElement);

    this.buttonLabelChoose = 'Choose Date';
    this.buttonLabelChange = 'Change Date';
    this.monthLabels = this.moment.months();
  
    this.messageCursorKeys = 'Cursor keys can navigate dates';
    this.lastMessage = '';
  
    this.input = dialogNode.parentElement.querySelector('input');
    this.dialogNode = dialogNode;
    this.messageNode = dialogNode.querySelector('.dialog-message');
  
    this.monthYearNode = dialogNode.querySelector('.month-year');
  
    this.prevYearBtn = dialogNode.querySelector('button.prev-year');
    this.nextYearBtn = dialogNode.querySelector('button.next-year');
    this.prevMonthBtn = dialogNode.querySelector('button.prev-month');
    this.nextMonthBtn = dialogNode.querySelector('button.next-month');
  
    this.focusDate = new Date();
    this.selectedDate = new Date(0, 0, 1);
  
    this.dialogNode.addEventListener('keydown', this.onKeyDown = this.onKeyDown.bind(this));
    this.prevYearBtn.addEventListener('click', this.moveToPrevYear = this.moveToPrevYear.bind(this));
    this.nextYearBtn.addEventListener('click', this.moveToNextYear = this.moveToNextYear.bind(this));
    this.prevMonthBtn.addEventListener('click', this.moveToPrevMonth = this.moveToPrevMonth.bind(this));
    this.nextMonthBtn.addEventListener('click', this.moveToNextMonth = this.moveToNextMonth.bind(this));

    // create table header

    let theadNode = dialogNode.querySelector('table.dates thead');
    theadNode.innerHTML = '';
    let weekdayLabels = this.moment.weekdays();
    let weekdayMinLabels = this.moment.weekdaysMin();
    let theadRow = theadNode.insertRow(0);
    weekdayLabels.forEach((label, i)=>{
      let cell = document.createElement('th');
      cell.scope = 'col';
      cell.innerHTML = `<span aria-hidden="true">${weekdayMinLabels[i]}</span><span class="visual-hide">${label}</span>`;
      theadRow.appendChild(cell);
    });

    // Create table of Dates
  
    let tbodyNode = dialogNode.querySelector('table.dates tbody');
    tbodyNode.innerHTML = '';
    
    this.onNodeClick = this.onNodeClick.bind(this);
    this.onNodeFocus = this.onNodeFocus.bind(this);

    this.lastRowNode = null;
    this.dateNodes = [];
    for (let i = 0; i < 6; i++) {
      let row = tbodyNode.insertRow(i);
      this.lastRowNode = row;
      for (let j = 0; j < 7; j++) {
        let cell = document.createElement('td');
        let btn = document.createElement('button');
  
        btn.tabIndex = -1;
        btn.type = 'button';
        btn.addEventListener('click', this.onNodeClick);
        btn.addEventListener('focus', this.onNodeFocus);
  
        // cell.textContent = '-1';
        cell.appendChild(btn);
        row.appendChild(cell);
        this.dateNodes.push(btn);
      }
    }

    this.onOutsideClick = this.onOutsideClick.bind(this);
    this.onWindowResize = this.$mdUtil.debounce(this.close = this.close.bind(this), 100);
  
    this.updateGrid();
    this.close(false);
  }

  destroy(){
    if ( this.isOpen() ) {
     this.close(); 
    }
    this.onOpen = this.onClose = undefined;
    this.dialogNode.removeEventListener('keydown', this.onKeyDown);
    this.prevYearBtn.removeEventListener('click', this.moveToPrevYear);
    this.nextYearBtn.removeEventListener('click', this.moveToNextYear);
    this.prevMonthBtn.removeEventListener('click', this.moveToPrevMonth);
    this.nextMonthBtn.removeEventListener('click', this.moveToNextMonth);
  }


  updateGrid() {
    let focusDate = this.focusDate;
  
    this.monthYearNode.textContent = this.monthLabels[focusDate.getMonth()] + ' ' + focusDate.getFullYear();
  
    let firstDayOfMonth = new Date(focusDate.getFullYear(), focusDate.getMonth(), 1);
    let dayOfWeek = firstDayOfMonth.getDay();
  
    firstDayOfMonth.setDate(firstDayOfMonth.getDate() - dayOfWeek);
  
    let curDate = new Date(firstDayOfMonth);
  
    // for (var i = 0; i < this.days.length; i++) {
    this.dateNodes.forEach((node, i)=>{
      let dim = curDate.getMonth() != focusDate.getMonth();
      let selected = Helper.isSameJSDay(curDate, this.selectedDate);
      this.updateDate(node, dim, curDate, selected);
      curDate.setDate(curDate.getDate() + 1);
  
      // Hide last row if all dates are disabled (e.g. in next month)
      if (i === 35) {
        if (dim) {
          this.lastRowNode.style.visibility = 'hidden';
        } else {
          this.lastRowNode.style.visibility = 'visible';
        }
      }
    });
    this.onUpdate && this.onUpdate();
  }
  
  updateDate(node, disable, date, selected) {
    var d = date.getDate().toString();
    if (date.getDate() <= 9) {
      d = '0' + d;
    }
  
    var m = date.getMonth() + 1;
    if (date.getMonth() < 9) {
      m = '0' + m;
    }
  
    node.tabIndex = -1;
    node.removeAttribute('aria-selected');
    node.setAttribute('data-date', date.getFullYear() + '-' + m + '-' + d);
    node.setAttribute('aria-label', this.monthLabels[date.getMonth()] +' '+ date.getDate());
  
    if (disable) {
      node.classList.add('dimmed');
    } else {
      node.classList.remove('dimmed');
    }
    node.classList.toggle('today', Helper.isSameJSDay(date, this.today));

    node.textContent = date.getDate();
    if (selected) {
      node.setAttribute('aria-selected', 'true');
      node.tabIndex = 0;
    }
  }
  
  updateFocusDate() {
    this.dateNodes.forEach((node)=>{
      let date = this.getNodeDate(node);
      let focused = Helper.isSameJSDay(date, this.focusDate);
  
      node.tabIndex = -1;
      if ( focused ) {
        node.tabIndex = 0;
        node.focus();
      }
    });
  }
  

  reposition() {
    let dialog = this.dialogNode;
    let body = document.body;

    dialog.style.transform = '';
    let inputRect = this.input.getBoundingClientRect();
    let bodyRect = body.getBoundingClientRect();

    let top = inputRect.top - bodyRect.top + inputRect.height;
    let left = inputRect.left - bodyRect.left;

    let vpTop = (bodyRect.top < 0 && body.scrollTop == 0) ? -bodyRect.top : body.scrollTop;
    let vpLeft = (bodyRect.left < 0 && body.scrollLeft == 0) ? -bodyRect.left : body.scrollLeft;
    let vpBottom = vpTop + this.$window.innerHeight;
    let vpRight = vpLeft + this.$window.innerWidth;

    if (CALENDAR_DIALOG_WIDTH < inputRect.width) {
      left += inputRect.width - CALENDAR_DIALOG_WIDTH;

    } else
    // If the right edge of the dialog would be off the screen and shifting it left by the
    // difference would not go past the left edge of the screen. 
    if (left + CALENDAR_DIALOG_WIDTH + 5 > vpRight) {
      if (vpRight - CALENDAR_DIALOG_WIDTH -5 > 0) {
        left = vpRight - CALENDAR_DIALOG_WIDTH -5;
      } else {
        /// If the calendar dialog is too big to fit on the screen at all, move it 
        /// to the left of the screen and scale the entire element down to fit.
        left = vpLeft;
        var scale = this.$window.innerWidth / CALENDAR_DIALOG_WIDTH;
        dialog.style.transform = 'scale(' + scale + ')';
      }

      dialog.classList.add('md-datepicker-pos-adjusted');
    }

    // If the bottom edge of the dialog would be off the screen and shifting it up by the
    // difference would not go past the top edge of the screen.
    if (top + CALENDAR_DIALOG_HEIGHT + 5 > vpBottom && vpBottom - CALENDAR_DIALOG_HEIGHT -5 > vpTop) {
      top = vpBottom - CALENDAR_DIALOG_HEIGHT;
      dialog.classList.add('md-datepicker-pos-adjusted');
    }


    dialog.style.left = left + 'px';
    dialog.style.top = top + 'px';
  }

  isOpen() {
    return this.dialogNode.parentElement != null;
  }
  open(event, value) {
    this.triggerNode = event.currentTarget;
    this.reposition();

    const dialog = this.dialogNode;

    document.body.appendChild(dialog);

    // Add CSS class after one frame to trigger open animation.
    this.$$rAF(()=>dialog.classList.add('dialog-open'));


    this.$mdUtil.nextTick(()=>{
      this.documentElement.on('click touchstart', this.onOutsideClick);
    }, false);
    this.$mdUtil.disableScrollAround(dialog);
    this.$window.addEventListener('resize', this.onWindowResize);
  
    
    // set focus date
    if ( value ) {
      this.focusDate = this.getDataDate(value);
    } else {
      this.focusDate = new Date();
    }
    this.selectedDate = new Date(this.focusDate);
    this.today = new Date();

    this.updateGrid();
    this.updateFocusDate();

    this.onOpen && this.onOpen();
  }
  close() {
    // this.dialogNode.style.display = 'none';
    this.dialogNode.remove();

    this.documentElement.off('click touchstart', this.onOutsideClick);
    this.$mdUtil.enableScrolling();
    this.$window.removeEventListener('resize', this.onWindowResize);

    this.triggerNode?.focus();
    this.onClose && this.onClose();
  }
  
  moveToNextYear() {
    this.focusDate.addMonth(12);
    this.updateGrid();
  }
  moveToPrevYear() {
    this.focusDate.addMonth(-12);
    this.updateGrid();
  }
  moveToNextMonth() {
    this.focusDate.addMonth(1);
    this.updateGrid();
  }
  moveToPrevMonth() {
    this.focusDate.addMonth(-1);
    this.updateGrid();
  }
  
  focusTo(date, followMonth=true) {
    var d = this.focusDate;
    this.focusDate = date;
  
    if ( followMonth && !Helper.isSameJSMonth(d, this.focusDate) ) {
      this.updateGrid();
    }
    this.updateFocusDate();
  }
  focusToTomorrow() {
    var d = new Date(this.focusDate);
    d.setDate(d.getDate() + 1);
    this.focusTo(d);
  }
  focusToNextWeek() {
    var d = new Date(this.focusDate);
    d.setDate(d.getDate() + 7);
    this.focusTo(d);
  }
  focusToYesterday() {
    var d = new Date(this.focusDate);
    d.setDate(d.getDate() - 1);
    this.focusTo(d);
  }
  focusToLastWeek() {
    var d = new Date(this.focusDate);
    d.setDate(d.getDate() - 7);
    this.focusTo(d);
  }
  focusToFirstDayOfWeek() {
    var d = new Date(this.focusDate);
    d.setDate(d.getDate() - d.getDay());
    this.focusTo(d);
  }
  focusToLastDayOfWeek() {
    var d = new Date(this.focusDate);
    d.setDate(d.getDate() + (6 - d.getDay()));
    this.focusTo(d);
  }
  
  // Day methods
  
  getNodeDate(node) {
    return this.getDataDate(node.getAttribute('data-date'));
  }
  getDataDate(dateStr) {
    let parts = dateStr.split('-');
    return new Date(parts[0], parseInt(parts[1]) - 1, parts[2]);
  }
  
  selectNode(node) {
    let last = this.selectedDate;
    this.selectedDate = this.getNodeDate(node);
    // updated aria-selected
    this.dateNodes.forEach((day) =>
      day === node
        ? day.setAttribute('aria-selected', 'true')
        : day.removeAttribute('aria-selected')
    );

    let event = new CustomEvent('calendar', {detail:new Date(this.selectedDate)});
    this.dialogNode.dispatchEvent(event);
  }
  
  // Event handlers
  
  onKeyDown(event) {
    var flag = false;
  
    switch (event.key) {
      case 'Esc':
      case 'Escape':
        this.close();
        break;
  
      case 'Tab':
        // trap tabs within dialog
        let tabList = Array.from(this.dialogNode.querySelectorAll('[tabindex="0"]').values());
        let pos = tabList.indexOf(event.target);
        if ( event.shiftKey ) {
          if ( pos == 0 ) {
            tabList[tabList.length-1].focus();
            flag = true;
          }
        } else if ( pos == tabList.length-1 ) {
          tabList[0].focus();
          flag = true;
        }
        break;
  
      case 'Right':
      case 'ArrowRight':
        this.focusToTomorrow();
        flag = true;
        break;
  
      case 'Left':
      case 'ArrowLeft':
        this.focusToYesterday();
        flag = true;
        break;
  
      case 'Down':
      case 'ArrowDown':
        this.focusToNextWeek();
        flag = true;
        break;
  
      case 'Up':
      case 'ArrowUp':
        this.focusToLastWeek();
        flag = true;
        break;
  
      case 'PageUp':
        if (event.shiftKey) {
          this.moveToPrevYear();
        } else {
          this.moveToPrevMonth();
        }
        this.updateFocusDate();
        flag = true;
        break;
  
      case 'PageDown':
        if (event.shiftKey) {
          this.moveToNextYear();
        } else {
          this.moveToNextMonth();
        }
        this.updateFocusDate();
        flag = true;
        break;
  
      case 'Home':
        this.focusToFirstDayOfWeek();
        flag = true;
        break;
  
      case 'End':
        this.focusToLastDayOfWeek();
        flag = true;
        break;
    }
  
    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  onNodeFocus(event) {
    let followMonth = true;
    if ( event.currentTarget.classList.contains('dimmed') ) {
      // if ( event.currentTarget.matches(':hover') )
      //   return;
      followMonth = false;
    }

    if ( event.currentTarget.tagName == 'BUTTON' && event.which !== 3 ) {
      let day = this.getNodeDate(event.target);
      if ( ! Helper.isSameJSDay(day, this.focusDate) ) {
        this.focusTo(day, followMonth);
      }
    }
    event.preventDefault();
    event.stopPropagation();
  }
  onNodeClick(event) {
    if ( event.currentTarget.disable ) {
      return;
    }
    this.selectNode(event.currentTarget);
    this.close();
  
    event.stopPropagation();
    event.preventDefault();
  }
  
  onOutsideClick(event) {
    if ( !this.dialogNode.contains(event.target) && this.isOpen() ) {
      this.close(false);
      event.stopPropagation();
      event.preventDefault();
    }
  }
  
}
