import $ from 'jquery'
import angular from 'angular'
import {Helper} from '..'

let targets = [
	'input',
	'button',
	'textarea',
	'md-checkbox',
	'md-slider-container',
	'md-slider',
	'md-switch',
	'md-select',
	'md-radio-group',
	'raw-recipient',
	'.md-chips',
	'md-autocomplete',
	'md-chips',
];

export const onFormFocusDirective = [
	'$timeout',
(
	$timeout,
)=>{
	return {
		restrict: 'A',
		link: ($scope, $element, $attrs)=>{
      const fn = Helper.debounce((target)=>{
				let e1 = $(target).filter(':not(.no-form-focus, [no-form-focus])');
				let e2 = e1.parents('.no-form-focus, [no-form-focus]');
				if ( e1.length && !e2.length )
					$scope.$evalAsync($attrs.onFormFocus);
			}, 400);
			$element = $($element).on('focus', targets.join(','), function(){fn(this)});

			$scope.$on('$destroy', ()=>{
        $element.off();
      });
		},
	}
}];
onFormFocusDirective.selector = 'onFormFocus';
