import {DependencyInjected} from '../classes'
import PERMISSIONS from '../permissions';

export class Authentication extends DependencyInjected {
	static get $inject(){return [
		'$window',
		'$rootScope',
		'jwtHelper',
	]};
	static get selector(){return 'authentication'};


	init(){
		this.storage = this.$window.localStorage;
		let token = this._getToken();
		if ( token && ! this.jwtHelper.isTokenExpired(token) ) 
			this.setToken(token);
	}
	_getToken(){
		return this.storage.getItem('token');
	}
	_isValid(token){
		return token && !this.jwtHelper.isTokenExpired(token);
	}

	setToken(token){
		if ( token ) {
			this.storage.setItem('token', token);
		} else {
			this.storage.clear();
		}
		this.$rootScope.$emit('tokenUpdate', this);
	}

	getToken(){
		let token = this._getToken();
		return this._isValid(token) 
			? token 
			: null;
	}

	hasToken(){
		return this._isValid(this._getToken());
	}

	getPayload(){
		let token = this._getToken();
		return this._isValid(token)
			? this.jwtHelper.decodeToken(token)
			: null;
	}

	isValid(){
		return this.hasToken()
			&& !this.getPayload()?.permissions?.includes(PERMISSIONS.VERIFY_MFA) 
			|| false;
	}


}