import {saveAs} from 'file-saver';
import {DependencyInjected} from '../classes'

const B64LIMIT = 1048576;

export class Downloader extends DependencyInjected {
	static get $inject(){return [
		'$http',
		'$q',
		'$state',
		'$transitions',
		'api',
		'API_URI',
		'authentication',
		'promptExit',
	]}
	static get selector(){return 'downloader'};

	
	async get(url, filename){
		const defer = this.$q.defer();
		const options = {
			method: 'GET',
			url: url.replace(this.API_URI, ''), // api.exec already prepends the api uri
			// headers: {'Authorization': this.authentication.getToken()},
			timeout: defer.promise,
			responseType: 'blob',
		};
		let canceled;

		this.promptExit.enable(this.$state.current.name, {title: 'Export is still in progress and would be canceled.'});
		const hookCancel = this.$transitions.onStart(
			{from: this.$state.current.name}, 
			function(){
				hookCancel();
				defer.resolve(canceled = 'abort');
			}
		);
		
		const res = await this.api.exec(options);
		
		if ( canceled ) return;

		hookCancel();
		this.promptExit.disable();

		saveAs(res.data, filename);

		// let a = document.createElement('a');
		// let windowUrl = window.URL || window.webkitURL;
		// if (res.data.length > B64LIMIT && typeof windowUrl.createObjectURL === 'function') {
		// 	let blob = new Blob([res.data]);
		// 	let url = windowUrl.createObjectURL(blob);
		// 	a.href = url;
		// 	a.download = filename;
		// 	a.click();
		// 	windowUrl.revokeObjectURL(url);
		// } else {
		// 	//use base64 encoding when less than set limit or file API is not available
		// 	a.href = 'data:text/plain;base64,'+ moxie.core.utils.Encode.btoa(res.data);
		// 	a.download = filename;
		// 	a.click();
		// }
	}


}