let permissions = {};
[
  // Login
  'CONSOLE_LOGIN',
  'AUTOMATIC_STUDENT_LOGIN',
  'CHAT_LOGIN',
  'APP_LOGIN',
  'APP_FACEBOOK_LOGIN',
  'APP_APPLE_LOGIN',
  'VERIFY_MFA',
  // Analysis
  'ANALYZE_STUDENTS_PER_COLLEGE',
  'ANALYZE_STUDENTS_PER_ACTIVE_STATUS',
  'ANALYZE_STUDENTS_PER_DEVICE_OS',
  'ANALYZE_STUDENTS_PER_COLLEGE_LEVEL',
  'ANALYZE_STUDENTS_PER_MOTIVATION',
  'ANALYZE_NEW_STUDENTS_PER_DATE',
  'ANALYZE_NOTIFICATIONS_PER_COLLEGE',
  'ANALYZE_NOTIFICATIONS_PER_CATEGORY',
  'ANALYZE_NOTIFICATIONS_PER_TYPE',
  'ANALYZE_NOTIFICATIONS_HEAT_MAP',
  'ANALYZE_ANSWERS_PER_COLLEGE_QUESTION',
  'ANALYZE_COLLEGE_STUDENT_REPORT',
  'ANALYZE_COLLEGE_COHORT_REPORT',
  'ANALYZE_COLLEGE_MILESTONE_REPORT',
  'ANALYZE_APPROVAL_STATUS',
  // Users
  'CREATE_USERS',
  'READ_USERS',
  'UPDATE_USERS',
  'UPDATE_USER_PASSWORD_WITHOUT_PASSWORD',
  'DELETE_USERS',
  'READ_PUBLIC_USERS',
  'CREATE_MY_STUDENT_USER',
  'READ_USER_ROLES',
  'UPDATE_USER_ROLES',
  'READ_USER_SOCIALS',
  'UPDATE_USER_SOCIALS',
  'READ_USER_CHAT_NAMES',
  // Rewards
  'CREATE_REWARDS',
  'READ_REWARDS',
  // Plans
  'READ_PLANS',
  // Content Directory
  'CREATE_CONTENT_DIRECTORY',
  'READ_CONTENT_DIRECTORY',
  'UPDATE_CONTENT_DIRECTORY',
  'DELETE_CONTENT_DIRECTORY',
  // Roles
  'CREATE_ROLES',
  'READ_ROLES',
  'UPDATE_ROLES',
  'DELETE_ROLES',
  // Flow Configurations
  'CREATE_FLOW_CONFIGURATIONS',
  'READ_FLOW_CONFIGURATIONS',
  'UPDATE_FLOW_CONFIGURATIONS',
  'DELETE_FLOW_CONFIGURATIONS',
  'PUBLISH_FLOW_CONFIGURATIONS',
  // Flow types
  'CREATE_FLOW_TYPES',
  'READ_FLOW_TYPES',
  'UPDATE_FLOW_TYPES',
  'DELETE_FLOW_TYPES',
  // Activity Logs
  'READ_ACTIVITY_LOGS',
  'READ_NON_USER_ACTIVITY_LOGS',
  'CREATE_ACTIVITY_LOGS',
  // Categories
  'CREATE_CATEGORIES',
  'READ_CATEGORIES',
  'UPDATE_CATEGORIES',
  'DELETE_CATEGORIES',
  // Colleges
  'READ_COLLEGES',
  'CREATE_COLLEGES',
  'UPDATE_COLLEGES',
  'DELETE_COLLEGES',
  // Tasks
  'CREATE_TASKS',
  'READ_RELEASED_TASKS',
  'READ_TASKS',
  'UPDATE_TASKS',
  'UPDATE_TASKS_OVERRIDE',
  'DELETE_TASKS',
  'DELETE_TASKS_OVERRIDE',
  // Tasks Batches
  'CREATE_TASKS_BATCHES',
  'READ_TASKS_BATCHES',
  'UPDATE_TASKS_BATCHES',
  'DELETE_TASKS_BATCHES',
  // Districts
  'READ_DISTRICTS',
  'CREATE_DISTRICTS',
  'UPDATE_DISTRICTS',
  'DELETE_DISTRICTS',
  // Ledgers
  'CREATE_LEDGERS',
  'READ_LEDGERS',
  'UPDATE_LEDGERS',
  'DELETE_LEDGERS',
  // Ledger Actions
  'EARN_STEP',
  'COMPLETE_TASK',
  'WATCH_VIDEO',
  'REQUEST_REWARD',
  // Tags
  'READ_TAGS',
  'CREATE_TAGS',
  'UPDATE_TAGS',
  'DELETE_TAGS',
  // Cohort Types
  'READ_COHORT_TYPES',
  'CREATE_COHORT_TYPES',
  'UPDATE_COHORT_TYPES',
  'DELETE_COHORT_TYPES',
  // Cohorts
  'READ_COHORTS',
  'CREATE_COHORTS',
  'UPDATE_COHORTS',
  'DELETE_COHORTS',
  // Mappings
  'READ_MAPPINGS',
  'UPDATE_MAPPINGS',
  //Events
  'READ_EVENTS',
  'CREATE_EVENTS',
  'UPDATE_EVENTS',
  'DELETE_EVENTS',
  //Tips
  'READ_TIPS',
  'CREATE_TIPS',
  'UPDATE_TIPS',
  'DELETE_TIPS',
  //Tip Themes
  'READ_TIP_THEMES',
  'CREATE_TIP_THEMES',
  'UPDATE_TIP_THEMES',
  'DELETE_TIP_THEMES',
  'UPDATE_TIPS_OVERRIDE',
  'DELETE_TIPS_OVERRIDE',
  //Workspaces
  'READ_WORKSPACES',
  'CREATE_WORKSPACES',
  'UPDATE_WORKSPACES',
  'DELETE_WORKSPACES',
  'PUBLISH_WORKSPACES',
  'MERGE_WORKSPACES',
  //Cohort College External ID
  'READ_COHORT_COLLEGE_EXTERNAL_IDS',
  'CREATE_COHORT_COLLEGE_EXTERNAL_IDS',
  'UPDATE_COHORT_COLLEGE_EXTERNAL_IDS',
  'DELETE_COHORT_COLLEGE_EXTERNAL_IDS',
  //Scheduled Notifications
  'READ_SCHEDULED_NOTIFICATIONS',
  'CREATE_SCHEDULED_NOTIFICATIONS',
  'UPDATE_SCHEDULED_NOTIFICATIONS',
  'DELETE_SCHEDULED_NOTIFICATIONS',
  //Mixpanel Events
  'READ_MIXPANEL_EVENTS',
  'CREATE_MIXPANEL_EVENTS',
  'UPDATE_MIXPANEL_EVENTS',
  'DELETE_MIXPANEL_EVENTS',
  //Profile Photo
  'READ_PROFILE_PHOTOS',
  'CREATE_PROFILE_PHOTOS',
  'UPDATE_PROFILE_PHOTOS',
  'DELETE_PROFILE_PHOTOS',
  //QR Batches
  'READ_QR_BATCHES',
  'CREATE_QR_BATCHES',
  'UPDATE_QR_BATCHES',
  'DELETE_QR_BATCHES',
  //QR Codes
  'READ_QR_CODES',
  'CREATE_QR_CODES',
  'UPDATE_QR_CODES',
  'DELETE_QR_CODES',
  //QR Resources
  'READ_QR_RESOURCES',
  'CREATE_QR_RESOURCES',
  'UPDATE_QR_RESOURCES',
  'DELETE_QR_RESOURCES',
  // OTHER
  'FILE_UPLOAD',
  'TARGET_RECIPIENTS_OWNED_BY_OWNER',
  'TARGET_RECIPIENTS_NOT_OWNED_BY_OWNER',
  'MANAGE_ROOM',
  'EDIT_DISPLAY_NAME',
  'SEND_ROOM_MESSAGE',
  'VIEW_ALL_REPORTS',
  'GET_SALESFORCE_SYNC_STATUS',
  'CONTROL_SALESFORCE_SYNC',
  'ANSWER_NOTIFICATION',
].forEach(key=>permissions[key] = key.toLowerCase());

export const PERMISSIONS = Object.freeze(permissions);
export default PERMISSIONS;


let levels = {};
[
  'myself',
  'myCollege',
  'myCoached',
  'myDistrict',
  'all',
].forEach(key=>levels[key.toUpperCase()] = key);
export const PERMISSION_LEVELS = Object.freeze(levels);
