import {SETTINGS} from '../'
import {Helper} from '../classes'


export const asyncValidateDirective = [
	'$q',
(
	$q,
)=>{
	return {
		restrict: 'A',
		require: '?ngModel',
		link: (scope, elem, attrs, ngModel)=>{
			if ( ! ngModel ) return; // do nothing if no ng-model
			ngModel.$asyncValidators.asyncValidate = (modelValue, viewValue)=>viewValue ? $q.when(scope.$eval(attrs.asyncValidate, {$model:modelValue, $view:viewValue})) : $q.resolve();
		},
	};
}];
asyncValidateDirective.selector = 'asyncValidate';
