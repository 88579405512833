import $ from 'jquery'
import angular from 'angular'
import {Helper} from '../'

export const inputTimeDirective = [
	'$filter',
	'$parse',
	'$timeout',
(
	$filter,
	$parse,
	$timeout,
)=>{
	return {
		restrict: 'A',
		require: '?^ngModel',
		scope: {
			min: '<',
		},
		link: function($scope, $element, attrs, ngModel){
			attrs.format = 'hh:mm a';
			let pattern = /^(\d\d?):(\d\d?) (am|pm)$/i;
			let positions = {
				hour: {0:0, 1:2, m:/^(\w+):/, p:/^[0-2]\d/},
				minute: {0:3, 1:5, m:/:(\w+)/, p:/[0-6]\d/},
				meridian: {0:6, 1:8, m:/ (\w+)$/, p:/(am|pm)$/i},
			};

			$element
				.on('click', e=>{
					autoCorrect();
					setSection(getSection());
				})
				.on('focus', e=>{
					if ( ! ngModel.$modelValue ) {
						autoCorrect();
						setSection(getSection());
					}
				})
				.on('blur', e=>autoCorrect())
				.on('paste', e=>{
					try {
						let value = e.clipboardData.getData('text');
						let d = moment(value, attrs.format);

						if ( d.isValid() ) {
							ngModel.$modelValue.hour(d.hour()).minute(d.minute());
							setTimeout(()=>{
								autoCorrect();
								setSection('hour');
							}, 100);
						}
					} catch(e){};
				})
				.on('keydown', function(e){
					switch (e.keyCode) {
						case 37: // left button hit
							tabBackward(e); break;
						case 38: // up button hit
							e.preventDefault();
							e.stopPropagation();
							offsetTime(1); break;
						case 39: // right button hit
							tabForward(e); break;
						case 40: // down button hit
							e.preventDefault();
							e.stopPropagation();
							offsetTime(-1); break;
						case 186: // colon
							if ( getSection() == 'hour' ) {
								tabForward(e);
								e.preventDefault();
								e.stopPropagation();
							}
							break;
						case 32: // space
							if ( getSection() == 'minute' ) {
								tabForward(e);
								e.preventDefault();
								e.stopPropagation();
							}
							break;
						case 9: // TAB
							if ( e.shiftKey ) {
								if ( getSection() != 'hour' ) {
									tabBackward(e);
									e.preventDefault();
									e.stopPropagation();
								}
							} else
							if ( getSection() != 'meridian' ) {
								tabForward(e);
								e.preventDefault();
								e.stopPropagation();
							}
							break;
						default:
							if ( e.keyCode >= 48 && e.keyCode <= 57 ) {
								setTimeout(()=>{
									if ( getSectionValue().length == 2 ) {
										tabForward(e);
									}
								}, 0);
							}
					}
					console.log('down', e.keyCode, e.shiftKey, e.ctrlKey, e.metaKey);
				})

			
			function getSection(){
				let pos = $element.prop('selectionStart');
				if ( $element.val().length < 1 )
					return 'hour';

				for( let k in positions )
					if ( pos >= positions[k][0] && pos <= positions[k][1] )
						return k;
				return 'unknown';
			}
			function getSectionValue(){
				let cur = getSection();
				let pos = positions[cur];
				if ( pos ) {
					let m = ngModel.$viewValue.match(pos.m);
					if ( m ) {
						console.log(cur, m[1]);
						return m[1]
					}
				}
				return '';
			}
			function setSection(part){
				if ( positions[part] ) {
					let pos = positions[part];
					setTimeout(()=>$element[0].setSelectionRange(pos[0], pos[1]), 0);
				}
			}

			function autoCorrect(){
				let model = ngModel.$modelValue || moment().startOf('day');
				let val = model.format(attrs.format);
				if ( $scope.min ) {
					let min = moment($scope.min);
					if ( model.isBefore(min) ) {
						val = min.format(attrs.format);
						model.hour(min.hour).minute(min.minute);
					}
				}
				$timeout(()=>{
					ngModel.$setViewValue(val);
					ngModel.$processModelValue();
					$element.val(val);
					console.log(val);
				});
			}

			function tabForward(e){
				let cur = getSection();
				autoCorrect();
				if ( cur == 'hour' ) setSection('minute');
				else if ( cur == 'minute' ) setSection('meridian');
				else setSection('hour');
				if ( e ) {
					e.preventDefault();
					e.stopPropagation();
				}
			}
			function tabBackward(e){
				let cur = getSection();
				autoCorrect();
				if ( cur == 'meridian' ) setSection('minute');
				else if ( cur == 'minute' ) setSection('hour');
				else return setSection('meridian');
				if ( e ) {
					e.preventDefault();
					e.stopPropagation();
				}
			}

			function offsetTime(n){
				let part = getSection();
				switch( part ) {
					case 'hour':
						ngModel.$modelValue.hour(ngModel.$modelValue.hour() + n % 24);
						break;
					case 'minute':
						ngModel.$modelValue.minute(ngModel.$modelValue.minute() + n % 24);
						break;
					case 'meridian': 
						let hr = ngModel.$modelValue.hour();
						ngModel.$modelValue.hour(hr >= 12 ? hr - 12 : hr + 12);
						break;
					default: return;
				}
				$element.val(ngModel.$modelValue.format(attrs.format));
				ngModel.$processModelValue();
				setSection(part);
			}

			ngModel.$parsers.push(function(value){
				let m = value.match(pattern);
				let d = moment(value, attrs.format);
				let part = getSection();

				if ( !m || !d.isValid() ) {
					ngModel.$rollbackViewValue();
					return ngModel.$modelValue;
				}

				ngModel.$setValidity('format', true);

				if ( ! ngModel.$modelValue ) {
					ngModel.$modelValue = d;
					ngModel.$setViewValue(value);
					ngModel.$processModelValue();
				}
				return ngModel.$modelValue.hour(d.hour()).minute(d.minute());
			});
			ngModel.$formatters.push(value=>{
				return moment(value).format(attrs.format);
			});

			$scope.$watch('min', function(newVal, oldVal){
				if ( newVal !== oldVal )
					autoCorrect();
			});

		},
	}
}];
inputTimeDirective.selector = 'inputTime';
