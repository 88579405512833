import {Helper} from '..'

export const scrollSpyDirective = [
(
)=>{
	return {
		restrict: 'A',
		link: ($scope, $element, attrs)=>{
			let sections, active, elem = $element.get(0);
			let onScroll = Helper.throttle(($ev)=>{
				let scroll = elem.scrollTop + Helper.getOffsetTop(elem),
					mid = scroll + elem.clientHeight*2/3,
					end = elem.scrollHeight - elem.clientHeight;
				let current = sections.find(section=>{
					let offset = Helper.getOffsetTop(section);
					return (offset < mid) || (scroll >= end && offset > end);
				});
				if ( current && (current != active || !$ev || !$ev.originalEvent) ) {
					// console.log('scroll', current.getAttribute('scroll-spy-target'), mid, current.offsetTop, $ev);
					$scope.$evalAsync(attrs.scrollSpy, {
						$current: current,
						$value: current.getAttribute('scroll-spy-target'),
						$old: active,
					});
					active = current;
				}
			}, 200, true);

			$scope.$parent?.$once('deps-loaded', ()=>setTimeout(function(){
					$element.on('scroll', onScroll);
					sections = $element.find('[scroll-spy-target]').get().reverse();
					onScroll();
				}, 250));
			
			let destructors = [
				$scope.$on('scroll-spy', ()=>onScroll()),
				$scope.$on('scroll-spy-update', ()=>sections = $element.find('[scroll-spy-target]').get().reverse()),
			];

			$scope.$once('$destroy', ()=>{
				while( destructors.length ) destructors.pop()();
				$element.off('scroll', onScroll);
			});
		},
	}
}];
scrollSpyDirective.selector = 'scrollSpy';
