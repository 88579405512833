import intersection from 'lodash/intersection'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import difference from 'lodash/difference'
import {DependencyInjected, Helper} from '../classes'

const ERROR = require('../messages.json').RECIPIENT;

export const TERMS = {
  semester: 'Semester',
  trimester: 'Trimester',
  quarter: 'Quarter',
};

export const OP_TO_WORD = {};
export const WORD_TO_OP = {};
'= equals, != not-equal, < less-than, > greater-than, <= less-than-equal, >= greater-than-equal'.split(/\s*,\s*/gm).forEach(str=>{
  let [op, word] = str.split(' ');
  OP_TO_WORD[op] = word;
  WORD_TO_OP[word] = op;
});


export class Recipient extends DependencyInjected {
  static get $inject(){return [
    '$q',
    'apiMap',
    'MAPPINGS_JSON',
  ]}
  static get selector(){return 'recipient'};

  static get defaultOpts(){return {
    ignoreInvalidValues: false,
    ignoreInvalidKeys: false,
    ignoreInvalidOps: false,
    ignoreInvalids: false,
    ignoreErrors: false,
  }};



  prepare(){
    let promises = [];

    // BTC-508, removing requests for all resources to instead use w/ myTokenAdministers instead
    // might error when parsing recipients that are out of myTokenAdminister

    this.typeMap = {term: Helper.superMap(this.MAPPINGS_JSON.colleges?.term_key || TERMS)};

    promises.push(this.apiMap.getColleges().then((data)=>this.typeMap.college=data));
    promises.push(this.apiMap.getAllDistricts().then((data)=>this.typeMap.district=data));
    promises.push(this.apiMap.getAllCohorts().then((data)=>this.typeMap.cohort=data));

    return this.$q.all(promises);
  }

  stringify(list, allCohort){
    let colleges=[], notColleges=[], 
      cohorts=[], notCohorts=[];
    list.forEach(item=>{
      switch(item.type) {
        case 'college':
          if ( item.op == '=' )
            colleges.push('college=' + item.model._id);
          else
            notColleges.push('college!='+ item.model._id);
          break;
        case 'district':
          if ( item.op == '=' )
            colleges.push('district='+ item.model._id);
          else
            notColleges.push('district!='+ item.model._id);
          break;
        case 'term':
          if ( item.op == '=' )
            colleges.push('term='+ item.model._id);
          else
            notColleges.push('term!='+ item.model._id);
          break;
        case 'level':// levels are considered cohorts
        case 'cohort':
          if ( item.op == '=' )
            cohorts.push('cohort='+ item.model.name);
          else
            notCohorts.push('cohort!='+ item.model.name);
          break;
      }
    });

    let group = [];
    if ( colleges.length )
      group.push(colleges.sort().join(' OR '));
    if ( cohorts.length )
      group.push(cohorts.sort().join(allCohort? ' AND ': ' OR '));
    if ( notColleges.length )
      group.push(notColleges.sort().join(' AND '));
    if ( notCohorts.length )
      group.push(notCohorts.sort().join(' AND '));

    if ( group.length > 1 )
      return '(('+ group.join(') AND (') +'))';
    if ( group.length === 1 )
      return '('+ group[0] +')';
    return '';
  }

  summarize(list, allCohort){
    if ( this.isRecipientError(list) )
      return list.message;

    let colleges=[], notColleges=[], 
      districts=[], notDistricts=[], 
      levels=[], notLevels=[], 
      cohorts=[], notCohorts=[],
      terms=[], notTerms=[];
    list.forEach(item=>{
      // var item = this.typeMap[parsed.type].byId[parsed.id];
      switch(item.type) {
        case 'district':
          if ( item.op=='=' )
            districts.push(Helper.encodeHtml(item.model.name));
          else
            notDistricts.push(Helper.encodeHtml(item.model.name));
          break;
        case 'college':
          if ( item.op=='=' )
            colleges.push(Helper.encodeHtml(item.model.name));
          else
            notColleges.push(Helper.encodeHtml(item.model.name));
          break;
        case 'level':
        case 'cohort':
          if ( item.model.cohort_type === 'level' ) {
            if ( item.op=='=' )
              levels.push(Helper.encodeHtml(item.model.title || item.model.description || item.model.name));
            else
              notLevels.push(Helper.encodeHtml(item.model.title || item.model.description || item.model.name));
          } else {
            if ( item.op=='=' )
              cohorts.push(Helper.encodeHtml(item.model.title || item.model.description || item.model.name));
            else
              notCohorts.push(Helper.encodeHtml(item.model.title || item.model.description || item.model.name));
          }
          break;
        case 'term':
          if ( item.op=='=' )
            terms.push(Helper.encodeHtml(item.model.name));
          else
            notTerms.push(Helper.encodeHtml(item.model.name));
          break;
      }
    });

    let groupA=[], groupB=[],
      groupZ=[], groupY=[];
    if ( districts.length ) {
      let sentence = 'My college is ';
      if ( districts.length == 1 ) sentence += `in <b>${districts[0]}</b>`;
      if ( districts.length == 2 ) sentence += `in either <b>${districts[0]}</b> or <b>${districts[1]}</b>`;
      if ( districts.length > 2 )  sentence += `in any of these districts: <b>${districts.slice(0,-1).join('</b>, <b>')}</b> or <b>${districts.slice(-1)[0]}</b>`;
      groupA.push(sentence);
    }
    if ( notDistricts.length ) {
      let sentence = 'My college is <i>not</i> ';
      if ( notDistricts.length == 1 ) sentence += `in <b>${notDistricts[0]}</b>`;
      if ( notDistricts.length == 2 ) sentence += `in <b>${notDistricts[0]}</b> nor <b>${notDistricts[1]}</b>`;
      if ( notDistricts.length > 2 )  sentence += `these districts: <b>${notDistricts.slice(0,-1).join('</b>, <b>')}</b> nor <b>${notDistricts.slice(-1)[0]}</b>`;
      groupZ.push(sentence);
    }

    if ( terms.length ) {
      let sentence = 'My college has ';
      if ( terms.length == 1 ) sentence += `<b>${terms[0]}</b>`;
      if ( terms.length == 2 ) sentence += `either <b>${terms[0]}</b> or <b>${terms[1]}</b>`;
      if ( terms.length > 2 )  sentence += `either <b>${terms.slice(0,-1).join('</b>, <b>')}</b> or <b>${terms.slice(-1)[0]}</b>`;
      groupA.push(`${sentence} academic terms`);
    }
    if ( notTerms.length ) {
      let sentence = 'My college has <i>no</i> ';
      if ( notTerms.length == 1 ) sentence += `<b>${notTerms[0]}</b>`;
      if ( notTerms.length == 2 ) sentence += `<b>${notTerms[0]}</b> nor <b>${notTerms[1]}</b>`;
      if ( notTerms.length >  2 ) sentence += `<b>${notTerms.slice(0,-1).join('</b>, <b>')}</b> nor <b>${notTerms.slice(-1)[0]}</b>`;
      groupZ.push(`${sentence} academic terms`);
    }
    if ( colleges.length ) {
      let sentence;
      if ( colleges.length == 1 ) sentence = `I study at <b>${colleges[0]}</b>`;
      if ( colleges.length == 2 ) sentence = `I attend classes in either <b>${colleges[0]}</b> or <b>${colleges[1]}</b>`;
      if ( colleges.length > 2 )  sentence = `I attend classes in any of these schools: <b>${colleges.slice(0,-1).join('</b>, <b>')}</b> or <b>${colleges.slice(-1)[0]}</b>`;
      groupA.push(sentence);
    }
    if ( notColleges.length ) {
      let sentence;
      if ( notColleges.length == 1 ) sentence = `I <i>don't</i> study at <b>${notColleges[0]}</b>`;
      if ( notColleges.length == 2 ) sentence = `I <i>don't</i> attend classes at <b>${notColleges[0]}</b> nor <b>${notColleges[1]}</b>`;
      if ( notColleges.length > 2 )  sentence = `I <i>don't</i> attend classes in any of these schools: <b>${notColleges.slice(0,-1).join('</b>, <b>')}</b> nor <b>${notColleges.slice(-1)[0]}</b>`;
      groupZ.push(sentence);
    }


    const cohortOp = `<i>${allCohort ? 'and' : 'or'}</i>`;
    const eitherBoth = `<i> ${allCohort ? 'both' : 'either'}</i>`;
    const anyAll = `<i>${allCohort ? 'all' : 'any'}</i>`;

    if ( cohorts.length ) {
      let sentence = 'I am a member of ';
      if ( cohorts.length == 1 ) sentence += `<b>${cohorts[0]}</b>`;
      if ( cohorts.length == 2 ) sentence += `${eitherBoth} <b>${cohorts[0]}</b> ${cohortOp} <b>${cohorts[1]}</b>`;
      if ( cohorts.length > 2 )  sentence += `${anyAll} of these cohorts: <b>${cohorts.slice(0,-1).join('</b>, <b>')}</b> ${cohortOp} <b>${cohorts.slice(-1)[0]}</b>`;
      groupB.push(sentence);
    }
    if ( notCohorts.length ) {
      let sentence = `I am <i>not</i> a member of `;
      if ( notCohorts.length == 1 ) sentence += `<b>${notCohorts[0]}</b>`;
      if ( notCohorts.length == 2 ) sentence += `<b>${notCohorts[0]}</b> nor <b>${notCohorts[1]}</b>`;
      if ( notCohorts.length > 2 )  sentence += `these cohorts: <b>${notCohorts.slice(0,-1).join('</b>, <b>')}</b> nor <b>${notCohorts.slice(-1)[0]}</b>`;
      groupZ.push(sentence);
    }

    if ( levels.length ) {
      let sentence = 'I am ';
      if ( levels.length == 1 ) sentence += `in level <b>${levels[0]}</b>`;
      if ( levels.length == 2 ) sentence += `in ${eitherBoth} level <b>${levels[0]}</b> ${cohortOp} <b>${levels[1]}</b>`;
      if ( levels.length > 2 )  sentence += `in ${anyAll} of these levels: <b>${levels.slice(0,-1).join('</b>, <b>')}</b> ${cohortOp} <b>${levels.slice(-1)[0]}</b>`;
      groupB.push(sentence);
    }
    if ( notLevels.length ) {
      let sentence = 'I am <i>not</i> in ';
      if ( notLevels.length == 1 ) sentence += `level <b>${notLevels[0]}</b>`;
      if ( notLevels.length == 2 ) sentence += `level <b>${notLevels[0]}</b> nor <b>${notLevels[1]}</b>`;
      if ( notLevels.length > 2 )  sentence += `levels: <b>${notLevels.slice(0,-1).join('</b>, <b>')}</b> nor <b>${notLevels.slice(-1)[0]}</b>`;
      groupZ.push(sentence);
    }


    let final = '';
    if ( groupA.length ) {
      final += groupA.join(' or ');
    } else {
      final += 'I study at <i>any</i> of the colleges';
    }
    if ( groupB.length ) {
      final += ' <i>and</i> '+ groupB.join(` ${cohortOp} `);
    }
    if ( groupZ.length ) {
      final += ' <i>but</i> ';
      if ( groupZ.length == 1 ) {
        final += groupZ[0];
      } else if ( groupZ.length > 1 ) {
        final += groupZ.slice(0,-1).join(`, `) +' and '+ groupZ.slice(-1)[0];
      }
    }

    return final;
  }

  isAdvanced(expr){
    let result = expr!==undefined ? this.parse(expr, {ignoreErrors:true, ignoreInvalids:true}) : this.last;
    if ( result && result.advanced === undefined )
      result.advanced = this._isAdvaced(result, result.parse);
    return Boolean(result?.advanced || result?.errors?.length > 0);
  }
  isAllCohort(expr){
    let result = expr!==undefined ? this.parse(expr, {ignoreErrors:true, ignoreInvalids:true}) : this.last;
    return result?.cohort_op == 'AND';
  }

  hasErrors(expr){
    let result = expr!==undefined ? this.parse(expr, {ignoreErrors:true, ignoreInvalids:true}) : this.last;
    return this.last?.errors?.length > 0;
  }
  getErrors(expr){
    let result = expr!==undefined ? this.parse(expr, {ignoreErrors:true, ignoreInvalids:true}) : this.last;
    return this.last?.errors || [];
  }


  enumerate(expr, opts={}){
    const result = expr!==undefined ? this.parse(expr, opts) : this.last;
    if ( ! result || ! result.list )
      return [];

    return result.list
      .filter(child=>!child.error)
      .map(child=>({
        _id: child.value,
        model: child.model,
        type: child.type,
        op: child.op,
      }));
    // list.advanced = result.advanced;
    // return this.last.list = list;
  }

  parse(expr, opts={}){
    let result = this.last = {expr:expr, errors:[], list:[]};
    result.parse = this._parseGroup(expr.trim(), 0, 0, opts);
    result.errors.sort((a,b)=>a.offset - b.offset)
    // result.advanced = result.parse.advanced || this._isAdvaced(result);
    
    return result;
  }


  _parseGroup(expr, offset=0, level=0, opts={}){
    const group = new Group(offset, level);
    let next, expectingLogicOp=false;
    while( next = this._parseNextWord(expr, offset) ){
      if ( next.spaces !== undefined ) // has spaces
        offset += next.spaces.length;

      if ( next.word == '(' ) {
        if ( expectingLogicOp ) {
          group.error = group.error || this.generateError(ERROR.UNKNOWN_OPEN_GROUP, offset, next.word, expr);
          if ( !opts.ignoreErrors )
            return group.error;
        }

        group.parenthesis = true;
        ++offset;

        let subgrp = this._parseGroup(expr, offset, level+1, opts);
        group.children.push(subgrp);
        if ( subgrp instanceof Error || (this.isRecipientError(subgrp) && ! opts.ignoreErrors) )
          return subgrp;
        group.offset = offset = subgrp.offset;

        if ( !group.advanced && subgrp.advanced )
          group.advanced = subgrp.advanced;
        
        next = this._parseNextWord(expr, offset);
        if ( next ) {
          if ( next.spaces !== undefined ) // has spaces
            offset += next.spaces.length;
          
          if ( next.word == ')' ) {
            group.offset = ++offset;
          }
        } else {
          group.error = group.error || this.generateError(ERROR.MISSING_CLOSED_GROUP, group.start, '(', expr);
          if ( !opts.ignoreErrors )
            return group.error;
        }
      } else
      if ( next.word == ')' ) {
        if ( level == 0 ) { // floating close parenthesis
          group.error = group.error || this.generateError(ERROR.UNKNOWN_CLOSE_GROUP, offset, next.word, expr);
          if ( !opts.ignoreErrors )
            return group.error;
          group.offset = (offset += next.word.length);
        } else {
          // do nothing, offset is adjusted if at parent level
          group.offset = offset;
          break;
        }
      } else
      { // capture keywords here
        if ( (expectingLogicOp || group.children[group.children.length-1]?.error) && /^(or|and)$/i.test(next.word) ) {
          group.ops.push(next.word.toUpperCase());
          group.offset = (offset += next.word.length);

          if ( !group.advanced ) {
            let ops = group.ops.filter(Helper.uniqueFilter);
            if ( ops.length > 1 ) {
              group.advanced = 'Logical operators are different';
            } else
            if ( ops[0]!='AND' && group.children.find(child=>child instanceof Group) ) {
              group.advanced = 'Custom logical operators';
            }
          }
          expectingLogicOp = false;
          continue;
        }

        let child = this._parseKeyValue(expr, offset, level, opts);
        if ( child instanceof Error || this.isRecipientError(child) ) {
          return child;
        } else
        if ( expectingLogicOp && !child.error ) {
          child.error = this.generateError(ERROR.MISSING_LOGIC_OP, offset, next.word, expr);
          if ( ! opts.ignoreErrors )
            return child.error;
        }

        group.children.push(child);
        group.offset = offset = child.offset;

        if ( !group.advanced && !!child.advanced )
          group.advanced = child.advanced;
        if ( child.error ) // child has error, do not require logic op yet
          continue;
      }
      // loop again & expect another expression or group
      expectingLogicOp = true;
    }

    if ( group.children.length > 0 && group.children.length == group.ops.length ) { // we have a floating ops
      let lastOp = group.ops[group.ops.length-1];
      group.error = group.error || this.generateError(ERROR.UNKOWN_LOGIC_OP, offset - (next?.spaces?.length||0) - lastOp.length, lastOp, expr);
      if ( !opts.ignoreErrors )
        return group.error;
      lastOp.length;
    }

    return group;
  }

  // get next expression. Can either be a parenthesis or expression
  _parseNextWord(expr, offset){
    let m = expr.substr(offset).match(/^(\s*)([\)\(]|[^\)\(]+?(?=[\s\)\(]|$))/i);
    return m ? {spaces: m[1], word: m[2]} : null;
  }

  _parseKeyValue(expr, offset, level, opts){
    let res = new KeyValue(offset);

    // match for expr (abd=123) or word 
    let found = expr.substr(offset).match(/(\s*)(\w+(?=\s*(?:[!<>]?=|<|>))|[^\s\)\(]+)(\s*)(?:([!<>]?=|<|>)(\s*)([^\s\)\(]+?(?=[\s\)\(]|$))?)?/i);
    if ( found ) {
      let [str, spaces0, key, spaces1, op, spaces2, val] = found;

      if ( spaces0 !== undefined )
        offset += spaces0.length;

      if ( op ) {
        // if ( /^(college|district|cohort|term|joined)$/i.test(key) ) {
        if ( /^(college|district|cohort|term)$/i.test(key) ) { // remove joined support
          res.type = key.toLowerCase();
          if ( ! res.advanced && op=='joined' )
            res.advanced = `Used '${op}' keyword`;
        } else {
          res.error = this.generateError(ERROR.INVALID_KEY, offset, key, expr);
          if ( !(opts.ignoreInvalidKeys || opts.ignoreInvalids) )
            return res.error;
        }
        offset += key.length;

        if ( spaces1 !== undefined )
          offset += spaces1.length;

        if ( /^(=|!=|<|<=|>|>=)$/.test(op) ) {
          if ( res.type=='joined' || /^(!?=)$/.test(op) ) {
            res.op = op;
            if ( ! res.advanced && ! ['=', '!='].includes(op) )
              res.advanced = `Used comparison operator '${op}'`;
          } else {
            res.error = this.generateError(ERROR.INVALID_OP, offset, op, expr);
          }
        } else {
          res.error = this.generateError(ERROR.INVALID_OP, offset, op, expr);
        }
        if ( res.error && !(opts.ignoreInvalidOps || opts.ignoreInvalids) )
          return res.error;
        offset += op.length;
        
        if ( spaces2 !== undefined )
          offset += spaces2.length;

        res.value = val;
        if ( res.type && ! this._validateTypeValue(res) ) {
          res.error = this.generateError(ERROR.INVALID_VALUE, offset, res.value, expr);
          if ( !(opts.ignoreInvalidValues || opts.ignoreInvalids) )
            return res.error;
        }
        offset += val.length;

      } else { // a single word here
        let nextWord = key;
        if ( /^(none|all)$/i.test(nextWord) ) {
          res.type = nextWord.toLowerCase();
          if ( ! res.advanced )
            res.advanced = `Used '${nextWord}' keyword`;
        } else 
        if ( /^(college|district|cohort|term|joined)$/i.test(nextWord) ) {
          res.error = this.generateError(ERROR.MISSING_OP, offset, nextWord, expr);
          if ( !opts.ignoreErrors )
            return res.error;
        } else {
          if ( /^(OR|AND)$/.test(nextWord) )
            res.error = this.generateError(ERROR.UNKOWN_LOGIC_OP, offset, nextWord, expr);
          else
            res.error = this.generateError(ERROR.INVALID_KEY, offset, nextWord, expr);
          if ( !opts.ignoreErrors )
            return res.error;
        }
        offset += nextWord.length;  
      }
    } else {
      res.error = this.generateError(ERROR.MISSING_WORD, offset, '', expr);
      if ( !(opts.ignoreInvalidKeys || opts.ignoreInvalids) )
        return res.error;
    }
    if ( ! this.last.list.find(v=>v.toString()==res.toString()) )
      this.last.list.push(res);

    res.offset = offset;
    return res;
  }
  _validateTypeValue(obj){
    if ( obj.type == 'cohort' ) {
      let item = this.typeMap.cohort?.byName?.[obj.value] || this.typeMap.cohort?.byId?.[obj.value];
      if ( item ) {
        obj.value = item._id;
        obj.model = item;
        if ( item.cohort_type == 'level' )
          obj.type = 'level';
        return true;
      }
    } else if ( obj.type === 'joined' ) {
      if ( /^\d+$/.test(obj.value) ) {
        obj.model = obj.value;
        return true;
      }
    } else {
      let item = this.typeMap[obj.type]?.byId?.[obj.value];
      if ( item ) {
        obj.value = item._id;
        obj.model = item;
        if ( (obj.type == 'college' || obj.type == 'district') && item.status != 'active' )
          obj.advanced = `${obj.type} not active`;
        return true;
      }
    }
    return false;
  }

  _isAdvaced(data, group){
    group = group || data.parse;

    if ( uniqBy(data.list, item=>`${item.type}${item.value}`).length != data.list.length )
      return group.advanced = 'Duplicate model';

    const hasSingle = group.children.find(child=>child instanceof KeyValue);
    const hasSubGroup = group.children.find(child=>child instanceof Group);
    if ( group.children.length > 1 && hasSingle && hasSubGroup )
      return group.advanced = 'Diff child types';
    
    if ( hasSubGroup ) {
      let advChild = group.children.find(sub=>this._isAdvaced(data, sub));
      if ( advChild )
        return group.advanced = advChild.advanced;
      if ( uniq(group.ops).length > 1 || (group.ops.length && group.ops[0] != 'AND') )
        return group.advanced = `Grand group with ${group.ops[0]} op`;
    }

    if ( hasSingle ) {
      if ( group.children.length > 1 && uniq(group.children.map(child=>child.op)).length > 1 )
        return group.advanced = 'Diff member ops in group';
      if ( group.ops.length > 1 && uniq(group.ops).length > 1 )
        return group.advanced = 'Diff logical ops in group';

      const collegeGroup = ['district', 'college', 'term'];
      const cohortGroup = ['cohort', 'level'];
      
      let types = uniq(group.children.map(child=>child.type));

      if ( intersection(types, collegeGroup).length ) {
        if ( difference(types, collegeGroup).length )
          return group.advanced = 'Diff college member types';

        if ( group.children[0].op == '=' ) {
          if ( data._$collegeGroup )
            return group.advanced = 'Duplicate college group';
          data._$collegeGroup = group;
          if ( group.ops.length && group.ops[0] != 'OR' )
            return group.advanced = `College group with '${group.ops[0]}' op`;
        } else {
          if ( data._$notCollegeGroup )
            return group.advanced = 'Duplicate not-college group';
          data._$notCollegeGroup = group;
          if ( group.ops.length && group.ops[0] != 'AND' )
            return group.advanced = `Not-college group with '${group.ops[0]}' op`;
        }
      } else
      if ( intersection(types, cohortGroup).length ) {
        if ( difference(types, cohortGroup).length )
          return group.advanced = 'Diff cohort member types';

        if ( group.children[0].op == '=' ) {
          if ( data._$cohortGroup )
            return group.advanced = 'Duplicate cohort group';
          data._$cohortGroup = group;
          data.cohort_op = group.ops[0];
        } else {
          if ( data._$notCohortGroup )
            return group.advanced = 'Duplicate not-cohort group';
          data._$notCohortGroup = group;
          if ( group.ops.length && group.ops[0] != 'AND' )
            return group.advanced = `Not-cohort group with '${group.ops[0]}' op`;
        }
      }
    }

    return group.advanced;
  }


  generateError(msg, off, word, debug){
    let err = new RecipientError(msg, off, word, debug);
    this.last.errors.push(err);
    return err;
  }

  isRecipientError(err){
    return err instanceof RecipientError;
  }
}

class Group {
  constructor(start, level){
    this.start = start;
    this.offset = start;
    this.level = level;
    this.children = [];
    this.ops = [];
  }
}
class KeyValue {
  constructor(start){
    this.start = start;
  }
  toString(){
    return `${this.type}${this.op||''}${this.value||''}`;
  }
}

export class RecipientError {
  constructor(msg, ofs, w, debug) {
    this.name = 'Recipient Expression Error';
    this.message = msg;
    this.offset = ofs;
    this.word = w || '';
    this.code = msg +' at '+ ofs +(w ? ' got "'+ w +'"': '');
    this.debug = debug;
  }

  toString(){
    return this.code;
  }
}
