import angular from 'angular'

// generated from http://mcg.mbitson.com/#!?ggbluegreen=%236582ff&ggorange=%23f37f1c&gglightblue=%235cdbe6&themename=gg

let themes = {
	'ggbluegreen': {
		'50': 'e0efec',
		'100': 'b3d8d0',
		'200': '80beb0',
		'300': '4da390',
		'400': '269079',
		'500': '007c61',
		'600': '007459',
		'700': '00694f',
		'800': '005f45',
		'900': '004c33',
		'A100': '80ffcc',
		'A200': '4dffb8',
		'A400': '1affa3',
		'A700': '00ff99',
		'contrastDefaultColor': 'light',
		'contrastDarkColors': '50 100 A100 A200 A400 A700',
		'contrastLightColors': '200 300 400 500 600 700 800 900',
	},
	'ggorange': {
		'50': 'fbe0e0',
		'100': 'f5b3b3',
		'200': 'ee8080',
		'300': 'e74d4d',
		'400': 'e22626',
		'500': 'dd0000',
		'600': 'd90000',
		'700': 'd40000',
		'800': 'cf0000',
		'900': 'c70000',
		'A100': 'ffefef',
		'A200': 'ffbcbc',
		'A400': 'ff8989',
		'A700': 'dd0000',
		'contrastDefaultColor': 'light',
		'contrastDarkColors': '50 100 200 A100 A200 A400 A700',
		'contrastLightColors': '300 400 500 600 700 800 900',
	},
	'gglightblue': {
		'50': 'e4eff2',
		'100': 'bdd6df',
		'200': '91bbca',
		'300': '649fb5',
		'400': '438ba5',
		'500': '227695',
		'600': '1e6e8d',
		'700': '196382',
		'800': '145978',
		'900': '0c4667',
		'A100': '9ad7ff',
		'A200': '67c2ff',
		'A400': '34aeff',
		'A700': '1aa4ff',
		'contrastDefaultColor': 'light',
		'contrastDarkColors': '50 100 200 300 400 A100 A200 A400 A700',
		'contrastLightColors': '500 600 700 800 900',
	}
};


export const themeConfig = ['$mdThemingProvider', ($mdThemingProvider)=>{
	angular.forEach(themes, (palette, name)=>$mdThemingProvider.definePalette(name, palette));

	let theme = $mdThemingProvider.theme('default')
		.primaryPalette('ggbluegreen',{
			'default': '500',
			'hue-1': '100',
			'hue-2': '900',
			'hue-3': '200',
		})
		.accentPalette('gglightblue',{
			'default': '500',
			'hue-1': '300',
			'hue-2': '800',
			'hue-3': 'A700',
		})
		.warnPalette('ggorange');

		theme.foregroundPalette = {
			1: '#000',
			2: 'rgba(0,0,0,0.87)',
			3: 'rgba(0,0,0,0.54)',
			4: 'rgba(0,0,0,0.38)',
		};

	$mdThemingProvider.theme('warning').backgroundPalette('teal').dark();
	$mdThemingProvider.theme('danger').backgroundPalette('orange').dark();

	$mdThemingProvider.theme('info').backgroundPalette('grey').dark();

}];

export default themeConfig;