<md-dialog md-theme="'default'" role="dialog" aria-labelledby="dialogTitle" aria-describedby="dialogDesc">
	<md-dialog-content>
		<div class="md-dialog-content">
			<div class="md-headline" id="dialogTitle">
				<span md-colors="::{color:'red'}"><span class="fas fa-exclamation-circle"></span>&nbsp;&nbsp;Error Info: {{ ctrl.error.name }}</span><br>
			</div>
			<p class="md-subhead" id="dialogDesc">{{ ::ctrl.error.message }}</p>
			<p class="md-caption">Code: <code>{{ ::ctrl.error.code }}</code></p>
			<div>
				<span class="md-caption">Please copy/paste the below special text and email to mycoachsupport@beyond12.org to help us fix this, along with what steps you took to get here. </span>
				<pre class="md-padding" md-colors="::{background:'grey-200'}"
					click-Select
					style="overflow:auto; white-space:pre-wrap; word-break:break-all; max-height:150px;"
				>{{ ::ctrl.error.debug }}</pre>
			</div>
		</div>
	</md-dialog-content>
	<md-dialog-actions layout="row" layout-align="center center">
    <md-button ng-click="ctrl.close()">Close</md-button>
  </md-dialog-actions>
</md-dialog>