import $ from 'jquery'

export const chipClassDirective = [
(
)=>{
	return {
		restrict: 'A',
		scope: {
			chipClass: '<*',
		},
		link: function($scope, $element, $attr){
			$scope.$watch('chipClass', (newVal, oldVal)=>{
				$($element).parents('md-chip')
					.removeClass(oldVal)
					.addClass(newVal)
			});
		}
	};
}];
chipClassDirective.selector = 'chipClass';