<md-input-container class="date md-input-has-placeholder no-error-space" 
  ng-model-options="{debounce: 1000}">
  <label aria-hidden="true">From Date</label>
  <input name="dateFrom" type="text"
    aria-label="from date"
    date-picker
    valid-date ng-model="ctrl.query.dateFrom" 
    ng-change="ctrl.updateDateRange(true)">
  <div ng-messages="headerForm.dateFrom.$error" role="alert">
    <!-- directive: use-template TEMPLATES.COMMON.VALID_DATE_MSGS -->
  </div>
</md-input-container>
<md-input-container class="date md-input-has-placeholder no-error-space"
  ng-model-options="{debounce: 1000}">
  <label aria-hidden="true">To Date</label>
  <input name="dateTo" type="text"
    aria-label="to date"
    date-picker
    valid-date ng-model="ctrl.query.dateTo"
    ng-change="ctrl.updateDateRange(true)">
  <div ng-messages="headerForm.dateTo.$error" role="alert">
    <!-- directive: use-template TEMPLATES.COMMON.VALID_DATE_MSGS -->
  </div>
</md-input-container>