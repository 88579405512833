/* global moment APP_VERSION APP_ENV APP_BEYOND12_MODE */
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import angular from 'angular'
import jQuery from 'jquery'
import 'jquery-ui-dist/jquery-ui'
import 'jquery.caret'
import uiRoute from '@uirouter/angularjs'
import ngMaterial from 'angular-material'
import ngMessages from 'angular-messages'
import ngJwt from 'angular-jwt'
import ngSanitize from 'angular-sanitize'
import ngMoment from 'angular-moment';

import '@fortawesome/fontawesome-free/css/all.css'
// import 'angular-material/angular-material.css'
import 'animate.css'
import './app.scss'

import commonModule from './common/common.module'
import guestModule from './guest/guest.module'
import mainModule from './main/main.module'


window.jQuery = window.$ = jQuery;
window.angular = angular;
window.moment = moment;

export default
  angular.module('app', [
    uiRoute,
    ngMaterial,
    ngMessages,
    ngJwt,
    ngSanitize,
    ngMoment,
    commonModule,
    guestModule,
    mainModule,
  ])
  .constant('moment', moment)
  .config(['$locationProvider', ($locationProvider)=>{
    $locationProvider.html5Mode(true);
    jQuery('body').removeClass('busy');
  }])
  .run(['$rootScope', '$state', ($rootScope, $state)=>{
    $rootScope.moment = moment;
    $rootScope.$state = $state;
    $rootScope.APP_VERSION = APP_VERSION;
    $rootScope.APP_BEYOND12_MODE = !!APP_BEYOND12_MODE;

    Object.getPrototypeOf($rootScope).$once = function(name, fn){
      let once = this.$on(name, (event, ...args)=>{
        once();
        return fn(event, ...args);
      });
      return once;
    };
  }])
  .run(['$rootScope', '$timeout', ($rootScope, $timeout)=>{
    $rootScope.trigger = (action, selector)=>$timeout(()=>jQuery(selector).trigger(action), 100);
  }])
  .name;


import './favicon.ico'
import './favicon-16x16.png'
import './favicon-32x32.png'
import './favicon-apple-touch.png'