import angular from 'angular'
import {DependencyInjected} from '../classes'

export class Session extends DependencyInjected {
	static get $inject(){return [
		'$window',
	]};
	static get selector(){return 'session'};


	init(){
		this.storage = this.$window.sessionStorage;
	}

	set(key, value){
		this.storage.setItem(key, JSON.stringify(value));
	}

	get(key){
		return JSON.parse(this.storage.getItem(key) || 'null');
	}

	remove(key){
		this.storage.removeItem(key);
	}

	clear(){
		this.storage.clear();
	}

}