import {DependencyInjected} from '../classes'

let mimes = [{
	mime: 'image/jpeg',
	pattern: [0xFF, 0xD8, 0xFF],
	mask: [0xFF, 0xFF, 0xFF],
}, {
	mime: 'image/png',
	pattern: [0x89, 0x50, 0x4E, 0x47],
	mask: [0xFF, 0xFF, 0xFF, 0xFF],
}, {
	mime: 'image/gif',
	pattern: [0x47, 0x49, 0x46, 0x38, 0x37, 0x61],
	mask: [0xFF, 0xFF, 0xFF, 0xFF, 0xFF],
}, {
	mime: 'image/gif',
	pattern: [0x47, 0x49, 0x46, 0x38, 0x39, 0x61],
	mask: [0xFF, 0xFF, 0xFF, 0xFF, 0xFF],
}];


export class Mimetype extends DependencyInjected {
  static get $inject(){return ['$q']};
  static get selector(){return 'mimetype'};

	
  identify(file){
		let defer = this.$q.defer();

		let reader = new FileReader();
		reader.onloadend = (e)=>{
			if ( !e.target.error ) {
				let bytes = new Uint8Array(e.target.result);

				for(var i=0, l = mimes.length; i<l; ++i){
					if ( this._check(bytes, mimes[i]) )
						return defer.resolve(mimes[i].mime);
				}
			}
			return defer.reject();
		};
		let blob = file.slice(0, 6);
		reader.readAsArrayBuffer(blob);

		return defer.promise;
  }

	_check(bytes, mime){
		for(var i=0, len=mime.mask.length; i<len; ++i){
			if ( (bytes[i] & mime.mask[i]) - mime.pattern[i] !== 0 ) {
				return false;
			}
		}
		return true;
	}
	
}