import {SETTINGS} from '../'
import {Helper} from '../classes'


export const validEmailDirective = [
	'$q',
(
	$q,
)=>{
	return {
		restrict: 'A',
		require: '?ngModel',
		link: (scope, elem, attrs, ngModel)=>{
			if ( ! ngModel ) return; // do nothing if no ng-model
			ngModel.$validators.email = (modelValue, viewValue)=>
        viewValue ? /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(viewValue) : true;
		},
	};
}];
validEmailDirective.selector = 'validEmail';
