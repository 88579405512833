import $ from 'jquery'
import angular from 'angular'
import {Helper} from '../'

export const clickSelectDirective = [
(
)=>{
	return {
		restrict: 'A',
		link: ($scope, $element)=>{
			$element = $($element);
			$element.on('click', function(){
				let range, selection;
				if ( window.getSelection ) {
					selection = window.getSelection();
					range = document.createRange();
					range.selectNodeContents(this);
					selection.removeAllRanges();
					selection.addRange(range);
				} else if ( document.body.createTextRange ) {
					range = document.body.createTextRange();
					range.moveToElementText(this);
					range.select();
				}
			});
			$scope.$on('$destroy', ()=>$element.off('click'));
		},
	}
}];
clickSelectDirective.selector = 'clickSelect';
