import {DependencyInjected, Helper} from '../classes'

class MapFilteredController extends DependencyInjected {
	static get $inject(){return [
		'$scope',
		'$q',
		'$mdMenu',
	]}

	init(){
		this.refresh = Helper.debounce(()=>{
			if ( this._refresh )
				this.$scope.$evalAsync(()=>this._refresh(), {});
		}, 200);

		this.$scope.$evalAsync(()=>{
			if ( ! this.ready )
				this.$scope.$parent?.$on('deps-loaded', ()=>this._init());
			else
				this._init();
		});
	}

	_init(){
		Object.keys(this.keys).forEach((groupId)=>{
			let map = this.mapping[groupId];
			if ( map ) map.forEach(item=>item._$groupId = groupId);
			let f = this.$scope.$watchCollection('ctrl.filters._'+ groupId, (group)=>this._update(groupId));
			this._destructors.push(f);
		});
		this.label = this.label || 'Filter';
		this.placeholder = this.placeholder || 'Enter a filter...';
	}


	_update(groupId){
		let dirty = false;
		let group = this.filters['_'+ groupId] || [];
		if ( ! Array.isArray(group) ) group = [group];
		group.forEach((item)=>{
			if ( ! this.filters.includes(item) ) {
				item._$groupId = item._$groupId || groupId;
				this.filters.push(item);
				dirty = true;
			}
		});
		var i = this.filters.length;
		while( i-- ) {
			let chip = this.filters[i];
			if ( chip._$groupId === groupId && ! group.includes(chip) ) {
				this.filters.splice(i, 1);
				dirty = true;
			}
		}
		if ( dirty )
			this.refresh();
	}

	add(chip){
		if ( typeof chip === 'string' ) {
			let p = this.filters.indexOf(chip);
			if ( p > -1 ) this.filters.splice(p, 1);
			return;
		}
		let group = this.filters['_'+ chip._$groupId] || [];
		let map = this.mapping[chip._$groupId];
		if ( map ) {
			let item = map.byId[chip._id];
			if ( ! map.single ) {
				if ( item && ! group.includes(item) )
					group.push( item );
			} else {
				this.filters['_'+ chip._$groupId] = item;
				this._update( chip._$groupId );
			}
		}
		this.refresh();
	}

	remove(chip){
		let group = this.filters['_'+ chip._$groupId] || [];
		let map = this.mapping[chip._$groupId];
		if ( ! map.single ) {
			let p = group.indexOf(chip);
			if ( p > -1 )
				group.splice(p, 1);
		} else {
			this.filters['_'+ chip._$groupId] = null;
			this._update( chip._$groupId );
		}
		this.$scope.$emit('mapFilter.removed', chip);
		this.refresh();
	}

	search(text){
		let results = [];

		Object.keys(this.keys).forEach((groupId)=>{
			if ( this.mapping[groupId] )
				this.mapping[groupId].forEach((item)=>{
					let name = item.title || item.name;
					if ( name.toLowerCase().indexOf(text.toLowerCase()) > -1 || text.toLowerCase().indexOf(name.toLowerCase()) > -1 )
						if ( ! this.filters.includes(item) ) {
							item._$groupId = groupId;
							results.push(item);
						}
				});
		});

		return this.$q.when(results);
	}

}


export const mapFilterDirective = [
(
)=>{
	return {
		restrict: 'A',
		scope: {
			filters: '=mapFilter',
			mapping: '=',
			keys: '=',
			label: '@?',
			placeholder: '@?',
			emptyChip: '@?',
			defaultChip: '@?',
			hideInput: '<?',
			ngDisabled: '<?',
			ready: '<?',
			_refresh: '&?refresh',
		},
		bindToController: true,
		controller: MapFilteredController,
		controllerAs: 'ctrl',
		template: require('./mapFilter.html'),
	};
}];
mapFilterDirective.selector = 'mapFilter';
