export const momentPickerConfig = ['momentPickerProvider', momentPickerProvider=>momentPickerProvider.options({
	'minView': 'month',
	'maxView': 'month',
	'today': 'true',
	'autoclose': 'true',
	'keyboard': 'true',
	'hoursFormat': 'ha',
	'leftArrow': '<i class="gg-arrow-left" role="img" aria-label="last month"></i>',
	'rightArrow': '<i class="gg-arrow-right" role="img" aria-label="next month"></i>',
})];

export default momentPickerConfig;