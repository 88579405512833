export const CONSTANTS = Object.freeze({
	CONTENT_TYPE: {
		HTML: 'text/html',
		TEXT: 'text/plain',
		JSON: 'application/json',
	},
	NOTIFICATION_TYPES: {
		EVENT: 'dates_and_events',
		TIP_THEME: 'tip_theme',
		TIP: 'tips',
	},
	SCOPE_EVENTS: {
		DEPS_LOADED: 'deps-loaded',
		DATA_READY: 'data-ready',
		ADD_PAGE_DEPENDENCY: 'add-page-dependency',
		PAGE_READY: 'page-ready',
		BUSY: 'busy',
		NOT_BUSY: 'not-busy',
		RECIPIENT_READY: 'recipient-ready',
		RECIPIENT_INIT: 'recipientG-init',
		RECIPIENT_UPDATE: 'recipientGroup.update',
	}
});
export default CONSTANTS;