
function focusOnHoverLink(scope, elem){
  let explicitFocus = null;
  let mouseFocus = null;

  elem
    .on('focus', organicFocus)
    .on('mouseover', borrowFocus)
    .on('mouseout', giveBackFocus)
    .on('mousedown', takeFocus);
  scope.$on('$destroy', ()=>
    elem
      .off('focus', organicFocus)
      .off('mouseover', borrowFocus)
      .off('mouseout', giveBackFocus)
      .off('mousedown', takeFocus)
  );

  function organicFocus(ev){
    if ( mouseFocus !== ev.currentTarget ) {
      // got focus but not via mouse
      explicitFocus = ev.currentTarget;
    }
  }
  function takeFocus(ev){
    explicitFocus = ev.target;
    mouseFocus = null;
  }

  function borrowFocus(ev){
    if ( mouseFocus !== ev.currentTarget ) {
      if ( ev.currentTarget === ev.target ) {
        let active = window.document.activeElement;
        if ( active !== ev.currentTarget ) {
          explicitFocus = active;
          // console.log('explicitFocus', explicitFocus);
        }
      }

      // mark that this target took the focus
      mouseFocus = ev.currentTarget;
      mouseFocus.focus();
      // console.log('mouseFocus', mouseFocus, ev.target);
    }
  }
  function giveBackFocus(ev){
    // target took the focus, return it
    if ( mouseFocus === ev.currentTarget ) {
      if ( mouseFocus !== explicitFocus ) {
        mouseFocus.blur();
        explicitFocus?.focus();
      }
      // console.log('giveBackFocus', mouseFocus);
      mouseFocus = null;
    }
  }
};


export const button_directive = [()=>({
  restrict: 'E',
  priority: 400,
  link: (scope, elem, attrs)=>{
    if ( /(button|reset|submit)/i.test(attrs.type) )
      focusOnHoverLink(scope, elem);
  }, 
})];
export const input_directive = button_directive;
export const a_directive = [()=>({
  restrict: 'E',
  priority: 400,
  link: focusOnHoverLink,
})];
export const mdButton_directive = [()=>({
  restrict: 'EC',
  priority: 400,
  link: focusOnHoverLink,
})];
export const focusable_directive = [()=>({
  restrict: 'C',
  priority: 400,
  link: focusOnHoverLink,
})];


export const mdSelectMenu_directive = [()=>({
  restrict: 'E',
  priority: 400,
  link: (scope, elem, attr)=>{
    const ctrl = elem.controller('mdSelectMenu');
    elem
      .on('mouseover', 'md-option', takePsuedoFocus)
      .on('mouseover', 'md-option', losePsuedoFocus);
    scope.$on('destroy', ()=>
      elem
        .off('mouseover', 'md-option', takePsuedoFocus)
        .off('mouseout', 'md-option', losePsuedoFocus)
    );

    function takePsuedoFocus(ev){
      let node = ev.target;
      if ( node.tagName == 'MD-OPTION' ) {
        elem.find('.md-focused').removeClass('md-focused');
        node.classList.add('md-focused');
        ctrl?.setActiveDescendant && ctrl.setActiveDescendant(node.id);
        
        ctrl?.refreshViewValue && ctrl.refreshViewValue();
      }
    }
    function losePsuedoFocus(ev){

    }
  },
})];
